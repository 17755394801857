import React from 'react'
import { Box, Typography } from '@material-ui/core'
import useStyles from './style'
const ExamSummartFiltered = ({ id }) => {
  const classes = useStyles()

  return (
    <Box className={classes.content}>
      <Box className={classes.emptyBox}>
        <Typography variant="h4">
          There are no participants for this filter value
        </Typography>
      </Box>
    </Box>
  )
}

export default ExamSummartFiltered
