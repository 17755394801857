import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  link: {
    cursor: 'pointer',
    textDecoration: 'none',
    marginTop: '.8rem',
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      width: '7.3px',
      height: '13.41px',
      fill: theme.palette.info.dark,
    },
    marginTop: '0px',
  },
  linkContent: {
    color: theme.palette.info.dark,
    margin: '0 12px',
  },
  linkIcon: {
    height: '13.41px',
    display: 'inline-block',
  },
  extraContent: {
    // textTransform: 'capitalize',
    textIndent: '1em',
    textDecoration: 'none',
    color: 'black',
    fontWeight: 'normal',
    fontSize: '12px',
    fontFamily: 'Roboto',
    textTransform: "lowercase",
    "&::first-letter": {
      textTransform: "capitalize",
    },
  },
}))

export default useStyles
