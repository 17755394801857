import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    marginTop: "27px",
    justifyContent: "space-between",
    backgroundColor: theme.palette.white.main,
  },
  questionInfo: {
    display: "flex",
    border: `1px solid ${theme.palette.border}`,
    borderRadius: "5px",
    padding: "10px",
    alignItems: "center",
    maxWidth: "592px",
    width: "100%",
    height: "44px",
    "& h5": {
      margin: "0",
    },
  },

  btnContainer: {
    marginLeft: "auto",
  },
  uploadBtnTextDelete: {
    marginLeft: "10px",
    "&:hover": {
      background: "none",
    },
    "& span": {
      color: theme.palette.error.main,
      fontWeight: "400",
      textTransform: "initial",
    },
  },
  uploadBtnTextEdit: {
    marginLeft: "10px",
    "&:hover": {
      background: "none",
    },
    "& span": {
      color: theme.palette.info.main,
      fontWeight: "400",
      textTransform: "initial",
    },
  },
  order: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "& svg": {
      cursor: "pointer",
      fill: "#696969",
    },
    "& svg:first-child": {
      marginBottom: "3.75px",
    },
  },
}));

export default useStyles;
