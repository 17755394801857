import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Typography } from "@material-ui/core";
import useStyles from "../EmptyExamSummary/styles";
import {
  fetchExamById,
  fetchExamUsers,
  setOpenModal,
} from "../../../store/actions";
import { useParams } from "react-router-dom";
import EmptyExamSummary from "../EmptyExamSummary/EmptyExamSummary";
import ExamSummary from "../ExamSummary/ExamSummary";
import ExamActionButtons from "../../Button/ExamActionButtons/ExamActionButtons";
import AdminBoardSkeleton from "../AdminBoard/AdminBoardSkeleton/AdminBoardSkeleton";
import ParticipantsModal from "../ParticipantsModal/ParticipantsModal";
import Link from "../../Link/Link";
import { ArrowRightIcon } from "../../Icons";
import {
  clearFilteredData,
  setFilteredData,
  setFiltered,
} from "../../../store/actions";

const ExamSummaryMainCard = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { id } = useParams();

  const {
    userExamList,
    activeExam,
    open,
    filterData,
    isUsersLoading,
    filter,
    loading,
    dateRange,
    filterByDate,
    enableEdit,
  } = useSelector(({ exams, examDetail }) => ({
    activeExam: examDetail?.activeExam,
    loading: examDetail?.loading,
    userExamList: exams?.userExamList,
    isUsersLoading: exams.isUsersLoading,
    open: exams?.open,
    filterData: exams?.filterData,
    page: exams?.page,
    size: exams?.size,
    filter: exams?.filter,
    dateRange: exams?.dateRange,
    filterByDate: exams.filterByDate,
    enableEdit: exams.enableEdit,
  }));
  useEffect(() => {
    dispatch(fetchExamById(id));
    dispatch(fetchExamUsers(id, filterData));
  }, [  id,
    filterData.searchTerm,
    filterData.columnName,
    filterData.order,
    filterData.page,
    filterData.score,
    filterData.status,
    filterData.schedule_date,
    filterData.first_schedule_date,
    filterData.last_schedule_date]);
  useEffect(() => {
    const result =
      dateRange.first_schedule_date !== "" &&
      dateRange.last_schedule_date !== ""
        ? orderByDateRangehandle()
        : null;
  }, [dateRange.first_schedule_date, dateRange.last_schedule_date]);
  const orderByDateRangehandle = () => {
    dispatch(
      setFilteredData("first_schedule_date", dateRange.first_schedule_date)
    );
    dispatch(
      setFilteredData("last_schedule_date", dateRange.last_schedule_date)
    );
    dispatch(setFilteredData("page", 1));
    dispatch(setFiltered(true));
  };
  
  useEffect(()=>{
    dispatch(fetchExamById(id));
    dispatch(fetchExamUsers(id, {page:1,size:10,order:"ASC",columnName:"name"}));
  },[])
  return (
    <>
      {activeExam?.examUsers >= 0 ? (
        <>
          <Link
            text="Exams"
            to={"/admin/home"}
            endIcon={<ArrowRightIcon className={classes.backIcon} />}
            extraText={activeExam && activeExam?.title}
            onClick={() => dispatch(clearFilteredData())}
          />
          <Box className={classes.container}>
            <Box className={classes.header}>
              <Typography className={classes.title} variant="h1">
                {activeExam?.title}
              </Typography>
              <Typography className={classes.subtext} variant="body1">
                {`${activeExam?.examUsers || 0} Participants · `}
                
                <Box className={classes.activeSessions} component="span">
                  {`${userExamList?.activeExams ? userExamList?.activeExams : 0} Active sessions`}
                  
                </Box>
              </Typography>
            </Box>
            <ExamActionButtons
              classes={classes}
              open={() => dispatch(setOpenModal(true))}
              openModal={open}
              userList={userExamList?.result?.data}
              enableEdit={enableEdit}
              examUsers={activeExam?.examUsers}
            />
            {activeExam?.examUsers > 0 ? (
              <>
                <ExamSummary
                  userExamList={userExamList}
                  orderByDateRangehandle={orderByDateRangehandle}
                />
                {open ? (
                  <ParticipantsModal
                    userExamList={userExamList}
                    handleClose={() => dispatch(setOpenModal(false))}
                    open={open}
                  />
                ) : null}
              </>
            ) : (
              <>
                <EmptyExamSummary
                  openModal={() => dispatch(setOpenModal(true))}
                />
                {open ? (
                  <ParticipantsModal
                    userExamList={userExamList}
                    handleClose={() => dispatch(setOpenModal(false))}
                    open={open}
                  />
                ) : null}
              </>
            )}
          </Box>
        </>
      ) : (
        <AdminBoardSkeleton />
      )}
    </>
  );
};

export default ExamSummaryMainCard;
