import {
  LOADING_LOGIN,
  LOGGEDIN_SUCCESS,
  LOGGEDIN_FAILED,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
  SEND_EMAIL,
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_FAILED,
  LOGOUT,
  SET_ROLE,
} from "../../types/types";

const initState = {
  loading: false,
  login: false,
  loginRole:"",
  role: "",
  routerRole:"",
  err: "",
};

const loginReducer = (state = initState, action) => {
  switch (action.type) {
    case LOADING_LOGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGGEDIN_SUCCESS:
      return {
        ...state,
        loading: false,
        login: true,
        role: action.role,
      };
    case LOGGEDIN_FAILED:
      return {
        ...state,
        err: action?.payload,
      };
    case RESET_PASSWORD:
      return {
        ...state,
        loading: true,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case RESET_PASSWORD_FAILED:
      return {
        ...state,
        loading: false,
        err: action?.payload,
      };
    case SEND_EMAIL:
      return {
        ...state,
        loading: true,
      };
    case SEND_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case SEND_EMAIL_FAILED:
      return {
        ...state,
        loading: false,
        err: action?.payload,
      };
      case SET_ROLE:
        return {
          ...state,
          routerRole:action?.value,
        };
    case LOGOUT:
      return initState;
    default:
      return state;
  }
};

export default loginReducer;
