import {
  FETCH_EXAM_BY_ID,
  FETCH_EXAM_BY_ID_SUCCESS,
  FETCH_EXAM_BY_ID_FAIL,
  FETCH_EXAM_ANSWERS,
  FETCH_EXAM_ANSWERS_SUCCESS,
  FETCH_EXAM_ANSWERS_FAILURE,
  FETCH_EXAM_QUESTIONS,
  FETCH_EXAM_QUESTIONS_SUCCESS,
  FETCH_EXAM_QUESTIONS_FAILURE,
  FETCH_USER_INFORMATION,
  FETCH_USER_INFORMATION_SUCCESS,
  FETCH_USER_INFORMATION_FAILURE,
  UPDATE_USER_INFORMATION,
  UPDATE_USER_INFORMATION_SUCCESS,
  UPDATE_USER_INFORMATION_FAILURE,
  FETCH_ALL_EXAM_USERS,
  FETCH_ALL_EXAM_USERS_SUCCESS,
  FETCH_ALL_EXAM_USERS_FAILURE,
  SET_TIME_COMPLETED,
  UPDATE_USER_INPUT_FIELD,
  UPDATE_EXAM_INPUT_FIELD,
  UPDATE_EXAM_QUESTION_FIELD,
  UPDATE_EXAM_DURATION_FIELD,
  CLEAR_EXAM_INFO,
  CLEAR_USER_INFO,
  SHOW_SNACK_BAR,
} from '../types'

const initState = {
  listExamUsers: [],
  //  listExamUsers: {
  //   result: [],
  //   activeExams: 0,
  //   examActiveSession: [],
  //   participants: [],
  //   toBeAssest: [],
  // },
  examInfo: {},
  examQuestions: [],
  examAnswers: [],
  userInfo: {},
  questionAnswer: {},
  timeCompleted: false,
  err: '',
  loading: false,
  isloading: false,
  activeExam: {
    description: '',
    img: null,
    status: '',
    timeRemain: {
      days: '00',
      hours: '00',
      minutes: '00',
    },
    timeTotal: {
      days: '00',
      hours: '00',
      minutes: '00',
    },
    title: '',
  },
  snackbarOpen: false,
  snackbarType: '',
  snackbarMessage: '',
  examQuestionsList: [],
}

const activeExamReducer = (state = initState, action) => {
  switch (action.type) {
    case FETCH_EXAM_BY_ID:
      return {
        ...state,
        loading: true,
      }
    case FETCH_EXAM_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        activeExam: action.value,
      }
    case FETCH_EXAM_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        err: action.err,
        activeExam: {},
      }

    case FETCH_EXAM_QUESTIONS:
      return {
        ...state,
        loading: true,
      }

    case FETCH_EXAM_QUESTIONS_SUCCESS:
      return {
        ...state,
        examQuestions: action?.value,
        examQuestionsList: action?.value,
        loading: false,
      }

    case FETCH_EXAM_QUESTIONS_FAILURE:
      return {
        ...state,
        examQuestions: initState.examQuestions,
        loading: false,
      }

    case FETCH_EXAM_ANSWERS:
      return {
        ...state,
        isloading: true,
        loading: false,
      }

    case FETCH_EXAM_ANSWERS_SUCCESS:
      return {
        ...state,
        examAnswers: action?.value,
        isloading: false,
        loading: false,
      }

    case FETCH_EXAM_ANSWERS_FAILURE:
      return {
        ...state,
        examAnswers: initState.examAnswers,
        isloading: false,
        loading: false,
      }

    case FETCH_USER_INFORMATION:
      return {
        ...state,
        loading: true,
        err: '',
      }
    case FETCH_USER_INFORMATION_SUCCESS:
      return {
        ...state,
        userInfo: action?.value,
        loading: false,
      }
    case FETCH_USER_INFORMATION_FAILURE:
      return {
        ...state,
        userInfo: initState.userInfo,
        err: action.err,
        loading: false,
      }

    case UPDATE_USER_INPUT_FIELD: {
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          [action.value.name]: action.value.value,
        },
      }
    }
    case UPDATE_EXAM_INPUT_FIELD: {
      return {
        ...state,
        activeExam: {
          ...state.activeExam,
          [action.value.name]: action.value.value,
        },
      }
    }
    case UPDATE_EXAM_DURATION_FIELD: {
      return {
        ...state,
        activeExam: {
          ...state?.activeExam,
          timeTotal: {
            ...state?.activeExam?.timeTotal,
            [action.value.name]: action.value.value,
          },
        },
      }
    }
    case UPDATE_EXAM_QUESTION_FIELD: {
      return {
        ...state,
        examQuestions: action?.value,
      }
    }
    case CLEAR_EXAM_INFO: {
      return {
        ...state,
        activeExam: initState.activeExam,
        examQuestions: initState.examQuestions,
      }
    }
    case UPDATE_USER_INFORMATION:
      return {
        ...state,
        loading: true,
      }

    case UPDATE_USER_INFORMATION_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case UPDATE_USER_INFORMATION_FAILURE:
      return {
        ...state,
        loading: false,
      }

    case FETCH_ALL_EXAM_USERS:
      return {
        ...state,
        loading: true,
      }

    case FETCH_ALL_EXAM_USERS_SUCCESS:
      return {
        ...state,
        listExamUsers: action.value,
        loading: false,
      }

    case FETCH_ALL_EXAM_USERS_FAILURE:
      return {
        ...state,
        listExamUsers: initState.userObj,
        loading: false,
      }

    case SET_TIME_COMPLETED:
      return {
        ...state,
        timeCompleted: action?.value,
      }
    case CLEAR_USER_INFO:
      return {
        ...state,
        userInfo: {},
      }
    case SHOW_SNACK_BAR: {
      return {
        ...state,
        snackbarOpen: action.snackbarOpen,
        snackbarType: action.snackbarType,
        snackbarMessage: action.snackbarMessage,
      }
    }
    default:
      return state
  }
}

export default activeExamReducer
