import React from 'react';
import { Modal as MuiModal, Button } from '@material-ui/core';
import { Box, Typography } from '@material-ui/core';
import ButtonSecondary from '../Button/ButtonSecondary/ButtonSecondary';
import useStyles from './styles';
import whiteArrowIcon from '../../assets/whiteArrowIcon.svg';
import warningIcon from '../../assets/warningIcon.svg';

const Modal = ({ handleClose, open, modalConfig, children }) => {
  const classes = useStyles();

  const body = (
    <Box className={classes.container}>
      <Box className={classes.modalHeader}>
        <Typography className={classes.icon} variant="h2">
          {modalConfig.title}
        </Typography>
        <img src={warningIcon} alt="" />
      </Box>
      {children}

      <Box className={classes.buttonContainer}>
        <ButtonSecondary
          onClick={modalConfig.onClose}
          noicon="true"
          variant="outlined"
          color="primary"
        >
          {modalConfig.onCancelBtnTxt}
        </ButtonSecondary>
        <Button
          className={classes.startButton}
          variant="contained"
          color="secondary"
          onClick={modalConfig.onAccept}
        >
          <Typography component={'span'} className={classes.startText}>
            {modalConfig.onAcceptBtnTxt}
          </Typography>
          <img src={whiteArrowIcon} alt="" className={classes.startIcon} />
        </Button>
      </Box>
    </Box>
  );

  return (
    <Box>
      <MuiModal open={open} onClose={handleClose}>
        <>{body}</>
      </MuiModal>
    </Box>
  );
};

export default Modal;
