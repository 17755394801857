import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    left: '50%',
    top: '40%',
    width: '435px',
    height: '371px',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '10px',
    outline: 'none',
    padding: '25px 39px 25px 39px',
    transform: 'translate(-50%,-50%)',
  },
  headerMessage: {
    textAlign: 'center',
    fontSize: '16px',
    color: '#C72F2C',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '18px',
    '& button:first-child': {
      width: '133px',
      textAlign: 'center',
      marginRight: '14px',
      justifyContent: 'center',
    },
    '& button:last-child': {
      width: '225px',
    },
  },
  startButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: '10px',
  },
  startText: {
    flex: 8,
    display: 'flex',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#fff',
    textTransform: 'none',
  },
  link: {
    textDecoration: 'none',
  },
  beCareFul: { width: '35%', margin: 'auto' },
}));

export default useStyles;
