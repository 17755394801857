import {
  FETCH_EXAM_START,
  FETCH_EXAM_SUCCESS,
  FETCH_EXAM_FAIL,
  START_USER_EXAM,
  START_USER_EXAM_SUCCESS,
  START_USER_EXAM_FAILURE,
  SUBMIT_USER_QUESTION,
  SUBMIT_USER_QUESTION_SUCCESS,
  SUBMIT_USER_QUESTION_FAILURE,
  STORE_QUESTION,
  STORE_ANSWER,
  FETCH_REMAINING_TIME,
  FETCH_REMAINING_TIME_SUCCESS,
  FETCH_REMAINING_TIME_FAIL,
  SAVE_ANSWER,
  SET_TIME_STATE,
  SAVE_CONDITION_VALUES,
  LIST_GRID_VIEW,
} from "../types";

const initState = {
  exams: [],
  AnswerList: [],
  loading: false,
  err: "",
  remainingTime: null,

  time: null,
  answer: null,
  checkArray: [],
  view: "grid",
};

const UserReducer = (state = initState, action) => {
  switch (action.type) {
    case FETCH_EXAM_START:
      return {
        ...state,
        loading: true,
        err: "",
      };
    case FETCH_EXAM_SUCCESS:
      return {
        ...state,
        exams: action.payload,
        remainingTime: null,
        loading: false,
      };
    case FETCH_EXAM_FAIL:
      return {
        ...state,
        err: action.err,
      };

    case START_USER_EXAM:
      return {
        ...state,
      };

    case START_USER_EXAM_SUCCESS:
      return {
        ...state,
      };

    case START_USER_EXAM_FAILURE:
      return {
        ...state,
      };

    case SUBMIT_USER_QUESTION:
      return {
        ...state,
        loading: true,
      };

    case SUBMIT_USER_QUESTION_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case SUBMIT_USER_QUESTION_FAILURE:
      return {
        ...state,
        loading:false
      };
    case STORE_QUESTION:
      return {
        ...state,
        questionList: action.value,
      };
    case STORE_ANSWER:
      return {
        ...state,
        AnswerList: action.value,
      };

    case FETCH_REMAINING_TIME:
      return {
        ...state,
        loading: true,
        err: "",
      };

    case FETCH_REMAINING_TIME_SUCCESS:
      return {
        ...state,
        remainingTime: action?.value,
        loading: false,
      };

    case FETCH_REMAINING_TIME_FAIL:
      return {
        ...state,
        err: action.err,
      };

    case SET_TIME_STATE:
      return {
        ...state,

        time: action?.payload,
      };

    case SAVE_ANSWER:
      return {
        ...state,
        loading: true,
        answer: action?.payload,
      };

    case SAVE_CONDITION_VALUES:
      return {
        ...state,
        loading: false,
        checkArray: action?.check
          ? action?.payload
          : [...state.checkArray, action?.payload],
      };

    case LIST_GRID_VIEW:
      return {
        ...state,
        view: action?.payload,
      };

    default:
      return state;
  }
};

export default UserReducer;
