import { Box, Grid, Typography, Button } from "@material-ui/core";
import React, { useEffect } from "react";
import useStyles from "./styles";
import CodeEditor from "@uiw/react-textarea-code-editor";

import {
  submitUserQuestion,
  userFromToken,
  updateUserQuestion,
  saveConditionValuesAction,
} from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";

const AnswerBox = ({
  time,
  answerkey,
  questionNumber,
  placeholderTxt,
  questionId,
  examId,
  answer,
  answerdTcheck,
  countedTime,
  setAnswerTxt,
  answerCheck,
  questionItem,
}) => {
  const classes = useStyles();
  const userId = userFromToken().id;
  const dispatch = useDispatch();
  const { checkArray, loading } = useSelector(({ examsUser }) => ({
    checkArray: examsUser.checkArray,
    loading: examsUser.loading,
  }));
  console.log(questionItem);
  useEffect(() => {
    setAnswerTxt({ ...answerCheck, answerText: answer });
  }, [answerdTcheck]);
  const onClickHandler = (e) => {
    e.preventDefault();
    if (!answerCheck.answerText) {
      setAnswerTxt({ ...answerCheck, answerTouched: true });
    } else {
      setAnswerTxt({
        ...answerCheck,
        answerTouched: false,
        typeValidation: false,
      });
      dispatch(
        saveConditionValuesAction(
          checkArray.filter((q) => q !== questionId),
          true
        )
      );
    }
    answerdTcheck
      ? dispatch(
          updateUserQuestion({
            examId,
            questionId,
            q: { description: answerCheck.answerText },
            userId,
          })
        )
      : dispatch(
          submitUserQuestion({
            examId,
            questionId,
            q: { description: answerCheck.answerText },
            userId,
          })
        );
  };

  const changeHandler = (e) => {
    setAnswerTxt({ ...answerCheck, [e.target.name]: e.target.value });
  };

  const onEditHandler = () => {
    dispatch(saveConditionValuesAction(questionId, false));
  };

  return (
    <Box className={classes.answerBox} key={answerkey} id={questionId}>
      <Typography className={classes.label} variant="h5">
        Question {questionNumber + 1} answer:
      </Typography>
      {/**  <textarea
        disabled={
          time === 'finished' ||
          countedTime === 0 ||
          (answerdTcheck && !checkArray.includes(questionId))
        }
        placeholder={placeholderTxt}
        className={
          answerCheck.answerTouched ? classes.emptyAnswer : classes.textarea
        }
        name="answerText"
        id=""
        rows="10"
        onChange={changeHandler}
        value={answerCheck?.answerText}
      /> */}
      <CodeEditor
        disabled={
          time === "finished" ||
          countedTime === 0 ||
          (answerdTcheck && !checkArray.includes(questionId))
        }
        placeholder={placeholderTxt}
        className={
          answerCheck.answerTouched ? classes.emptyAnswer : classes.textarea
        }
        name="answerText"
        id=""
        rows="10"
        onChange={changeHandler}
        value={answerCheck?.answerText}
        language="js"
        padding={15}
        style={{
          fontSize: 12,
          backgroundColor: "#fff",
          fontFamily:
            "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
        }}
        {...(questionItem?.multichoice ? { maxLength: 1 } : null)}
      />
      <Grid
        direction="column"
        alignItems="flex-end"
        justifyContent="flex-end"
        container
      >
        {(!answerdTcheck ||
          (answerdTcheck && checkArray.includes(questionId))) && (
          <Button
            className={classes.submitBtn}
            variant={"contained"}
            color={"secondary"}
            onClick={onClickHandler}
            disabled={
              time === "finished" ||
              countedTime === 0 ||
              loading ||
              (!answerCheck?.answerText && answerCheck.answerTouched)
            }
          >
            {loading ? (
              <CircularProgress style={{ color: "gray" }} size={25} />
            ) : (
              "Submit your answer"
            )}
          </Button>
        )}
        {answerdTcheck && !checkArray.includes(questionId) && (
          <Button
            className={classes.editButton}
            variant={"outlined"}
            color={"primary"}
            onClick={onEditHandler}
            disabled={time === "finished" || countedTime === 0}
          >
            Edit your answer
          </Button>
        )}
        {(!answerdTcheck ||
          (answerdTcheck && checkArray.includes(questionId))) && (
          <Typography
            variant="body1"
            className={answerCheck.answerTouched ? classes.emptyText : ""}
          >
            {time === "finished" || countedTime === 0
              ? ""
              : answerCheck.answerTouched
              ? "Note That You Can't Submit Empty Answer"
              : "You can't edit your answer after submission."}
          </Typography>
        )}
      </Grid>
    </Box>
  );
};
export default AnswerBox;
