import { Box, Typography } from '@material-ui/core'
import React from 'react'
import useStyles from './styles'

const Input = ({
  labeltxt,
  type,
  placeholderTxt,
  value,
  onChange,
  id,
  name,
}) => {
  const classes = useStyles()
  return (
    <Box className={classes.inputContainer}>
      <Typography
        htmlFor={id}
        component="label"
        color="textPrimary"
        className={classes.formLabel}
        variant="body1"
      >
        {labeltxt}
      </Typography>
      <input
        id={id}
        className={classes.formInput}
        placeholder={placeholderTxt}
        type={type}
        value={value}
        onChange={onChange}
        name={name}
      />
    </Box>
  )
}

export default Input
