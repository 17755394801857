import React, { useState, useEffect } from "react";
import { Box, Divider, Typography } from "@material-ui/core";
import Input from "../../Input/Input";
import useStyles from "./styles";
import { PaperClipIcon } from "../../Icons";
import DurationSpinner from "../DurationSpinner/DurationSpinner";
import AddQuestion from "../AddQuestion/AddQuestion";
import PreviewBox from "../PreviewBox/PreviewBox";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchExamQuestions,
  fetchExamById,
  updateExamInfoFieldAction,
  updateExamQuestionAction,
  updateExamDurationAction,
  snackbarAction,
} from "../../../store/actions";
import Link from "../../Link/Link";
import { ArrowRightIcon } from "../../Icons";
import LZString from "lz-string";

const CreateExam = () => {
  const classes = useStyles();
  const { id } = useParams();
  const dispatch = useDispatch();

  const [questionList, setQuestionList] = useState([]);
  const [otherQuestionList, setOtherQuestionList] = useState([]);

  const { examQuestions, activeExam, examQuestionsList } = useSelector(
    ({ examDetail }) => ({
      examQuestions: examDetail?.examQuestions,
      activeExam: examDetail?.activeExam,
      examQuestionsList: examDetail?.examQuestionsList,
    })
  );

  useEffect(() => {
    id && dispatch(fetchExamById(id,true));
    id && dispatch(fetchExamQuestions(id));
    id && setQuestionList(deCompressedQuestions(examQuestions));
  }, [dispatch, id]);

  useEffect(() => {
    id && setQuestionList(deCompressedQuestions(examQuestions));
    id && setOtherQuestionList(deCompressedQuestions(examQuestionsList));
  }, [examQuestions]);

  const removeImgUploaded = () => {
    dispatch(updateExamInfoFieldAction("img", null));
    dispatch(
      updateExamInfoFieldAction("imgName", "Click here to upload exam photo")
    );
  };
  const uploadImage = async (e) => {
    let fileName =
      e.target.files[0].name.length > 20
        ? ` ${e.target.files[0].name.slice(0, 16)}...`
        : e.target.files[0].name;
    let fileType = e.target.files[0].name.substring(
      e.target.files[0].name.lastIndexOf(".") + 1
    );
    if (
      fileType &&
      !["jpeg", "png", "jpg", "svg", "jfif", "pjpeg"].includes(fileType)
    ) {
      dispatch(
        snackbarAction(true, "error", "Only images allowed, try again!")
      );
    }

    let file = e.target.files[0];
    let base64 = await convertBase64(file);
    dispatch(updateExamInfoFieldAction("img", base64));
    dispatch(updateExamInfoFieldAction("imgName", fileName));
    e.target.value = "";
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, rejet) => {
      const fileReader = new FileReader();
      if (file && file.type.match("image.*")) {
        fileReader.readAsDataURL(file);
      }
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        PromiseRejectionEvent(error);
      };
    });
  };

  const editHandler = (e) => {
    dispatch(updateExamInfoFieldAction(e.target.name, e.target.value));
  };

  const updateDurationHandler = (name, value) => {
    dispatch(updateExamDurationAction(name, value));
  };

  const updateQuestionHandler = (question) => {
    dispatch(updateExamQuestionAction(question));
  };

  const deCompressedQuestions = (list) => {
    let result = list?.map((item, index) => {
      const parsedData = JSON.parse(list[index]?.description);
      let newEntity = Object.values(parsedData?.entityMap || {}).forEach(
        (subItem, subIndex) => {
          subItem.data.src = LZString.decompress(
            parsedData?.entityMap[subIndex]?.data?.src
          );
        }
      );
      return {
        ...item,
        description: JSON.stringify({
          ...parsedData,
          entityMap: {
            ...parsedData?.entityMap,
            ...newEntity,
          },
        }),
      };
    });
    return result;
  };

  return (
    <>
      <Link
        text="Exams"
        to={"/admin/home"}
        endIcon={<ArrowRightIcon className={classes.backIcon} />}
        extraText={
          id ? "Edit exam" : "Create a new exam"
        }
      />
      <Box className={classes.wrapper}>
        <Box className={classes.content}>
          <Box className={classes.container}>
            <Typography variant="h3">
              {id ? "Edit exam" : "Create a new exam"}
            </Typography>
            <Divider className={classes.div} />
            <Input
              labeltxt="Exam Title"
              placeholderTxt="Exam title"
              value={activeExam?.title}
              name="title"
              onChange={editHandler}
            />
            <Box className={classes.examDescriptionContainer}>
              <Typography variant="body1" component="label">
                Description
              </Typography>
              <textarea
                className={classes.examDescription}
                placeholder="Description"
                name="description"
                value={activeExam?.description}
                onChange={editHandler}
              ></textarea>
            </Box>
            <Box className={classes.box}>
              <Typography variant="h5">Exam Duration</Typography>
              <DurationSpinner
                duration={activeExam?.timeTotal}
                updateDurationHandler={updateDurationHandler}
              />
            </Box>
            <Box className={classes.box}>
              <Typography variant="h5">Exam Photo</Typography>
              <Box
                className={
                  activeExam?.img !== null
                    ? classes.editExamPhoto
                    : classes.uploadContainer
                }
              >
                <Box className={classes.imgContainer}>
                  <PaperClipIcon />
                  <Typography
                    variant="body1"
                    component="label"
                    className={classes.uploadBtnText}
                    htmlFor="imageUpload"
                  >
                    {activeExam?.imgName
                      ? activeExam?.imgName
                      : activeExam?.img !== null
                      ? "Click here to update exam photo"
                      : "Click here to upload exam photo"}
                  </Typography>
                  <input
                    className={classes.uploadInput}
                    id="imageUpload"
                    type="file"
                    name="img"
                    accept="image/*"
                    onChange={(e) => {
                      uploadImage(e);
                    }}
                  />
                </Box>
                <Box className={classes.uploadImgText}>
                  {activeExam?.img && (
                    <Typography
                      variant="body1"
                      component="button"
                      className={[classes.uploadBtnText, classes.remove].join(
                        " "
                      )}
                      onClick={removeImgUploaded}
                    >
                      Remove
                    </Typography>
                  )}
                  <Typography
                    variant="body1"
                    component="label"
                    className={classes.uploadBtn}
                    htmlFor="imageUpload"
                  >
                    Select a photo
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <AddQuestion
            questionList={questionList && questionList}
            otherQuestionList={otherQuestionList}
            setQuestionList={setQuestionList}
            setOtherQuestionList={setOtherQuestionList}
            updateQuestionHandler={updateQuestionHandler}
          />
        </Box>
        <PreviewBox
          totalQuestions={questionList?.length}
          localQuestionList={questionList}
          questionList={otherQuestionList}
          img={activeExam?.img}
          fileType={activeExam?.fileType}
          title={activeExam?.title}
          duration={activeExam?.timeTotal}
          desc={activeExam?.description}
        />
      </Box>
    </>
  );
};

export default CreateExam;
