import { createTheme } from '@material-ui/core/styles'

const theme = createTheme({
  palette: {
    neutral: {
      main: '#333333',
      dark: '#696969',
      light: '#ACABAB',
    },
    white: {
      main: '#FFF',
    },
    background: {
      main: '#F2F2F2',
    },
    border: '#E8E8E8',
    primary: {
      main: '#3E40B7',
      dark: '#2B2A8F',
      light: '#E5E6FF',
      lighter: '#F4F5FF',
    },
    secondary: {
      main: '#00B761',
      dark: '#008748',
      light: '#E0FFE6',
      veryLight: '#F0FFF3',
    },
    error: {
      main: '#FD3D39',
      dark: '#C72F2C',
      light: '#FFE3E2',
    },
    warning: {
      main: '#FFA318',
      dark: '#BE6D23',
      light: '#FFF3DD',
      veryLight: '#FFF9EE',
    },
    info: {
      main: '#2582F0',
      dark: '#0D4BA7',
      light: '#DFEEFF',
    },
    text: {
      primary: '#333333',
      secondary: '#696969',
    },
    action: {
      active: '#FFF',
    },
    shadow: {
      main: '#4A48CF',
    },
  },
  typography: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    h1: {
      fontSize: '1.5rem', //24px
      fontWeight: 700,
      lineHeight: '150%',
      margin: '0.625rem 0',
    },
    h2: {
      fontSize: '1.375rem', //22px
      fontWeight: 700,
      margin: '0.625rem 0',
      lineHeight: '150%',
    },
    h3: {
      fontSize: '1.25rem', //20px
      fontWeight: 700,
      margin: '0.625rem 0',
      lineHeight: '150%',
    },
    h4: {
      fontSize: '1.125rem', //18px
      fontWeight: 700,
      margin: '0.625rem 0',
      lineHeight: '150%',
    },
    h5: {
      fontSize: '1rem', //16px
      fontWeight: 700,
      margin: '0.625rem 0',
      lineHeight: '150%',
    },
    h6: {
      fontSize: '1rem', //16px
      fontWeight: 400,
      margin: '0.625rem 0',
      color: '#696969',
      lineHeight: '150%',
    },
    body1: {
      fontSize: '0.875rem', //14px
      color: '#000',
    },
    body2: {
      fontSize: '0.875rem', //14px
      color: '#000',
      fontWeight: 700,
      lineHeight: '21px',
    },
    caption: {
      fontSize: '0.75rem', //12px
      color: '#696969',
      lineHeight: '150%',
    },
    subtitle2: {
      fontSize: '0.5625rem', //9px
      color: '#696969',
      lineHeight: '150%',
    },
    subtitle1: {
      fontSize: '0.8125rem', //13px
      color: '#696969',
      lineHeight: '19.5px',
      marginBottom: '2px',
    },
  },
  cardShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)',
  overrides: {
    MuiButton: {
      root: {
        height: '50px',
        padding: '17px 13px 17px 17px',
      },
      outlined: {
        '& span': {
          color: '#3E40B7',
        },
        '& svg': {
          fill: '#3E40B7',
        },
      },
      outlinedPrimary: {
        border: '2px solid #3E40B7',
        '&:hover': {
          border: '2px solid #3E40B7',
          backgroundColor: '#E6E6FF',
          color: '#2B2A8F',
        },
      },
      contained: {},
      sizeSmall: {
        padding: '17px 13px 17px 17px',
        justifyContent: 'space-between',
        borderRadius: '10px',
        color: '#FFF',
        fill: '#fff',
      },
    },
    MuiAvatar: {
      root: {
        width: '48px',
        height: '48px',
      },
    },
    MuiIconButton: {
      root: {
        background: '#FFF',
        borderRadius: '5px',
        width: '34px',
        height: '34px',
        '& span': {
          width: 'unset',
        },
        '& svg': {
          stroke: '#3E40B7',
        },
      },
    },
  },
})

export default theme
