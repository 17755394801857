import { Box, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import Button from "../Button/Button";
import Input from "../Input/Input";
import Link from "../Link/Link";
import useStyles from "./styles";
import backIcon from "../../assets/left_arrow.svg";
import { validateEmailInput } from "../../helpers/formValidator";
import { sendEmailAction } from "../../store/actions";
import { useDispatch } from "react-redux";

const ResetPasswordForm = () => {
  const [emailInputValue, setEmailInputValue] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const setEmailValue = (e) => {
    setEmailInputValue(e.target.value);
  };
  const onButtonClickHandler = (e) => {
    e.preventDefault();
    if (validateEmailInput(emailInputValue)) {
      dispatch(sendEmailAction(emailInputValue));
    } else {
      alert("please provide a valid email");
    }
  };

  return (
    <>
      <Box className={classes.formHeading}>
        <Link to="/login" align="flex-start" img={backIcon} text="Back to login" />
        <Typography variant="h1">Reset your Password</Typography>
      </Box>
      <Box>
        <form>
          <Input
            value={emailInputValue}
            onChange={setEmailValue}
            labeltxt="email"
            type="email"
            placeholderTxt="Email"
          />
          <Box className={classes.formBtn}>
            <Button onClick={onButtonClickHandler} fullWidth variant="contained" color="secondary">
              Send reset link
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default withRouter(ResetPasswordForm);
