export const validateEmailInput = (
  emailInputValue = undefined,
) => {
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  let valid = false;
  emailInputValue &&  emailRegex.test(emailInputValue) ? valid = true : valid = false;

    return valid
};

export const validatePasswordInput = (
  passwordInputValue = undefined,
  conformPassInputValue = undefined
) => {
  let valid = false;

  passwordInputValue &&
    conformPassInputValue &&
    (passwordInputValue === conformPassInputValue)
    ? valid = true : valid = false
    return valid
};