import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    display: "flex",
    backgroundColor: theme.palette.background.main,
  },
  content: {
    display: "flex",
    marginBottom: "24px",
    padding: "0",
    width: "100%",
  },
  header: {
    flex: "1",
  },
  cardItem: {
    marginButtom: "2rem",
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
    "& button": {
      marginRight: "18px",
    },
  },
  disabled: {
    background: "none",
    "& svg": {
      stroke: "#ACABAB",
    },
  },
  icon: {
    height: "13.5px",
    flex: 1,
  },
  examsButton: {
    width: "100%",
    borderRadius: "10px",
    display: "flex",
    textTransform: "none",
    padding: "0px",
    "&:disabled": {
      width: "100%",
      borderRadius: "10px",
      display: "flex",
      border: "2px solid #3E40B7",
    },
  },
  tTE: {
    flex: 3,
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "21px",
    color: "#fff",
    display: "flex",
    alignContent: "start",
    paddingLeft: "6%",
  },
  CF: {
    flex: 3,
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "21px",
    color: "#3E40B7",
    display: "flex",
    alignContent: "start",
    paddingLeft: "6%",
  },
}));

export default useStyles;
