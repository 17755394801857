
import API from "./APIs";

export const LoginService = (emailAndPassword) => {
  return API.post(`/authentication/login`, emailAndPassword)
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      return Promise.reject(err)
    })
}

export const ResetPasswordService = ({ token, password }) => {
  return API.post("/authentication/password-reset ", { token, password })
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      return Promise.reject(err)
    })
}

export const sendEmailService = (email) => {

  return API.post('/authentication/sendemail', { email })
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      return Promise.reject(err)
    })
  }