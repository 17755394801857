import React from 'react';
import { useDispatch } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import logoRed from '../../assets/aspire_sh_light.png';
import personImg from '../../assets/person.jpg';
import logoutIcon from '../../assets/logoutIcon.svg';
import { useHistory, withRouter } from 'react-router-dom';
import { logoutDispatch } from '../../store/actions';
import useStyles from './styles';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  AppBar,
} from '@material-ui/core';
import { UserIcon, TestIcon } from '../Icons';
import jwt_decode from 'jwt-decode';

import {
  clearFilteredData,
  clearOrderedByDataAction,
} from '../../store/actions';
const Sidebar = ({ contained }) => {
  const token = sessionStorage?.getItem('accessToken');
  const { role, id } = token && jwt_decode(token);
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const onButtonClickHandler = (e) => {
    e.preventDefault();
    dispatch(logoutDispatch(history));
  };

  const onUsersClickHandler = (e, path) => {
    e.preventDefault();
    history.push(path);
    dispatch(clearFilteredData());
    dispatch(clearOrderedByDataAction());
  };

  const editProfile = () => {
    history.push(`/admin/profile-edit/${id}`);
  };
  const onExamsClickHandler = (e) => {
    e.preventDefault();
    role?.toLowerCase() === 'admin'
      ? history.push('/admin/home')
      : role?.toLowerCase() === null
      ? history.push('/login')
      : history.push('/home');
    dispatch(clearFilteredData());
    dispatch(clearOrderedByDataAction());
  };

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Drawer
        variant="permanent"
        position="fixed"
        anchor="left"
        className={[classes.sidebar, contained ? classes.contained : ''].join(
          ' '
        )}
      >
        <Box className={classes.logo}>
          <img src={logoRed} alt="" />
        </Box>
        <List className={classes.list}>
          <ListItem
            onClick={onExamsClickHandler}
            className={
              history.location.pathname.includes('/home') ||
              history.location.pathname.includes('/admin/create') ||
              history.location.pathname.includes('admin/exam/')
                ? classes.selected
                : ''
            }
            button
          >
            <ListItemIcon>
              <TestIcon className={classes.testIcon} />
            </ListItemIcon>
            <ListItemText primary="exams" />
          </ListItem>
          {role?.toLowerCase() === 'admin' ? (
            <>
              <ListItem
                onClick={(e) => onUsersClickHandler(e, '/admin/list-user')}
                className={
                  history.location.pathname.includes('/admin/list-user') ||
                  history.location.pathname.includes('/admin/users/create') ||
                  history.location.pathname.includes('admin/users/')
                    ? classes.selected
                    : ''
                }
                button
              >
                <ListItemIcon>
                  <UserIcon className={classes.userIcon} />
                </ListItemIcon>
                <ListItemText primary="Users" />
              </ListItem>
              <ListItem
                onClick={(e) => onUsersClickHandler(e, '/admin/list-admin')}
                className={
                  history.location.pathname.includes('/admin/list-admin') ||
                  history.location.pathname.includes('/admins/create') ||
                  history.location.pathname.includes('admin/admins/')
                    ? classes.selected
                    : ''
                }
                button
              >
                <ListItemIcon>
                  <UserIcon className={classes.userIcon} />
                </ListItemIcon>
                <ListItemText primary="Admins" />
              </ListItem>
            </>
          ) : null}

          <Divider className={classes.div} />
          <ListItem disableGutters className={classes.profileImg}>
            <ListItemIcon>
              <Avatar src={personImg} className={classes.avatar} />
            </ListItemIcon>
            <Box>
              <ListItemText
                primary={`${sessionStorage.getItem(
                  'firstName'
                )} ${sessionStorage.getItem('lastName')}`}
              />
              {role.toLowerCase() === 'admin' && (
                <ListItemText
                  className={classes.editProfileTxt}
                  primary="Edit profile"
                  onClick={editProfile}
                />
              )}
            </Box>
          </ListItem>
          <Divider className={classes.div} />
          <ListItem onClick={onButtonClickHandler} button>
            <ListItemIcon>
              <img src={logoutIcon} alt="" />
            </ListItemIcon>
            <ListItemText primary="logout" />
          </ListItem>
        </List>
      </Drawer>
    </AppBar>
  );
};

export default withRouter(Sidebar);
