import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  questionContainer: {
    maxWidth: "839px",
    border: "1px solid #E8E8E8",
    borderRadius: "10px",
    "&:not(:first-of-type)": {
      marginTop: "70px",
    },
  },
  questionHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px",
    height: "70px",
    backgroundColor: "#E8E8E8",
    borderRadius: "10px 10px 0 0",
  },
  questionBody: {
    padding: "20px 15px",
    backgroundColor: theme.palette.white.main,
    borderRadius: "0 0 10px 10px",
  },
  rate: {
    marginTop: "25px",
    borderBottom: ` 2px solid ${theme.palette.border}`,
    maxWidth: "839px",
    paddingBottom: "11px",
    marginBottom: "11px",
    "& p": {
      marginBottom: "11px",
    },
    "& svg": {
      cursor: "pointer",
    },
    "& svg + svg": {
      marginLeft: "10px",
    },
  },
  viewQ: {
    color: theme.palette.info.main,
  },
  bad: {
    borderColor: theme.palette.error.main,
    "& div:first-child": {
      background: theme.palette.error.light,
    },
  },
  good: {
    borderColor: theme.palette.warning.main,
    "& div:first-child": {
      background: theme.palette.warning.veryLight,
    },
  },
  excellent: {
    borderColor: theme.palette.secondary.main,
    "& div:first-child": {
      background: theme.palette.secondary.veryLight,
    },
  },
}));

export default useStyles;
