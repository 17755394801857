import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  box: {
    padding: "0.3125rem 0.625rem",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "capitalize",
    borderRadius: "5px",
    height: "31px",
  },
  active: {
    color: theme.palette.secondary.dark,
    backgroundColor: theme.palette.secondary.light,
    width: "60px",
  },
  inProgress: {
    color: theme.palette.warning.dark,
    backgroundColor: theme.palette.warning.light,
    width: "93px",
  },
  finished: {
    color: theme.palette.error.dark,
    backgroundColor: theme.palette.error.light,
    width: "74px",
  },
  disabled: {
    width: "75px",
    height: "31px",
    color: theme.palette.neutral.light,
    backgroundColor: theme.palette.background.main,
  },
}));

export default useStyles;
