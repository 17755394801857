import React from 'react';
import { Grid, Box, Typography } from '@material-ui/core';
import useStyles from './styles';
import logo from '../../assets/aspire_sh_light.png';
import logoSmall from '../../assets/aspire_sh_light.png';

const Login = ({ children }) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        className={classes.aside}
        item
        xs={12}
        md={5}
      >
        <Box zIndex="1">
          <img className={classes.aspireLogo} src={logo} alt="aspire logo" />
        </Box>
      </Grid>
      <Grid
        className={classes.content}
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        item
        xs={12}
        md={7}
      >
        <Box className={classes.formContainer}>{children}</Box>
        <Box className={classes.logoBox} alignSelf="center">
          <img
            className={classes.aspireFooter}
            src={logoSmall}
            alt="aspire logo"
          />
          <Typography className={classes.logoText} variant="body1">
            Powered by ASPIRE ©{new Date().getFullYear()} All rights reserved.
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Login;
