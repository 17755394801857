import React, { useState } from 'react'
import { Box, Grid, Paper, Typography } from '@material-ui/core'
import { useHistory, withRouter, useParams } from 'react-router-dom'
import Button from '../Button/Button'
import Input from '../Input/Input'
import Link from '../Link/Link'
import useStyles from './styles'
import backIcon from '../../assets/left_arrow.svg'
import checkIcon from '../../assets/checkIcon.svg'
import { validatePasswordInput } from '../../helpers/formValidator'
import { useDispatch } from 'react-redux'
import { resetPasswordAction } from '../../store/actions'

const NewPasswordForm = () => {
  const [resetSucess, setResetSuccess] = useState(false)
  const [passwordInputValue, setPasswordInputValue] = useState('')
  const [confirmPassInputValue, setConfirmPassInputValue] = useState('')

  const history = useHistory()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { token } = useParams()
  const setPasswordValue = (e) => {
    setPasswordInputValue(e.target.value)
  }
  const setConfirmPasswordValue = (e) => {
    setConfirmPassInputValue(e.target.value)
  }
  const submitNewPassword = (e) => {
    e.preventDefault()
    if (validatePasswordInput(passwordInputValue, confirmPassInputValue)) {
      dispatch(resetPasswordAction({ token, password: passwordInputValue }))
      setResetSuccess(!resetSucess)
    } else {
      alert('not confirm')
    }
  }
  const onButtonClickHandler = (e) => {
    e.preventDefault()
    if (resetSucess) {
      history.replace('/login')
    }
    setResetSuccess(!resetSucess)
  }

  const renderdForm = resetSucess ? (
    <form>
      <Paper className={classes.box}>
        <Grid
          container
          className={classes.boxContents}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <img src={checkIcon} alt="" />
          </Grid>
          <Grid item>
            <Typography className={classes.msg} variant="body1">
              Your password has been reset successfully!
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      <Box className={classes.formBtn}>
        <Button
          onClick={onButtonClickHandler}
          fullWidth
          variant="contained"
          color="secondary"
        >
          Back to login
        </Button>
      </Box>
    </form>
  ) : (
    <form>
      <Input
        value={passwordInputValue}
        onChange={setPasswordValue}
        labeltxt="password"
        type="password"
        placeholderTxt="password"
      />
      <Input
        value={confirmPassInputValue}
        onChange={setConfirmPasswordValue}
        labeltxt="confirm password"
        type="password"
        placeholderTxt="confirm password"
      />
      <Box className={classes.formBtn}>
        <Button
          onClick={submitNewPassword}
          fullWidth
          variant="contained"
          color="secondary"
        >
          Reset your password
        </Button>
      </Box>
    </form>
  )

  return (
    <>
      <Box className={classes.formHeading}>
        <Link
          to="/login"
          align="flex-start"
          img={backIcon}
          text="Back to login"
        />
        <Typography variant="h1">Reset your Password</Typography>
      </Box>
      <Box>{renderdForm}</Box>
    </>
  )
}

export default withRouter(NewPasswordForm)
