import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  formHeading: {
    margin: '1.375rem 0 0',
  },
  formBtn: {
    marginTop: '4.6rem',
  },
  box: {
    borderRadius: '5px',
    border: `1px solid ${theme.palette.border}`,
    boxShadow: 'none',
    marginTop: '1.5rem',
    padding: '2.5rem 0.625rem;',
  },
  boxContents: {
    height: '120px',
    maxHeight: '181px;',
  },
  msg: {
    marginTop: '2rem',
  },
  err: {
    color: theme.palette.error.main,
  },
  backBtn: {
    paddingBottom: '22px',
    textDecoration: 'none',
  },
  LoginButton: {
    borderRadius: '10px',
    textTransform: 'none',
  },
  Labels: {
    color: '#333333',
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '21px',
    paddingBottom: '7px',
  },
  Inputs: {
    width: '100%',
    height: '55px',
    paddingBottom: '21px',
    marginBottom: '6px',
  },
}))

export default useStyles
