export const mapToExam = (
  { status, timeInHours, timeInSeconds, timeInMinutes, title, questions },
  id
) => {
  return {
    id: id,
    status: status,
    title: title,
    questions: questions,
    timeInHours: timeInHours,
    timeInMinutes: timeInMinutes,
    timeInSeconds: timeInSeconds,
  };
};
export const mapToExamList = (rawExamList) => {
  return rawExamList.map(
    ({ id, title, status, timeTotal, timeRemain, img }) => ({
      id: id,
      title: title,
      status: status,
      timeTotal: timeTotal,
      timeRemain: timeRemain,
      img: img,
    })
  );
};

export const mapToUserExam = (filterData) => {
  let paramQueryText = "";
  Object.keys(filterData || {}).map((key) => {
    let value = filterData[key];
    if (
      value !== null &&
      value !== undefined &&
      value !== "" &&
      Number(value) !== 0 &&
      Number(value) !== -1
    ) {
      paramQueryText += key + "=" + value + "&";
    }
  });
  return paramQueryText;
};
