import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../Input/Input";
import Button from "../../Button/Button";
import { Box, TextField, Typography } from "@material-ui/core";
import useStyles from "./styles";
import { useParams } from "react-router-dom";
import {
  updateUserInformation,
  createUserAction,
  getUserInformation,
  updateUserInfoFieldAction,
  resetLink,
} from "../../../store/actions";
import { useHistory } from "react-router-dom";
import { validateEmail } from "../../../helpers/regex";

const CreateNewUser = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();

  const [state, setState] = useState({
    emailError: false,
  });
  const [show, setShow] = useState(true);
  setTimeout(() => {
    setShow(false);
  }, 5000);

  const editHandler = (e) => {
    dispatch(updateUserInfoFieldAction(e.target.name, e.target.value));
  };
  const emailEditHandler = (e) => {
    dispatch(updateUserInfoFieldAction(e.target.name, e.target.value));
    setState((prevState) => ({
      ...prevState,
      emailError: false,
    }));
  };
  const updateUserHandler = () => {
    if (!validateEmail(userInfo?.email)) {
      return setState((prevState) => ({
        ...prevState,
        emailError: true,
      }));
    }
    dispatch(
      updateUserInformation(
        id,
        userInfo,
        filterData.page,
        filterData.size,
        props?.role,
        history
      )
    );
  };
  const onSaveHandler = () => {
    if (!validateEmail(userInfo?.email)) {
      return setState((prevState) => ({
        ...prevState,
        emailError: true,
      }));
    }
    dispatch(createUserAction({...userInfo,role:props?.role}, filterData, history));
  };
  const copyHandler = () => {
    navigator.clipboard.writeText(resetPasswordLink);
    setShow(true);
  };

  const { userInfo, filterData, resetPasswordLink } = useSelector(
    ({ examDetail, exams }) => ({
      userInfo: examDetail?.userInfo,
      filterData: exams?.filterData,
      resetPasswordLink: exams?.resetPasswordLink,
    })
  );

  useEffect(() => {
    id && dispatch(getUserInformation(id));
  }, [id]);

  return (
    <Box className={classes.createUserForm}>
      {show && resetPasswordLink && (
        <Box className={classes.toastMessage}>
          Successfully Copied To Clipboard !
        </Box>
      )}
      <Box className={classes.header}>
        <Typography variant="h3">
          {id ? "Edit profile" : `Create a new ${props?.role}`}
        </Typography>
      </Box>
      <Box className={classes.formContainer}>
        <Input
          labeltxt="First name"
          placeholderTxt="First name"
          name="firstName"
          value={userInfo?.firstName}
          onChange={editHandler}
        />
        <Input
          labeltxt="Last name"
          placeholderTxt="Last name"
          name="lastName"
          value={userInfo?.lastName}
          onChange={editHandler}
        />
        <Typography
          component="label"
          color="textPrimary"
          className={classes.formLabel}
          variant="body1"
        >
          Email
        </Typography>
        <TextField
          variant="outlined"
          value={userInfo?.email}
          onChange={emailEditHandler}
          type="Email"
          name="email"
          placeholder="Email"
          className={classes.emailFormInput}
          error={state.emailError}
          helperText={state.emailError ? "incorrect email format" : ""}
        />
      </Box>
      <Box className={classes.btnContainer}>
        <Button
          className={classes.uploadBtnTextEdit}
          onClick={() => history.goBack()}
        >
          {id ? "Back" : "Cancel"}
        </Button>
        <Button
          className={classes.createBtn}
          variant="contained"
          color="secondary"
          onClick={id ? updateUserHandler : onSaveHandler}
        >
          {id ? "Update" : "Save"}
        </Button>
        {!id && (
          <Button
            className={classes.copyBtn}
            variant="contained"
            color="secondary"
            onClick={copyHandler}
            disabled={!resetPasswordLink}
          >
            Copy
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default CreateNewUser;
