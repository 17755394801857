import React from "react";
import { Button as MaterialButton, Typography } from "@material-ui/core";
import useStyles from "./styles";

const Button = ({ children, ...props }) => {
  const classes = useStyles();

  return (
    <MaterialButton className={classes.button} disableElevation {...props}>
      <Typography
        className={classes.buttonContents}
        variant="body2"
        component="span"
      >
        {children}
      </Typography>
    </MaterialButton>
  );
};

export default Button;
