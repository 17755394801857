import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  highlights: {
    '& span': {
      width: '63px',
    },
  },
  inputContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '17px',
    '& > * + *': {
      marginLeft: '27px',
    },
  },
  filterContainer: {
    background: theme.palette.white.main,
    padding: '27px 0px 0px 22px',
  },
  subtext: {
    marginBottom: '31px',
    color: theme.palette.neutral.dark,
  },
  title: {
    marginTop: '0',
    marginBottom: '3px',
    color: theme.palette.neutral.main,
  },
  search: {
    width: '264px',
    '& input': {
      height: '38px',
      backgroundColor: 'transparent',
    },
    '& label': {
      position: 'absolute',
      right: '10px',
      top: '5px',
    },
  },
  selectFilter: {
    width: '162px',
    display: 'flex',
    alignItems: 'center',
    '& input': {
      height: '38px',
      backgroundColor: 'transparent',
    },
    '& label': {
      position: 'absolute',
      right: '10px',
      top: '5px',
    },
  },
  selectInput: {
    height: '38px',
    width: '162px',
    padding: '10px',
    borderColor: '#E8E8E8',
    borderRadius: '5px',
    color: '#333',
  },
  container: {
    padding: '0px 22px',
    background: theme.palette.white.main,
    borderRadius: '10px 10px 0 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    position: 'relative',
    '&::after': {
      display: 'block',
      content: '" "',
      position: 'absolute',
      height: '2px',
      left: '22px',
      right: '22px',
      bottom: '0',
    },
  },
  createBtn: {
    width: '175px',
    textTransform: 'unset',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '5px',
    borderRadius: '5px',
    '& span:first-child': {
      marginRight: '10px',
    },
  },
  activeSessions: {
    color: theme.palette.secondary.main,
  },
  examInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  examImg: {
    width: '80.95px',
    height: '53px',
    borderRadius: '5px',
  },
  examDetails: {
    marginLeft: '16px',
    '& h3': {
      marginTop: '0',
      marginBottom: '3px',
    },
  },
  filter: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '9px',
    '& * + *': {
      marginTop: '3.35px',
    },
  },
  tableHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  tableCell: {
    color: theme.palette.neutral.main,
    fontWeight: 'normal',
    paddingLeft: '7px',
  },
  headerRow: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '13px',
    width: '100%',
  },
  tableContainer: {
    backgroundColor: theme.palette.white.main,
    borderRadius: '0 0 10px 10px',
    paddingTop: '27px',
    width: '100%',
  },
  tableBtnContainer: {
    display: 'flex',
    alignItems: 'center',
    '& a': {
      marginTop: '0',
      textDecoration: 'none',
    },
    '& div': {
      width: 'unset',
    },
    '& span': {
      color: theme.palette.info.main,
      fontWeight: 'bold',
    },
  },
  tableBtn: {
    '& span': {
      textTransform: 'initial',
    },
  },
  tableFooter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '0px',
    padding: '30px 20px 0px 20px',
  },
  footerBtn: {
    cursor: 'pointer',
    '& .MuiButton-outlinedSecondary.Mui-disabled': {
      border: 'none',
    },
    '& .MuiButton-outlined.Mui-disabled': {
      border: 'none',
    },
    '&,&:hover,&:active': {
      all: 'unset',
      display: 'flex',
      alignItems: 'center',
      marginRight: '20px',
    },
    '& span': {
      color: `${theme.palette.neutral.dark} !important`,
    },
  },
  btnContainer: {
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingTop: '0px',
    position: 'relative',
    '&::after': {
      display: 'block',
      content: '" "',
      position: 'absolute',
      height: '1px',
      // background: theme.palette.border,
      left: '0',
      right: '0',
      top: '0',
    },
  },
  arrowIcon: {
    fill: theme.palette.neutral.light,
    cursor: 'pointer',
    '&:focus, &:hover, &:active': {
      filter:
        'invert(43%) sepia(34%) saturate(4439%) hue-rotate(197deg) brightness(96%) contrast(96%)',
    },
  },
  linkIcon: {
    width: '7.29px',
    height: '13.41px',
    fill: theme.palette.info.main,
  },
  paginationIcon: {
    fill: theme.palette.neutral.light,
    width: '4.86px',
    height: '8.94px',
  },
  headerBtnContainer: {
    display: 'flex',
    '& > * + *': {
      marginLeft: '20px',
    },
  },
  userIcon: {
    fill: theme.palette.white.main,
  },
  selected: {
    fill: theme.palette.primary.main,
  },
  pagination: {
    '& .MuiPaginationItem-page.Mui-selected': {
      backgroundColor: 'rgb(255 255 255 / 8%)',
      border: '1px solid #3E40B7',
      borderRadius: '6px',
      fontWeight: 'bold',
      fontSize: '14px',
      lineHeight: '150%',
      color: '#3E40B7',
    },
    padding: '0px 100px 0px 100px',
  },
  date: {
    '& .MuiOutlinedInput-input': {
      height: '0px',
    },
  },
  buttons: {
    lineHeight: '150%',
    fontWeight: 'normal',
    cursor: 'pointer',
  },
  tableHead: {
    display: 'flex',
    paddingLeft: '20px',
    width: '100%',
    borderBottom: '1px solid #E8E8E8',
  },
  tableBodyContainer: {
    display: 'flex',
    padding: '14.5px 0px 14.5px 0px',
    borderBottom: '1px solid #E8E8E8',
  },
}))
export default useStyles
