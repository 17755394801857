import React from 'react'
import {
  Card as MuiCard,
  CardMedia,
  Typography,
  CardContent,
  Box,
} from '@material-ui/core'
import useStyles from './styles'
import TextHighlight from '../TextHighlight/TextHighlight'

const CardListItem = ({
  layoutStyle,
  color,
  status,
  timeRemain,
  timeTotal,
  title,
  img,
  children,
}) => {
  const classes = useStyles()
  return (
    <MuiCard className={classes.card}>
      <Box className={classes.header}>
        <CardMedia
          className={[classes.media, layoutStyle].join(' ')}
          image={img}
          title={title}
        />
        <CardContent>
          <Box className={classes.content}>
            <Typography variant="h2" component="h2">
              {title }
            </Typography>
            <TextHighlight color={color}>{status}</TextHighlight>
          </Box>
        </CardContent>
      </Box>
      <Box className={classes.action}>
        <Box className={classes.time}>
          <Box>
            <Typography variant="subtitle1">Duration</Typography>
            <Typography variant="body2">{timeTotal}</Typography>
          </Box>

          <Box>
            <Typography variant="subtitle1">Time Remainig</Typography>
            <Typography
              component={'span'}
              style={{
                fontSize: '0.875rem',
                color: '#000',
                fontWeight: 700,
                lineHeight: '21px',
              }}
            >
              {timeRemain}
            </Typography>
          </Box>
        </Box>
        {children}
      </Box>
    </MuiCard>
  )
}

export default CardListItem
