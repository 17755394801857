import React from "react";

const PlaneIcon = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19.7663 0.734991C19.4675 0.432597 19.0958 0.212371 18.6871 0.0955883C18.2783 -0.0211941 17.8464 -0.0305844 17.433 0.0683246L4.09962 2.87666C3.33177 2.98196 2.60857 3.29956 2.01146 3.79367C1.41435 4.28778 0.96705 4.93879 0.719935 5.67338C0.47282 6.40797 0.435704 7.19696 0.612766 7.9515C0.789829 8.70605 1.17404 9.39617 1.72212 9.94416L3.15379 11.375C3.23128 11.4525 3.29273 11.5444 3.33462 11.6457C3.37652 11.7469 3.39804 11.8554 3.39796 11.965V14.605C3.3998 14.9762 3.48524 15.3422 3.64796 15.6758L3.64129 15.6817L3.66296 15.7033C3.90713 16.1943 4.30594 16.5913 4.79796 16.8333L4.81962 16.855L4.82546 16.8483C5.15909 17.011 5.5251 17.0965 5.89629 17.0983H8.53629C8.75717 17.0982 8.96908 17.1857 9.12546 17.3417L10.5563 18.7725C10.9401 19.1605 11.3968 19.4687 11.9003 19.6794C12.4037 19.8901 12.9439 19.999 13.4896 20C13.9444 19.9994 14.3961 19.9251 14.8271 19.78C15.555 19.541 16.2016 19.1033 16.694 18.5164C17.1864 17.9295 17.505 17.2167 17.6138 16.4583L20.4263 3.09582C20.5303 2.67886 20.5242 2.24201 20.4085 1.82813C20.2928 1.41425 20.0715 1.03755 19.7663 0.734991ZM4.33379 10.1983L2.90129 8.76749C2.56772 8.44196 2.33393 8.02803 2.22736 7.57429C2.12079 7.12055 2.14586 6.64582 2.29962 6.20582C2.4487 5.75443 2.72429 5.35535 3.09362 5.05604C3.46295 4.75674 3.91047 4.56981 4.38296 4.51749L17.5838 1.73832L5.06296 14.2608V11.965C5.06422 11.6369 5.00042 11.3119 4.87527 11.0086C4.75011 10.7054 4.56607 10.43 4.33379 10.1983ZM15.9763 16.1733C15.9123 16.6336 15.7213 17.0669 15.4247 17.4247C15.1281 17.7824 14.7377 18.0504 14.2972 18.1986C13.8568 18.3468 13.3838 18.3693 12.9312 18.2635C12.4787 18.1578 12.0646 17.928 11.7355 17.6L10.3021 16.1667C10.0708 15.934 9.79558 15.7496 9.49247 15.624C9.18937 15.4984 8.86438 15.4342 8.53629 15.435H6.24046L18.763 2.91666L15.9763 16.1733Z" />
    </svg>
  );
};

export default PlaneIcon;
