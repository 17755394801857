import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: "capitalize",
    width:'100%'
  },
  buttonContents: {
    color: "#FFF",
  },
  dark: {
    color: theme.palette.primary.main,
  },
}));

export default useStyles;
