import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: "2px",
  },
  items: {
    marginTop: "0px",
  },
  item: {
    margin: "0",
    borderBottom: `1px solid ${theme.palette.border}`,
  },
  icon: {
    width: "21px",
    height: "14.35px",
    display: "flex",
    justifyContent: "flex-end",
  },
  listTitle: {
    marginTop: "25px",
    padding: "0",
    "& h4": {
      padding: "0",
      margin: "0",
    },
  },
  link: {
    textDecoration: "none",
  },
}));

export default useStyles;
