import {
  FETCH_EXAM_START,
  FETCH_EXAM_SUCCESS,
  FETCH_EXAM_FAIL,
  START_USER_EXAM,
  START_USER_EXAM_SUCCESS,
  START_USER_EXAM_FAILURE,
  SUBMIT_USER_QUESTION,
  SUBMIT_USER_QUESTION_SUCCESS,
  SUBMIT_USER_QUESTION_FAILURE,
  UPDATE_USER_QUESTION,
  UPDATE_USER_QUESTION_SUCCESS,
  UPDATE_USER_QUESTION_FAILURE,
  END_USER_EXAM_SUCCESS,
  END_USER_EXAM_FAILURE,
  STORE_QUESTION,
  STORE_ANSWER,
  REMAINING_TIME,
  FETCH_REMAINING_TIME,
  FETCH_REMAINING_TIME_SUCCESS,
  FETCH_REMAINING_TIME_FAIL,
  SAVE_ANSWER,
  SET_TIME_STATE,
  SAVE_CONDITION_VALUES,
  LIST_GRID_VIEW,
} from '../types'

import {
  EndExamService,
  getExams,
  startExam,
  submitQuestion,
  UpdateQuestionService,
  getRemainingTimeService,
} from '../services'

import { fetchExamAnswers } from '../actions'

export const fetchUserExams = (userId) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_EXAM_START,
    })
    const data = await getExams(userId)
    dispatch({
      type: FETCH_EXAM_SUCCESS,
      payload: data || [],
    })
  } catch (err) {
    dispatch({
      type: FETCH_EXAM_FAIL,
      err: err,
    })
  }
}

export const startUserExam = (userId, examId, history) => async (dispatch) => {
  try {
    dispatch({
      type: START_USER_EXAM,
    })
    const data = await startExam(userId, examId)
    dispatch({
      type: START_USER_EXAM_SUCCESS,
      payload: data || [],
    })
    history.push(`/home/exam/${examId}`)
  } catch (err) {
    dispatch({
      type: START_USER_EXAM_FAILURE,
      err: err,
    })
  }
}
export const endUserExam = (userId, examId) => async (dispatch) => {
  try {
    const data = await EndExamService(userId, examId)
    dispatch({
      type: END_USER_EXAM_SUCCESS,
      payload: data || [],
    })
    dispatch(fetchUserExams(userId))
  } catch (err) {
    dispatch({
      type: END_USER_EXAM_FAILURE,
      err: err,
    })
  }
}
export const submitUserQuestion = (info) => async (dispatch) => {
  try {
    dispatch({
      type: SUBMIT_USER_QUESTION,
    })
    const data = await submitQuestion(info)
    dispatch({
      type: SUBMIT_USER_QUESTION_SUCCESS,
      payload: data || [],
    })
    dispatch(fetchExamAnswers(info.userId, info.examId))
  } catch (err) {
    dispatch({
      type: SUBMIT_USER_QUESTION_FAILURE,
      err: err,
    })
  }
}
export const updateUserQuestion = (info) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_USER_QUESTION,
    })
    await UpdateQuestionService(info)
    dispatch({
      type: UPDATE_USER_QUESTION_SUCCESS,
    })
    dispatch(fetchExamAnswers(info.userId, info.examId))
  } catch (err) {
    dispatch({
      type: UPDATE_USER_QUESTION_FAILURE,
      err: err,
    })
  }
}
export const storeQuestion = (id) => async (dispatch) => {
  dispatch({
    type: STORE_QUESTION,
    value: [id],
  })
}
export const answerQuestion = (answerList, id) => async (dispatch) => {
  const filterAnswer = answerList.filter((item) => {
    return id.id !== item.id
  })

  dispatch({
    type: STORE_ANSWER,
    value: [...filterAnswer, id],
  })
}
export const remainingTime = ({ h, m, s }) => async (dispatch) => {
  dispatch({
    type: REMAINING_TIME,
    value: { h, m, s },
  })
}

export const getRemainingTimeAction = (userId, examId) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_REMAINING_TIME,
    })
    const value = await getRemainingTimeService(userId, examId)
    dispatch({
      type: FETCH_REMAINING_TIME_SUCCESS,
      value,
    })
  } catch (err) {
    dispatch({
      type: FETCH_REMAINING_TIME_FAIL,
      err: err,
    })
  }
}

export const setTimeState = (time) => async (dispatch) => {
  dispatch({ type: SET_TIME_STATE, payload: time })
}

export const saveAnswerAction = (answer) => async (dispatch) => {
  dispatch({ type: SAVE_ANSWER, payload: answer })
}

export const saveConditionValuesAction = (questionId, check) => async (
  dispatch,
) => {
  dispatch({ type: SAVE_CONDITION_VALUES, payload: questionId, check })
}

export const setListGridView = (view) => async (dispatch) => {
  dispatch({ type: LIST_GRID_VIEW, payload: view })
}
