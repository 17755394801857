import React from "react";

const StarIcon = (props) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M11.5776 1.2022C11.9203 0.383963 13.0797 0.383961 13.4224 1.2022L16.1177 7.63746C16.2619 7.98169 16.5857 8.217 16.9577 8.24774L23.9109 8.82255C24.795 8.89564 25.1532 9.99821 24.4809 10.577L19.1935 15.129C18.9107 15.3725 18.787 15.7532 18.8727 16.1165L20.4747 22.907C20.6784 23.7704 19.7405 24.4518 18.9823 23.9913L13.0191 20.3693C12.7002 20.1756 12.2998 20.1756 11.9809 20.3693L6.01774 23.9913C5.25954 24.4518 4.32163 23.7704 4.52533 22.907L6.12731 16.1165C6.213 15.7532 6.0893 15.3725 5.80647 15.129L0.519073 10.577C-0.153216 9.99821 0.205029 8.89564 1.08912 8.82255L8.04233 8.24774C8.41426 8.217 8.73813 7.9817 8.8823 7.63746L11.5776 1.2022Z" />
    </svg>
  );
};

export default StarIcon;
