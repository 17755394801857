import { Grid, Typography } from '@material-ui/core'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import useStyles from './styles'

const Link = ({
  text,
  extraText,
  to,
  align,
  img,
  endIcon,
  startIcon,
  ...props
}) => {
  const classes = useStyles()

  return (
    <Grid container justifyContent={align}>
      <RouterLink className={classes.link} {...props} to={to}>
        {startIcon && startIcon}
        <img src={img} className={classes.linkIcon} />

        <Typography
          className={classes.linkContent}
          component="span"
          variant="body1"
        >
          {text}
        </Typography>
        {endIcon && endIcon}
        <span className={classes.extraContent}>{extraText}</span>
      </RouterLink>
    </Grid>
  )
}

export default Link
