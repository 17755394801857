import React from "react";

const TriangleArrowDown = (props) => {
  return (
    <svg
      width="6"
      height="4"
      viewBox="0 0 6 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0.672559 0.25H5.32756C5.40996 0.250018 5.4905 0.274464 5.559 0.320249C5.62751 0.366034 5.6809 0.431102 5.71243 0.507227C5.74396 0.583352 5.75221 0.667116 5.73614 0.74793C5.72007 0.828743 5.6804 0.902978 5.62214 0.96125L3.29464 3.28875C3.21651 3.36686 3.11054 3.41074 3.00006 3.41074C2.88957 3.41074 2.78361 3.36686 2.70548 3.28875L0.377976 0.96125C0.319722 0.902978 0.280053 0.828743 0.263983 0.74793C0.247913 0.667116 0.256164 0.583352 0.287694 0.507227C0.319223 0.431102 0.372614 0.366034 0.441118 0.320249C0.509622 0.274464 0.590163 0.250018 0.672559 0.25Z" />
    </svg>
  );
};

export default TriangleArrowDown;
