import React, { useState } from "react";
import QuestionPreview from "../QuestionPreview/QuestionPreview";
import useStyles from "./styles";
import { Box, Button } from "@material-ui/core";
import { ArrowLeftIcon, ArrowRightIcon } from "../../Icons";
import Pagination from "@material-ui/lab/Pagination";

const QuestionPreviewWithPagination = ({
  reduceExamAnswers,
  examQuestions,
  id,
  userExamId,
  participantId,
}) => {
  const classes = useStyles();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = reduceExamAnswers?.slice(startIndex, endIndex);
  const pageChangeHandler = (event, page) => {
    setCurrentPage(page);
  };
  return (
    <React.Fragment>
      {currentItems?.map((question, key) => {
        const queData =
          examQuestions?.find((obj) => obj.id === question?.Question?.id) || {};
        const isMultipleChoice = queData.multichoice;
        const correctAnswer = queData.correctanswer;
        return (
          <QuestionPreview
            key={question?.Question?.id}
            questionId={question?.Question?.id}
            questionTitle={question?.Question?.title}
            questionBody={question?.Answer?.description}
            score={question?.Answer?.score}
            examId={id}
            userExamId={userExamId}
            participantId={participantId}
            status={"Finished"}
            examQuestions={examQuestions}
            isMultipleChoice={isMultipleChoice}
            correctAnswer={correctAnswer}
            myKey={key}
          />
        );
      })}

      <Box className={classes.btnContainer}>
        <Button
          startIcon={<ArrowLeftIcon className={classes.paginationIcon} />}
          className={classes.footerBtn}
          color="secondary"
          noicon="true"
          variant="text"
          disableRipple
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <span className={classes.buttons}>Back</span>
        </Button>
        <Pagination
          className={classes.pagination}
          hideNextButton={true}
          hidePrevButton={true}
          count={Math.ceil(reduceExamAnswers?.length / itemsPerPage)}
          page={currentPage}
          shape={"rounded"}
          onChange={pageChangeHandler}
        />

        <Button
          endIcon={<ArrowRightIcon className={classes.paginationIcon} />}
          className={classes.footerBtn}
          color="secondary"
          variant="text"
          disableRipple
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={endIndex >= reduceExamAnswers?.length}
        >
          <span className={classes.buttons}>Next</span>
        </Button>
      </Box>
    </React.Fragment>
  );
};

export default QuestionPreviewWithPagination;
