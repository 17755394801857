import React from 'react'
import { Box, Typography, CircularProgress } from '@material-ui/core'
import Button from '../../Button/Button'
import { EyeIcon, ImgIcon, PlaneIcon } from '../../Icons'
import useStyle from './styles'
import { useDispatch, useSelector } from 'react-redux'
import {
  createExamAction,
  updateExamInformation,
  fetchExamQuestions,
} from '../../../store/actions'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import LZString from 'lz-string'
import { compress } from 'lz-string'

const PreviewBox = ({
  duration,
  totalQuestions,
  img,
  imgName,
  title,
  desc,
  questionList,
  localQuestionList,
}) => {
  const classes = useStyle()
  const dispatch = useDispatch()
  const history = useHistory()
  const { id } = useParams()

  const { filterData, loading } = useSelector(({ exams }) => ({
    filterData: exams?.filterData,
    loading: exams?.loading,
  }))

  const onPublishHandler = () => {
    let result = questionList?.map((item, index) => {
      const parsedData =questionList[index]?.description && JSON.parse(questionList[index]?.description)
      let newEntity = Object.values(parsedData?.entityMap || {}).forEach(
        (subItem, subIndex) => {
          subItem.data.src = LZString.compress(
            parsedData?.entityMap[subIndex]?.data?.src,
          )
        },
      )
      return {
        ...item,
        description: JSON.stringify({
          ...parsedData,
          entityMap: {
            ...parsedData?.entityMap,
            ...newEntity,
          },
        }),
      }
    })

    console.log(result.flat());

    id
      ? dispatch(
          updateExamInformation(
            id,
            {
              title,
              description: desc,
              duration:
                duration?.days * 86400 +
                duration?.hours * 3600 +
                duration?.minutes * 60,
              status: 'Published',
              imageUrl: img,
              questions: result.flat(),
            },
            filterData?.page,
            filterData?.size,
            history,
          ),
        )
      : dispatch(
          createExamAction(
            {
              title,
              description: desc,
              duration:
                duration?.days * 86400 +
                duration?.hours * 3600 +
                duration?.minutes * 60,
              status: 'Published',
              imageUrl: img,
              questions: result.flat(),
            },
            filterData?.page,
            filterData?.size,
            history,
          ),
        )
  }
  const previewHandler = (e) => {
    dispatch(fetchExamQuestions(id))
    e.preventDefault()
    history.push(`/admin/exam/${id}/preview`)
  }

  const enable =
    title !== '' &&
    desc !== '' &&
    duration !== '' &&
    questionList?.length >= 1 &&
    localQuestionList?.length >= 1

  return (
    <Box className={classes.previewBox}>
      {loading && (
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      )}
      <Box className={classes.btnContainer}>
        <Button
          className={[classes.previewBtn, enable && classes.activePreviewBtn].join(
            ' ',
          )}
          startIcon={<EyeIcon />}
          variant="outlined"
          onClick={previewHandler}
          disabled={!id}
        >
          Preview
        </Button>
        <Button
          className={[classes.publishBtn, enable && classes.activePublishBtn].join(
            ' ',
          )}
          startIcon={id ? '' : <PlaneIcon />}
          variant="contained"
          onClick={onPublishHandler}
          disabled={!enable}
        >
          {id ? 'Update exam' : 'Publish'}
        </Button>
      </Box>
      <Box className={classes.previewImgContainer}>
        <Box className={classes.previewImg}>
          {img ? <img src={img} alt="" /> : <ImgIcon />}
        </Box>
        <Typography variant="h3" className={classes.examTitle}>
          {title ? title : 'Untitled'}
        </Typography>
      </Box>
      <Box className={classes.previewInfo}>
        <Box className={classes.duration}>
          <Typography variant="body1">Duration</Typography>
         {/*  <Typography variant="body2">{`${
            parseInt(duration?.days) * 1440 +
            parseInt(duration?.hours) * 60 +
            parseInt(duration?.minutes)
          }m:00s`}</Typography>*/}
          <Typography variant="body2">{`${(parseInt(duration?.hours)+(parseInt(duration?.days)*24))}h:${duration?.minutes}m:00s`}</Typography>

        </Box>
        <Box className={classes.totalQuestions}>
          <Typography variant="body1">Questions</Typography>
          <Typography variant="body2">{totalQuestions}</Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default PreviewBox
