import { GET_ASSESSMENT_PARTICIPANT_INFO } from "../types";

const initState = {
  currentAssessment: {
    participantInfo: {},
  },
};

const AssessmentReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_ASSESSMENT_PARTICIPANT_INFO:
      return {
        ...state,
        currentAssessment: {
          ...state.currentAssessment,
          participantInfo: action.payload,
        },
      };
    default:
      return state;
  }
};

export default AssessmentReducer;
