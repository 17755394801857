import React from "react";

const UserIcon = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0)">
        <path d="M10 10C10.9889 10 11.9556 9.70676 12.7779 9.15735C13.6001 8.60794 14.241 7.82705 14.6194 6.91342C14.9978 5.99979 15.0969 4.99446 14.9039 4.02455C14.711 3.05465 14.2348 2.16373 13.5355 1.46447C12.8363 0.765206 11.9454 0.289002 10.9755 0.0960758C10.0055 -0.0968503 9.00021 0.00216643 8.08658 0.380605C7.17295 0.759043 6.39206 1.39991 5.84265 2.22215C5.29324 3.0444 5 4.0111 5 5C5.00132 6.32568 5.52853 7.59668 6.46593 8.53407C7.40332 9.47147 8.67432 9.99868 10 10ZM10 1.66667C10.6593 1.66667 11.3037 1.86217 11.8519 2.22844C12.4001 2.59471 12.8273 3.1153 13.0796 3.72439C13.3319 4.33348 13.3979 5.0037 13.2693 5.6503C13.1407 6.29691 12.8232 6.89085 12.357 7.35703C11.8908 7.8232 11.2969 8.14067 10.6503 8.26929C10.0037 8.3979 9.33347 8.33189 8.72439 8.0796C8.1153 7.82731 7.59471 7.40007 7.22843 6.8519C6.86216 6.30374 6.66667 5.65927 6.66667 5C6.66667 4.11595 7.01786 3.2681 7.64298 2.64298C8.2681 2.01786 9.11594 1.66667 10 1.66667Z" />
        <path d="M10 11.6666C8.01155 11.6688 6.10518 12.4597 4.69914 13.8658C3.29309 15.2718 2.50221 17.1782 2.5 19.1666C2.5 19.3876 2.5878 19.5996 2.74408 19.7559C2.90036 19.9122 3.11232 20 3.33333 20C3.55435 20 3.76631 19.9122 3.92259 19.7559C4.07887 19.5996 4.16667 19.3876 4.16667 19.1666C4.16667 17.6195 4.78125 16.1358 5.87521 15.0418C6.96917 13.9479 8.4529 13.3333 10 13.3333C11.5471 13.3333 13.0308 13.9479 14.1248 15.0418C15.2188 16.1358 15.8333 17.6195 15.8333 19.1666C15.8333 19.3876 15.9211 19.5996 16.0774 19.7559C16.2337 19.9122 16.4457 20 16.6667 20C16.8877 20 17.0996 19.9122 17.2559 19.7559C17.4122 19.5996 17.5 19.3876 17.5 19.1666C17.4978 17.1782 16.7069 15.2718 15.3009 13.8658C13.8948 12.4597 11.9884 11.6688 10 11.6666Z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="20" height="20" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UserIcon;
