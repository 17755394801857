import {
  FETCH_ADMIN_EXAM,
  FETCH_ADMIN_EXAM_SUCCESS,
  FETCH_ADMIN_EXAM_FAIL,
  CREATE_EXAM,
  CREATE_EXAM_SUCCESS,
  CREATE_EXAM_FAILURE,
  UPDATE_EXAM,
  UPDATE_EXAM_SUCCESS,
  UPDATE_EXAM_FAILURE,
  ADD_QUESTIONS,
  ADD_QUESTIONS_SUCCESS,
  ADD_QUESTIONS_FAILURE,
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  ASSIGN_USERS,
  ASSIGN_USERS_SUCCESS,
  ASSIGN_USERS_FAILURE,
  FETCH_EXAM_USERS,
  FETCH_EXAM_USERS_SUCCESS,
  FETCH_EXAM_USERS_FAILURE,
  SET_SCORE,
  SET_SCORE_SUCCESS,
  SET_SCORE_FAILURE,
  FETCH_USERS,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  SET_OPEN_MODAL,
  GET_FILTERED_EXAM_USERS,
  UPDATE_EXAM_INFO_FIELD,
  SET_SELECTED_USERS,
  SET_EXAM_USER_CHECK,
  RESET_SELECTED_USERS,
  SET_LOADING_BOOL,
  SET_EXAM_SCORE,
  SET_EXAM_QUESTION_RATE,
  FETCH_USER_EXAM_SCORE,
  FETCH_USER_EXAM_SCORE_SUCCESS,
  FETCH_USER_EXAM_SCORE_FAILURE,
  CLEAR_FILTERED_DATA,
  GET_PAGE_SUCCESS,
  CLEAR_ORDER_BY_DATA,
  SET_FILTERED,
  RESEL_LINK,
  SET_COLUMN_NUMBER,
  SET_DATE_RANGE,
  CLEAR_FILTER_BY_DATE_RANGE,
  SET_FILTERED_BY_DATE_RANGE,
  SET_ENABLE_EXAM_EDIT,
  SET_EDITOR_STATE,
  SET_USER_DATA,
  CLEAR_USER_DATA,
  FETCH_WAITING_ASSESSMENT_USERS,
  FETCH_WAITING_ASSESSMENT_USERS_SUCCESS,
  FETCH_WAITING_ASSESSMENT_USERS_FAILURE,
  FETCH_USERS_ADD_SUCCESS
} from '../types'

const initState = {
  loading: false,
  examList: [],
  userExamList: [],
  users: [],
  waitingAssessmentUser:[],
  filterData: {
    score: '',
    status: '',
    schedule_date: '',
    first_schedule_date: '',
    last_schedule_date: '',
    page: 1,
    size: 10,
    searchTerm: '',
    order: 'ASC',
    columnName: '',
    role: ""
  },
  dateRange: { first_schedule_date: '', last_schedule_date: '' },
  filterByDate: false,
  columnNum: 1,
  filter: false,
  page: 1,
  size: 10,
  examInfo: {},
  open: false,
  err: '',
  isUsersLoading: false,
  waitingAssessmentUserLoading:false,
  selectedUsers: [],
  checked: false,
  bool: true,
  score: 0,
  rating: 0,
  examScore: 0,
  resetPasswordLink: null,
  enableEdit: [],
  editorState: "",
  userData:{}
}

const AdminReducer = (state = initState, action) => {
  switch (action.type) {
    case FETCH_ADMIN_EXAM:
      return {
        ...state,
        loading: true,
        err: '',
      }
    case FETCH_ADMIN_EXAM_SUCCESS:
      return {
        ...state,
        examList: action.value,
        loading: false,
      }
    case FETCH_ADMIN_EXAM_FAIL:
      return {
        ...state,
        err: action.err,
        loading: false,
      }
    case SET_SELECTED_USERS:
      return {
        ...state,
        selectedUsers: [...state?.selectedUsers, ...action?.value],
      };
    case RESET_SELECTED_USERS:
      return {
        ...state,
        selectedUsers: [],
      }
    case CREATE_EXAM:
      return {
        ...state,
        loading: true,
        err: '',
      }
    case CREATE_EXAM_SUCCESS:
      return {
        ...state,
        examList: action?.value,
        loading: false,
      }
    case CREATE_EXAM_FAILURE:
      return {
        ...state,
        err: action.err,
        loading: false,
      }
    case UPDATE_EXAM:
      return {
        ...state,
        loading: true,
        err: '',
      }
    case UPDATE_EXAM_SUCCESS:
      return {
        ...state,
        examList: action?.value,
        loading: false,
      }
    case UPDATE_EXAM_FAILURE:
      return {
        ...state,
        err: action.err,
      }
    case UPDATE_EXAM_INFO_FIELD: {
      return {
        examInfo: {
          ...state.examInfo,
          [action.payload.name]: action.payload.value,
        },
      }
    }
    case GET_PAGE_SUCCESS:
      return {
        ...state,
        page: action.value,
        loading: false,
      }
    case ADD_QUESTIONS:
      return {
        ...state,
        loading: true,
        err: '',
      }
    case ADD_QUESTIONS_SUCCESS:
      return {
        ...state,
        examList: action.value,
        loading: false,
      }
    case ADD_QUESTIONS_FAILURE:
      return {
        ...state,
        err: action.err,
      }
    case CREATE_USER:
      return {
        ...state,
        loading: true,
        err: '',
      }
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        resetPasswordLink: action?.value?.resetLink,
        loading: false,
      }
    case CREATE_USER_FAILURE:
      return {
        ...state,
        err: action.err,
      }
    case DELETE_USER:
      return {
        ...state,
        loading: true,
        err: '',
      }
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        examList: action.value,
        loading: false,
      }
    case DELETE_USER_FAILURE:
      return {
        ...state,
        err: action.err,
      }
    case ASSIGN_USERS:
      return {
        ...state,
        loading: true,
        err: '',
      }
    case ASSIGN_USERS_SUCCESS:
      return {
        ...state,
        examList: action.value,
        loading: false,
      }
    case ASSIGN_USERS_FAILURE:
      return {
        ...state,
        err: action.err,
      }
    case SET_EXAM_USER_CHECK:
      return {
        ...state,
        checked: action?.value,
      }
    case FETCH_EXAM_USERS:
      return {
        ...state,
        isUsersLoading: true,
        err: '',
      }
    case FETCH_EXAM_USERS_SUCCESS:
      return {
        ...state,
        userExamList: action.value,
        isUsersLoading: false,
      }
    case FETCH_EXAM_USERS_FAILURE:
      return {
        ...state,
        isUsersLoading: false,
        err: action.err,
      }
    case CLEAR_FILTERED_DATA:
      return {
        ...state,
        filterData: initState.filterData,
      }
    case SET_SCORE:
      return {
        ...state,
        loading: true,
        err: '',
      }
    case SET_SCORE_SUCCESS:
      return {
        ...state,
        examList: action.value,
        loading: false,
      }
    case SET_SCORE_FAILURE:
      return {
        ...state,
        err: action.err,
      }
    case FETCH_USERS:
      return {
        ...state,
        loading: true,
        err: '',
        resetPasswordLink: null,
      }
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        users: action.value,
        loading: false,
        resetPasswordLink: null,
      }
      case FETCH_USERS_ADD_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          transformed: [...state.users.transformed, ...action.value.transformed],
        },
        loading: false,
        resetPasswordLink: null,
      };
    case FETCH_USERS_FAILURE:
      return {
        ...state,
        err: action.err,
      }
    case SET_OPEN_MODAL:
      return {
        ...state,
        open: action.value,
        loading: false,
      }
    case GET_FILTERED_EXAM_USERS:
      return {
        ...state,
        filterData: {
          ...state.filterData,
          [action.value.key]: action.value.val,
        },
        loading: false,
      }

    case SET_LOADING_BOOL:
      return {
        ...state,
        bool: action?.value,
      }
    case SET_EXAM_SCORE:
      return {
        ...state,
        score: action?.value,
      }
    case SET_EXAM_QUESTION_RATE:
      return {
        ...state,
        rating: action?.value,
      }

    case FETCH_USER_EXAM_SCORE:
      return {
        ...state,
        loading: true,
        err: '',
      }
    case FETCH_USER_EXAM_SCORE_SUCCESS:
      return {
        ...state,
        examScore: action?.value,
        loading: false,
      }
    case FETCH_USER_EXAM_SCORE_FAILURE:
      return {
        ...state,
        err: action.err,
      }

    case RESEL_LINK:
      return {
        ...state,
        resetPasswordLink: null,
      }
    case SET_FILTERED:
      return {
        ...state,
        filter: action?.value,
      }
    case SET_COLUMN_NUMBER:
      return {
        ...state,
        columnNum: action?.value,
      }
    case SET_DATE_RANGE:
      return {
        ...state,
        dateRange: {
          ...state.dateRange,
          [action.value.key]: action.value.val,
        },
      }
    case SET_FILTERED_BY_DATE_RANGE:
      return {
        ...state,
        filterByDate: action?.value,
      }
    case CLEAR_FILTER_BY_DATE_RANGE:
      return {
        ...state,
        dateRange: initState.dateRange,
      }
    case SET_ENABLE_EXAM_EDIT:
      return {
        ...state,
        enableEdit: action?.value,
      }
    case SET_EDITOR_STATE:
      return {
        ...state,
        editorState: action?.value,
      }
      case SET_USER_DATA:
        return {
          ...state,
          userData:{
            ...action?.payload
          }
        }

        case CLEAR_USER_DATA:
        return {
          ...state,
          userData:{}
        }
        case FETCH_WAITING_ASSESSMENT_USERS:
          return {
            ...state,
            waitingAssessmentUserLoading: true,
            err: '',
          }
        case FETCH_WAITING_ASSESSMENT_USERS_SUCCESS:
          return {
            ...state,
            waitingAssessmentUser: action.value,
            waitingAssessmentUserLoading: false,
          }
        case FETCH_WAITING_ASSESSMENT_USERS_FAILURE:
          return {
            ...state,
            waitingAssessmentUserLoading: false,
            err: action.err,
          }
    default:
      return state
  }
}

export default AdminReducer
