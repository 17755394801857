import React, { useEffect, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ButtonSecondary from "../../Button/ButtonSecondary/ButtonSecondary";
import Input from "../../Input/Input";
import Button from "../../Button/Button";
import TextHighlight from "../../TextHighlight/TextHighlight";
import {
  fetchUsers,
  clearUserInfo,
  setFilteredData,
  resetLink,
  setColNum,
  fetchAdmins,
  updateUserInformation,
  deleteUserAction,
  setUserDataAction,
} from "../../../store/actions";
import { Box, Typography } from "@material-ui/core";
import { ArrowRightIcon, SearchIcon, Addicon, MenuDotsIcon } from "../../Icons";
import { tableHeadUserHead } from "../../../helpers/objects";
import UserModal from "../../Modal/UserModel";
import useStyles from "../CreateNewUser/styles";
import HeadTable from "../../Table/HeadTable/HeadTable";
import FooterTable from "../../Table/FooterTable/FooterTable";
import debounce from "lodash.debounce";
import { useHistory } from "react-router-dom";

const AdminsUsers = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { userList, filterData, columnNum ,userData} = useSelector(({ exams }) => ({
    userList: exams?.users,
    filterData: exams?.filterData,
    columnNum: exams?.columnNum,
    userData:exams?.userData,
  }));

  useEffect(() => {
    columnNum === 1
      ? dispatch(setFilteredData("columnName", "firstName"))
      : columnNum === 2
      ? dispatch(setFilteredData("columnName", "created_date"))
      : columnNum === 3
      ? dispatch(setFilteredData("columnName", "status"))
      : dispatch(setFilteredData("columnName", "firstName"));
  }, [columnNum]);

  const onCreateHandler = () => {
    dispatch(resetLink());
    history.push("/admin/admins/create");
  };

  const editProfileHandler = (user) => {
    history.push(`/admin/admins/${user.id}/edit`);
  };

  const searchChangeHandler = (e) => {
    dispatch(setFilteredData("searchTerm", e.target.value));
    dispatch(setFilteredData("page", 1));
  };
  const debouncedChangeHandler = useCallback(
    debounce(searchChangeHandler, 500),
    []
  );

  useEffect(() => {
    dispatch(fetchAdmins(filterData, "Admin"));
    dispatch(clearUserInfo());
  }, [filterData, filterData.page, filterData.searchTerm]);

  const pageChangeHandler = (event, page) => {
    dispatch(setFilteredData("page", page));
    fetchUsers(filterData.page, filterData.size, "", "ASC", "firstName");
  };
  const sortHandler = (order, id) => {
    dispatch(setFilteredData("order", order));
    dispatch(setColNum(id));
  };

  const handleToggle = (id, status, user) => {    
    status && id &&  dispatch(setUserDataAction({id, status, user}));
  };

  const deleteUser = () => {
    userData?.id &&
      dispatch(deleteUserAction(userData?.id, filterData, "Admin")) 
  };

  const updateUserStatus = () => {
    userData?.id &&
      dispatch(
        updateUserInformation(
          userData?.id,
          {
            ...userData?.user,
            status:
              userData?.user?.account === "Active" ? "Inactive" : "Active",
          },
          filterData.page,
          filterData.size,
          "Admin",
          undefined
        )
      ) 
  };
  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.header}>
          <Typography className={classes.title} variant="h1">
            Admins
          </Typography>
          <Typography className={classes.subtext} variant="body1">
            {userList && userList?.items} Admin
          </Typography>
          <Box className={classes.search}>
            <Input
              id="search"
              labeltxt={<SearchIcon />}
              placeholderTxt="Search"
              onChange={debouncedChangeHandler}
            />
          </Box>
        </Box>
        <Box>
          <ButtonSecondary
            startIcon={<Addicon />}
            className={classes.createBtn}
            color="secondary"
            noicon="true"
            variant="contained"
            onClick={onCreateHandler}
          >
            Invite new admin
          </ButtonSecondary>
        </Box>
      </Box>
      <Box className={classes.tableContainer}>
        <Box className={classes.table} aria-label="simple table">
          <HeadTable
            headCell={tableHeadUserHead}
            classes={classes}
            sortHandler={sortHandler}
          />
          <Box>
            {userList?.transformed?.map((user) => (
              <Box key={user?.id} className={classes.tableBodyContainer}>
                <Box
                  className={classes.tableCell}
                  style={{ flex: "5", justifyContent: "flex-start" }}
                >
                  <Box className={classes.examInfo}>
                    <Box
                      className={classes.examDetails}
                    >{`${user?.name} ${user?.last}`}</Box>
                  </Box>
                </Box>
                <Box
                  className={classes.tableCell}
                  style={{ flex: "4", justifyContent: "flex-start" }}
                >
                  {user?.sessionStartDate}
                </Box>
                <Box
                  className={classes.highlights}
                  style={{ flex: "4", justifyContent: "flex-start" }}
                >
                  <Box>
                    {user?.account?.toLowerCase() === "active" ? (
                      <TextHighlight textColor="#008748" color="active">
                        {user?.account}
                      </TextHighlight>
                    ) : (
                      <TextHighlight color="disabled">Disabled</TextHighlight>
                    )}
                  </Box>
                </Box>
                <Box style={{ flex: "2", justifyContent: "center" }}>
                  <Box className={classes.tableBtnContainer}>
                    <Button
                      className={classes.tableBtn}
                      text="Edit Profile"
                      onClick={() => editProfileHandler(user)}
                    >
                      Edit Profile
                    </Button>
                    <ArrowRightIcon className={classes.linkIcon} />
                  </Box>
                </Box>
                <Box
                  style={{ flex: "1", justifyContent: "center" }}
                  className={classes.highlights}
                >
                  <UserModal
                    status={userData?.status}
                    updateUserStatus={updateUserStatus}
                    deleteUser={deleteUser}
                  >
                    <Box
                      className={classes.dots}
                      onClick={() =>
                        handleToggle(user?.id, user?.account, user)
                      }
                    >
                      <MenuDotsIcon />
                    </Box>
                  </UserModal>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>

        <FooterTable
          data={userList?.transformed}
          pages={userList?.pages}
          page={userList?.currentPage}
          classes={classes}
          pageChangeHandler={pageChangeHandler}
          length={userList?.items}
        />
      </Box>
    </>
  );
};

export default AdminsUsers;
