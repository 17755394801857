import { Box, Typography } from "@material-ui/core";
import React from "react";
import Button from "../../Button/Button";
import { TriangleArrowDown, TriangleArrowUp } from "../../Icons";
import useStyles from "./styles";

const ExamQuestion = ({
  editHandler,
  questionList,
  otherQuestionList,
  setQuestionList,
  setOtherQuestionList,
  setshowQuestionForm,
}) => {
  const classes = useStyles();

  const onDeleteHandler = (question, index) => {
    const otherUpdatedList = otherQuestionList?.map((ques, i) =>
      i === index ? { ...ques, status: "Inactive" } : { ...ques }
    );
    setOtherQuestionList(otherUpdatedList);
    const localUpdatedList = questionList.filter((item, i) => i !== index);
    setQuestionList(localUpdatedList);
    questionList?.length && setshowQuestionForm(false);
  };
  const orderUp = (index) => {
    const updatedList = [...questionList];
    let temp;
    if (index - 1 > -1) {
      temp = updatedList[index - 1];
      updatedList[index - 1] = updatedList[index];
      updatedList[index] = temp;
      setQuestionList([...updatedList]);
    }
  };
  const orderDown = (index) => {
    const updatedList = [...questionList];
    let temp;
    if (index + 1 < updatedList.length) {
      temp = updatedList[index + 1];
      updatedList[index + 1] = updatedList[index];
      updatedList[index] = temp;
      setQuestionList([...updatedList]);
    }
  };

  const renderdQuestions = questionList?.map(
    (question, index) =>
      question?.status?.toLowerCase() !== "inactive" && (
        <Box className={classes.container} key={question?.id}>
          <Typography variant="h5">Question {index + 1}</Typography>
          <Box className={classes.questionInfo}>
            <Typography variant="h5">{question?.title}</Typography>
            <Box className={classes.btnContainer}>
              <Button
                className={classes.uploadBtnTextDelete}
                onClick={onDeleteHandler.bind(this, question, index)}
              >
                Delete
              </Button>
              <Button
                className={classes.uploadBtnTextEdit}
                onClick={() =>
                  editHandler({
                    id: question?.id,
                    title: question?.title,
                    description: question?.description,
                    correctanswer: question?.correctanswer,
                    multichoice: question?.multichoice,
                    index: index,
                  })
                }
              >
                Edit
              </Button>
            </Box>
            <Box className={classes.order}>
              <TriangleArrowUp onClick={orderUp.bind(this, index)} />
              <TriangleArrowDown onClick={orderDown.bind(this, index)} />
            </Box>
          </Box>
        </Box>
      )
  );

  return <>{renderdQuestions}</>;
};

export default ExamQuestion;
