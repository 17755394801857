// update exam after starting
export const UPDATE_STATUS = "UPDATE_STATUS";
export const UPDATE_TIME = "UPDATE_TIME";

export const LOADING_LOGIN = "LOADING_LOGIN";
export const LOGGEDIN_SUCCESS = "LOGGEDIN_SUCCESS";
export const LOGGEDIN_FAILED = "LOGGEDIN_FAILED";

export const SEND_EMAIL = "SEND_EMAIL";
export const SEND_EMAIL_SUCCESS="SEND_EMAIL_SUCCESS";
export const SEND_EMAIL_FAILED="SEND_EMAIL_FAILED";

export const RESET_PASSWORD="RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS="RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILED="RESET_PASSWORD_FAILED";
// logout
export const LOGOUT = "LOGOUT";
export const SET_ROLE ="SET_ROLE"
// ----------------------- sorting --------------------------------
// exam
export const SORT_BY_NAME = "SORT_BY_NAME";
export const SORT_BY_STATUS = "SORT_BY_STATUS";
export const SORT_BY_ACTIVE_SESSION = "SORT_BY_ACTIVE_SESSION";
export const SORT_BY_TO_ASSESS = "SORT_BY_TO_ASSESS";
export const SORT_BY_ACCOUNT_STATUS = "SORT_BY_ACCOUNT_STATUS";
export const SORT_BY_SCORE = "SORT_BY_SCORE";
export const SORT_BY_DATE = "SORT_BY_DATE";

// user
export const SORT_PARTICIPANT_BY_NAME = "  SORT_PARTICIPANT_BY_NAME";
export const SORT_PARTICIPANT_BY_STATUS = "  SORT_PARTICIPANT_BY_STATUS";
export const SORT_PARTICIPANT_BY_SCORE = "  SORT_PARTICIPANT_BY_SCORE";


