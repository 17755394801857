import { Box, Paper, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import useStyles from "./styles";

const EmptyBoard = () => {
  const classes = useStyles();

  return (
    <Fragment>
      <Box className={classes.content} >
        <Box className={classes.header} >
          <Typography variant="h1">Exams</Typography>
        </Box>
      </Box>

      <Paper className={classes.paper} elevation={0}>
        <Typography variant="h3">You don’t have active exams</Typography>
      </Paper>
    </Fragment>
  );
};

export default EmptyBoard;
