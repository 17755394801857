import React from 'react'
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core'
import useStyles from './styles'
import check from '../../assets/check.svg'
import { useSelector } from 'react-redux'
const CheckList = ({ examQuestions }) => {
  const classes = useStyles()
  const { checkArray, examAnswers } = useSelector(
    ({ examsUser, examDetail }) => ({
      checkArray: examsUser.checkArray,
      examAnswers: examDetail.examAnswers,
    }),
  )
  const renderdCheckList =
    examQuestions?.map((question) => {
      return (
        <a href={`#${question.id}`} className={classes.link} key={question.id}>
          <ListItem
            key={question.id}
            className={
              question.id !== examQuestions[examQuestions.length - 1].id
                ? classes.item
                : ''
            }
          >
            <ListItemText
              primary={
                <Typography variant="body2">{question.title}</Typography>
              }
            />
            {!checkArray.includes(question.id) &&
              examAnswers.find((e) => e.Question.id === question.id)?.Answer
                ?.description && <img src={check} alt="" />}
          </ListItem>
        </a>
      )
    }) ?? null

  return (
    <List className={classes.list}>
      <ListItem>
        <ListItemText
          className={classes.listTitle}
          primary={<Typography variant="h4">Questions Checklist</Typography>}
        />
      </ListItem>
      <Box className={classes.items}>{renderdCheckList}</Box>
    </List>
  )
}

export default CheckList
