import React, { useState, useEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import useStyles from "./styles";
import StarRating from "../StarRating/StarRating";
import { setQuestionScore } from "../../../store/actions";
import { setExamQuestionRating } from "../../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import { lightGreen } from "@material-ui/core/colors";
import { fetchUserExamTotalScore } from "../../../store/actions";
import CodeEditor from "@uiw/react-textarea-code-editor";
const QuestionPreview = ({
  canEdit,
  questionId,
  questionTitle,
  questionBody,
  score,
  status,
  examId,
  participantId,
  userId,
  isMultipleChoice,
  correctAnswer,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { rating, examScore, loading } = useSelector(
    ({ exams, examDetail }) => ({
      rating: exams?.rating,
      examScore: exams?.examScore,
      loading: examDetail?.loading,
    })
  );

  useEffect(() => {
    dispatch(setExamQuestionRating(score));
    dispatch(fetchUserExamTotalScore(examId, participantId));
  }, []);

  const rateStyle = {
    notAssessed: "notAssessed",
    bad: "bad",
    good: "good",
    excellent: "excellent",
  };
  const style =
    score <= 2 && score !== null
      ? rateStyle.bad
      : score === 3
      ? rateStyle.good
      : score > 3
      ? rateStyle.excellent
      : rateStyle.notAssessed;

  const saveRating = (value) => {
    dispatch(setExamQuestionRating(value));
    dispatch(setQuestionScore(participantId, examId, questionId, value));
  };

  useEffect(() => {
    if (isMultipleChoice && !score) {
      const answerChar =
        questionBody && questionBody.toString().trim().toLowerCase();
        const newCorrectAnswer = correctAnswer.toString().trim().toLowerCase()
      if (answerChar === newCorrectAnswer) saveRating(5);
      else saveRating(1);
    }
  }, []);

  return (
    <>
      <Box className={[classes.questionContainer, classes[style]].join(" ")}>
        <Box className={classes.questionHeader}>
          <Typography variant="h3">{questionTitle}</Typography>
          <Typography variant="h3">{score ? score : "Score: N/A"}</Typography>

          <Typography className={classes.viewQ} variant="h6">
            View Question
          </Typography>
        </Box>
        <Box className={classes.questionBody}>
          {/**<Typography variant="body1">{"questionBody"}</Typography>*/}
          <CodeEditor
            disabled={true}
            variant="body1"
            name="answerText"
            id=""
            rows="10"
            value={questionBody}
            language="js"
            padding={10}
            style={{
              fontSize: 12,
              backgroundColor: "#fff",
              fontFamily:
                "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
            }}
          />
        </Box>
      </Box>
      <Box className={classes.rate}>
        <Typography variant="body1">Please rate out of 5</Typography>
        <StarRating
          saveRating={saveRating}
          key={questionId}
          questionId={questionId}
          score={score}
          status={status}
          style={style}
          examId={examId}
          participantId={participantId}
        />
      </Box>

      <Typography variant="h5">
        Score: {score ? score : "Score: N/A"}
      </Typography>
    </>
  );
};

export default QuestionPreview;
