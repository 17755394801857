export {default as Addicon} from './AddIcon/AddIcon';
export {default as ArrowDownIcon} from './ArrowDownIcon/ArrowDownIcon';
export {default as ArrowLeftIcon} from './ArrowLeftIcon/ArrowLeftIcon';
export {default as ArrowRightIcon} from './ArrowRightIcon/ArrowRightIcon';
export {default as ArrowUpIcon} from './ArrowUpIcon/ArrowUpIcon';
export {default as CalendarIcon} from './CalendarIcon/CalendarIcon';
export {default as EditIcon} from './EditIcon/EditIcon';
export {default as EyeIcon} from './EyeIcon/EyeIcon';
export {default as GridViewIcon} from './GridViewIcon/GridViewIcon';
export {default as ImgIcon} from './ImgIcon/ImgIcon';
export {default as ListViewIcon} from './ListViewIcon/ListViewIcon';
export {default as MenuDotsIcon} from './MenuDotsIcon/MenuDotsIcon';
export {default as PaperClipIcon} from './PaperClipIcon/PaperClipIcon';
export {default as Person} from './Person/Person';
export {default as PlaneIcon} from './PlaneIcon/PlaneIcon';
export {default as SearchIcon} from './SearchIcon/SearchIcon';
export {default as StarIcon} from './StarIcon/StarIcon';
export {default as TestIcon} from './TestIcon/TestIcon';
export {default as TriangleArrowDown} from './TriangleArrowDown/TriangleArrowDown';
export {default as TriangleArrowUp} from './TriangleArrowUp/TriangleArrowUp';
export {default as UserIcon} from './UserIcon/UserIcon';
export {default as WarningIcon} from './WarningIcon/WarningIcon';




















