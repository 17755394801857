import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  highlights: {
    '& span': {
      width: '63px',
    },
    '& .makeStyles-disabled-104': {
      width: '75px',
    },
  },
  inputContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '17px',
    '& > * + *': {
      marginLeft: '27px',
    },
  },
  filterContainer: {
    padding: '0 22px',
    paddingTop: '27px',
    background: theme.palette.white.main,
  },
  subtext: {
    marginBottom: '31px',
    color: theme.palette.neutral.dark,
  },
  title: {
    marginTop: '0',
    marginBottom: '3px',
    color: theme.palette.neutral.main,
  },
  search: {
    width: '264px',
    '& input': {
      height: '38px',
      backgroundColor: 'transparent',
    },
    '& label': {
      position: 'absolute',
      right: '10px',
      top: '5px',
    },
  },
  selectFilter: {
    width: '162px',
    display: 'flex',
    alignItems: 'center',
    '& input': {
      height: '38px',
      backgroundColor: 'transparent',
    },
    '& label': {
      position: 'absolute',
      right: '10px',
      top: '5px',
    },
  },
  selectInput: {
    height: '38px',
    width: '162px',
    padding: '10px',
    borderColor: '#E8E8E8',
    borderRadius: '5px',
    color: '#333',
  },
  container: {
    padding: '30px 22px 0px 22px',
    background: theme.palette.white.main,
    borderRadius: '10px 10px 0 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    position: 'relative',
    '&::after': {
      display: 'block',
      content: '" "',
      position: 'absolute',
      height: '2px',
      left: '22px',
      right: '22px',
      bottom: '0',
    },
  },
  createBtn: {
    width: '175px',
    textTransform: 'unset',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '5px',
    borderRadius: '10px',
    '& span:first-child': {
      marginRight: '10px',
    },
  },
  activeSessions: {
    color: theme.palette.secondary.main,
  },
  examInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  examImg: {
    width: '80.95px',
    height: '53px',
    borderRadius: '5px',
  },
  examDetails: {
    marginLeft: '16px',
    '& h3': {
      marginTop: '0',
      marginBottom: '3px',
    },
    textTransform: "lowercase",
    "&::first-line": {
      textTransform: "capitalize",
    },
  },
  filter: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '9px',
    '& * + *': {
      marginTop: '3.35px',
    },
  },
  tableHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  tableCell: {
    color: theme.palette.neutral.main,
    fontWeight: 'normal',
  },
  tableDots: {},
  headerRow: {

    display: 'flex',
    alignItems: 'center',
    paddingBottom: '13px',
    width: '100%',
  },
  tableContainer: {
    backgroundColor: theme.palette.white.main,
    borderRadius: '0 0 10px 10px',
    paddingTop: '17px',
    '& .MuiTableCell-body': {
      padding: '8px',
    },
  },
  tableBtnContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& span': {
      marginTop: '0',
      textDecoration: 'none',
      color: theme.palette.info.main,
      fontWeight: 'bold',
    },
    '& div': {
      width: 'unset',
      marginRight: '11px',
    },
  },

  tableBtn: {
    '& span': {
      textTransform: 'initial',
    },
  },
  arrowIcon: {
    fill: theme.palette.neutral.light,
    cursor: 'pointer',
    '&:focus, &:hover, &:active': {
      filter:
        'invert(43%) sepia(34%) saturate(4439%) hue-rotate(197deg) brightness(96%) contrast(96%)',
    },
  },
  linkIcon: {
    width: '7.29px',
    height: '13.41px',
    fill: theme.palette.info.main,
  },
  headerBtnContainer: {
    display: 'flex',
    '& > * + *': {
      marginLeft: '20px',
    },
  },
  createUserForm: {
    maxWidth: '720px',
    width: '100%',
    backgroundColor: theme.palette.white.main,
    padding: '22px',
    borderRadius: '10px',
  },
  formContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: '27px',
    paddingBottom: '27px',
    '& div': {
      width: '100%',
    },
    '& div:not(:last-child)': {
      width: '324px',
      marginRight: '14px',
    },
  },
  uploadBtnTextEdit: {
    marginRight: '34px',
    '&:hover': {
      background: 'none',
    },
    '& span': {
      color: theme.palette.primary.main,
      textTransform: 'initial',
    },
  },
  btnContainer: {
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingTop: '0px',
    position: 'relative',
    '&::after': {
      display: 'block',
      content: '" "',
      position: 'absolute',
      height: '1px',
      left: '0',
      right: '0',
      top: '0',
    },
  },
  paginationIcon: {
    fill: theme.palette.neutral.light,
    width: '4.86px',
    height: '8.94px',
  },
  tableFooter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '0px',
    padding: '30px 20px 20px 20px',
  },
  footerBtn: {
    '& .MuiButton-outlinedSecondary.Mui-disabled': {
      border: 'none',
    },
    '& .MuiButton-outlined.Mui-disabled': {
      border: 'none',
    },
    '&,&:hover,&:active': {
      all: 'unset',
      display: 'flex',
      alignItems: 'center',
      marginRight: '20px',
      marginLeft: '20px',
    },
    '& span': {
      color: `${theme.palette.neutral.dark} !important`,
    },
    cursor: 'pointer',
  },
  pagination: {
    '& .MuiPaginationItem-page.Mui-selected': {
      backgroundColor: 'rgb(255 255 255 / 8%)',
      border: '1px solid #3E40B7',
      borderRadius: '6px',
      fontWeight: 'bold',
      fontSize: '14px',
      lineHeight: '150%',
      color: '#3E40B7',
    },
  },
  header: {
    paddingBottom: '18px',
    position: 'relative',
  },
  dots: {
    marginTop: '7px',
    cursor: 'pointer',
  },
  buttons: {
    lineHeight: '150%',
    fontWeight: 'normal',
    cursor: 'pointer',
  },
  copyBtn: {
    marginLeft: '34px',
    width: '175px',
    textTransform: 'unset',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    marginTop: '5px',
    borderRadius: '10px',
    '&:disabled': {
      '& span': {
        color: '#ACABAB',
      },
    },
  },
  toastMessage: {
    position: 'absolute',
    left: '47%',
    top: '20px',
    height: '60px',
    width: '300px',
    color: '#fff',
    background: '#00B761',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
    borderRadius: '10px',
  },
  tableHead: {
    display: 'flex',
    paddingLeft: '20px',
    width: '100%',
    borderBottom: '1px solid #E8E8E8',
  },
  tableBodyContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '7px 0px 7px 11.5px',
    borderBottom: '1px solid #E8E8E8',
  },
  highlights: {
    display: 'flex',
    justifyContent: 'center',
    '& span': {
      width: '63px',
    },
  },
  formLabel: {
    margin: '0.4375rem 0', //7px
    textTransform: 'capitalize',
    display: 'inline-block',
  },
  formInput: {
    padding: '17px 10px',
    width: '100%',
    borderRadius: '5px',
    border: `1px solid ${theme.palette.border}`,
    '&:focus, &:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
      boxShadow: `0px 0px 3px ${theme.palette.shadow.main}`,
      outline: 'none',
    },
    '&:hover': {
      boxShadow: 'none',
    },
    '&:invalid': {
      border: `1px solid ${theme.palette.error.main}`,
    },
  },
  emailFormInput: {
    '& div:not(:last-child)': {
      width: '100%',
      marginRight: '14px',
    },
  },
}))

export default useStyles
