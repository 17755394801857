import React from "react";
import Button from "../Button";

const ButtonSecondary = ({ noicon="true", variant, color, children, ...props }) => {
  return (
    <Button size="small"  variant={variant} color={color} {...props} >
      {children}
    </Button>
  );
};

export default ButtonSecondary;
