import {} from "../types";

import { getUser } from "../services";
import { GET_ASSESSMENT_PARTICIPANT_INFO } from "../types";

export const getAssessmentParticipantInfo = (userId) => (dispatch) => {
  getUser(userId)
    .then((res) => {
      dispatch({
        type: GET_ASSESSMENT_PARTICIPANT_INFO,
        payload: res,
      });
    })
    .catch((e) => {
      console.log(e);
    });
};
