import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: '20px',
    width: '100%',
    margin: '48px 0px',
    boxShadow: theme.cardShadow,
    marginTop: '0',
    '& img + h3': {
      marginTop: '26px',
    },
    '& h3': {
      marginTop: '36px',
    },
  },
  examFigure: {
    maxWidth: '100%',
  },

  examHeader: {
    padding: '10px',
  },
  examHeaderTxt: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white.main,
    padding: '20px',
    borderRadius: '10px',
  },
  question: {
    margin: 0,
  },
  content: {
    padding: '35px 30px',
  },
  examTitle: {
    margin: '20px 0 22px',
    textTransform: 'capitalize',
  },
  divider: {
    margin: '22px 0px 0px',
  },
  timerBox: {
    borderRadius: '20px',
    padding: '16px',
    width: '299px',
    minHeight: '450px',
    marginLeft: '20px',
    backgroundColor: theme.palette.white.main,
    boxShadow: theme.cardShadow,
    alignSelf: 'flex-start',
    position: 'sticky',
    top: '10px',
    scrollBehavior: 'smooth',
  },
  backToExamLink: {
    flex: 0.25,
    textDecoration: 'none',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
  },
  arrowIcon: {
    width: '7.3px',
    height: '13.41px',
    marginRight: '12.81px',
  },
  backToExamText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '18.75px',
    color: '#0D4BA7',
    letterSpacing: '0px',
  },
  examDesc: {
    whiteSpace: 'pre-line',
  },
  submitButton: {
    width: '264px',
    textTansform: 'capitalize',
    textTransform: 'none',
    borderRadius: '10px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#fff',
    '&.MuiTypography-body2': {
      fontWeight: 400,
    },
    '&:hover': {
      backgroundColor: '#008748',
    },
    '&:disabled': {
      color: '#ACABAB',
      background: '#E8E8E8',
    },
  },
}));

export default useStyles;
