import React from 'react'

const EditIcon = (props) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M12.9373 0.620007L4.80934 8.74801C4.4989 9.05677 4.25279 9.42405 4.08526 9.82857C3.91773 10.2331 3.83211 10.6668 3.83334 11.1047V12C3.83334 12.1768 3.90358 12.3464 4.0286 12.4714C4.15363 12.5964 4.3232 12.6667 4.50001 12.6667H5.39534C5.83318 12.6679 6.26692 12.5823 6.67145 12.4148C7.07597 12.2472 7.44324 12.0011 7.75201 11.6907L15.88 3.56267C16.2696 3.17212 16.4884 2.64299 16.4884 2.09134C16.4884 1.53969 16.2696 1.01056 15.88 0.620007C15.4838 0.24127 14.9568 0.0299072 14.4087 0.0299072C13.8606 0.0299072 13.3336 0.24127 12.9373 0.620007ZM14.9373 2.62001L6.80934 10.748C6.43342 11.1216 5.92535 11.332 5.39534 11.3333H5.16667V11.1047C5.16806 10.5747 5.37839 10.0666 5.75201 9.69067L13.88 1.56267C14.0224 1.42664 14.2117 1.35074 14.4087 1.35074C14.6056 1.35074 14.7949 1.42664 14.9373 1.56267C15.0773 1.70302 15.1559 1.89314 15.1559 2.09134C15.1559 2.28955 15.0773 2.47966 14.9373 2.62001Z"
          fill="#3E40B7"
        />
        <path
          d="M15.8333 5.986C15.6565 5.986 15.487 6.05624 15.3619 6.18126C15.2369 6.30629 15.1667 6.47586 15.1667 6.65267V10H12.5C11.9696 10 11.4609 10.2107 11.0858 10.5858C10.7107 10.9609 10.5 11.4696 10.5 12V14.6667H3.83333C3.3029 14.6667 2.79419 14.456 2.41912 14.0809C2.04405 13.7058 1.83333 13.1971 1.83333 12.6667V3.33333C1.83333 2.8029 2.04405 2.29419 2.41912 1.91912C2.79419 1.54405 3.3029 1.33333 3.83333 1.33333H9.86133C10.0381 1.33333 10.2077 1.2631 10.3327 1.13807C10.4578 1.01305 10.528 0.843478 10.528 0.666667C10.528 0.489856 10.4578 0.320286 10.3327 0.195262C10.2077 0.0702379 10.0381 0 9.86133 0L3.83333 0C2.9496 0.00105857 2.10237 0.352588 1.47748 0.97748C0.852588 1.60237 0.501059 2.4496 0.5 3.33333L0.5 12.6667C0.501059 13.5504 0.852588 14.3976 1.47748 15.0225C2.10237 15.6474 2.9496 15.9989 3.83333 16H11.3953C11.8333 16.0013 12.2671 15.9156 12.6718 15.7481C13.0764 15.5806 13.4438 15.3345 13.7527 15.024L15.5233 13.252C15.8338 12.9432 16.08 12.576 16.2477 12.1715C16.4153 11.767 16.5011 11.3332 16.5 10.8953V6.65267C16.5 6.47586 16.4298 6.30629 16.3047 6.18126C16.1797 6.05624 16.0101 5.986 15.8333 5.986ZM12.81 14.0813C12.542 14.3487 12.2031 14.5337 11.8333 14.6147V12C11.8333 11.8232 11.9036 11.6536 12.0286 11.5286C12.1536 11.4036 12.3232 11.3333 12.5 11.3333H15.1167C15.0342 11.7023 14.8493 12.0406 14.5833 12.3093L12.81 14.0813Z"
          fill="#3E40B7"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default EditIcon
