import { Typography, Grid } from '@material-ui/core'
import useStyles from '../styles'
import CheckList from '../../CheckList/CheckList'
import Timer from '../../Timer/TimerLib/Timer'
import TimerPlaceHolder from '../../TimerPlaceHolder/TimerPlaceHolder'

const ExamTimer = ({ remainingTime, id, examQuestions, exam }) => {
  const classes = useStyles()
  return (
    <Grid className={classes.timerBox} item>
      {remainingTime > 0 ? (
        <Timer countedTime={remainingTime} id={id} />
      ) : (
        <TimerPlaceHolder duration={exam?.timeTotal} />
      )}
      <Typography variant="caption" className={classes.examDesc}>{exam?.description}</Typography>
      <CheckList examQuestions={examQuestions} />
    </Grid>
  )
}

export default ExamTimer
