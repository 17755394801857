import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'inline-block',
    marginBottom: '25px',
    maxWidth: '729px',
    width: '100%',
    padding: '22px',
    background: theme.palette.white.main,
    borderRadius: '10px',
    userSelect: 'none',
  },
  content: {
    // width: '100%',
  },
  examDescriptionContainer: {
    marginTop: '27px',
  },
  examDescription: {
    fontFamily: 'Roboto,sans-serif',
    width: '100%',
    borderRadius: '5px',
    height: '98px',
    resize: 'none',
    padding: '10px',
    marginTop: '7px',
    border: '1px solid #E8E8E8',
    overflowY: "auto"
  },
  box: {
    userSelect: 'none',
    width: '100%',
    marginTop: '27px',
    padding: '22px',
    borderRadius: '10px',
    border: '1px solid #E8E8E8',
    '& h5': {
      margin: '0',
      marginBottom: '13px',
      '& .Mui-selected': {
        backgroundColor: 'transparent',
      },
    },
  },
  uploadBtnText: {
    marginLeft: '10px',
    color: theme.palette.info.main,
    fontWeight: '400',
    textTransform: 'initial',
    cursor: 'pointer',
  },
  uploadInput: {
    visibility: 'hidden',
    width: '0',
    height: '0',
  },
  uploadBtn: {
    height: '50px',
    width: '156px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '0px 5px 5px 0px',
    textTransform: 'initial',
    color: theme.palette.white.main,
    cursor: 'pointer',
    boxSizing: 'border-box',
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    backgroundColor: theme.palette.info.main,
    '&:hover': {
      background: theme.palette.info.dark,
    },
  },
  uploadImgText: {
    display: 'flex',
  },
  remove: {
    color: theme.palette.error.main,
    background: 'none',
    border: 'none',
    outline: 'none',
    boxSizing: 'border-box',
  },
  uploadContainer: {
    paddingLeft: '16px',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '1px solid #E8E8E8',
    width: '641px',
  },
  imgContainer: {
    display: 'flex',
  },
  div: {
    marginTop: '18px',
  },

  duration: {},
  wrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    marginTop: '16px',
  },
  editExamPhoto: {
    paddingLeft: '16px',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '1px solid red',
    width: '641px',
    
  },
}))

export default useStyles
