import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  appBar: {
    width: '223px',
    right: 'auto',
    boxShadow: 'none',
  },
  sidebar: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    '& .MuiDrawer-paper': {
      width: '223px',
      padding: '2.5rem 1.375rem 1.75rem;',
      border: 'none',
      backgroundColor: theme.palette.white.main,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      position: 'relative',
      overflow: 'hidden',
      boxSizing: 'border-box',
      '& .MuiListItem-button': {
        margin: '19px 0',
      },
    },
  },
  profileImg: {
    margin: '16px 0',
    paddingTop: '0',
    paddingBottom: '0',
  },
  list: {
    width: '179px',
    paddingTop: 0,
    flex: '1',
    [theme.breakpoints.down('sm')]: {
      width: 'fit-content',
    },
    '& .MuiListItem-root': {
      borderRadius: '10px',
      textTransform: 'capitalize',
    },
    avatar: {
      width: '48px',
      height: '48px',
    },
    MuiSvgIcon: {
      root: {
        width: 48,
        height: 48,
      },
    },
  },
  logo: {
    width: '95px',
    height: '108px',
    marginBottom: '10px',
  },
  contained: {
    maxHeight: '1024px',
  },
  selected: {
    backgroundColor: theme.palette.primary.lighter,
    '& span': {
      fontWeight: '700',
    },
    '& svg': {
      fill: theme.palette.primary.main,
    },
  },
  editProfileTxt: {
    '& span': {
      color: theme.palette.info.dark,
      cursor: 'pointer',
    },
  },
  userIcon: {
    fill: '#000',
  },
  testIcon: {
    fill: '#000',
  },
}));

export default useStyles;
