import React, { useEffect, useState } from "react";
import ButtonSecondary from "../../Button/ButtonSecondary/ButtonSecondary";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography } from "@material-ui/core";
import { EyeIcon, EditIcon, UserIcon } from "../../Icons";
import { useParams, useHistory } from "react-router-dom";
import {
  fetchExamQuestions,
  setEnableExamEdit,
  updateExamInformation,
} from "../../../store/actions";
import ConfirmationModal from "../../Modal/ConfirmationModal";

const ExamActionButtons = ({
  classes,
  open,
  userList,
  enableEdit,
  examUsers,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const handleToggle = () => setOpenModal(!openModal);
  const previewHandler = (e) => {
    dispatch(fetchExamQuestions(id));
    e.preventDefault();
    history.push(`/admin/exam/${id}/preview`);
  };

  const openConfirmationModal = (e) => {
    e.preventDefault();
    handleToggle();
  };
  const deleteHandler = (e) => {
    dispatch(updateExamInformation(id, { status: "Inactive" }));
    history.push(`/admin/home`);
  };
  const editHandler = (e) => {
    history.push(`/admin/edit/${id}`);
  };

  useEffect(() => {
    const enable = userList?.filter(
      (user) => user?.status?.toLowerCase() === "started"
    );
    dispatch(setEnableExamEdit(enable));
  }, [userList]);

  return (
    <Box className={classes.headerBtnContainer}>
      <ConfirmationModal
        handleClose={handleToggle}
        open={openModal}
        msg="Delete an Exam?"
        mainText="Are you sure you want to completely delete the exam? This cannot be undone."
        buttonHandler={deleteHandler}
      />
      <ButtonSecondary
        startIcon={<EyeIcon />}
        className={classes.createBtn}
        color="primary"
        noicon="true"
        variant="outlined"
        onClick={previewHandler}
      >
        Preview
      </ButtonSecondary>
      <ButtonSecondary
        startIcon={<EyeIcon />}
        className={classes.createBtn}
        color="primary"
        noicon="true"
        variant="outlined"
        onClick={openConfirmationModal}
      >
        Delete
      </ButtonSecondary>
      <ButtonSecondary
        startIcon={
          <EditIcon className={examUsers >= 1 && classes.disableEditIcon} />
        }
        className={examUsers >= 1 ? classes.disableEditBtn : classes.createBtn}
        color="primary"
        noicon="true"
        variant="outlined"
        onClick={editHandler}
        disabled={examUsers >= 1}
      >
        <Typography
          className={
            examUsers >= 1
              ? classes.disableEditBtnText
              : classes.enableEditBtnText
          }
        >
          Update
        </Typography>
      </ButtonSecondary>
      <ButtonSecondary
        startIcon={<UserIcon className={classes.userIcon} />}
        className={classes.createBtn}
        color="secondary"
        noicon="true"
        variant="contained"
        onClick={open}
      >
        Participants
      </ButtonSecondary>
    </Box>
  );
};

export default ExamActionButtons;
