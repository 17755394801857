import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
  },
  spinner: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: `1px solid ${theme.palette.border}`,
    borderRadius: "5px",
    maxWidth: "202.33px",
    width: "100%",
    "& h3": {
      margin: "0 auto",
      color: theme.palette.neutral.light,
      userSelect: "none",
    },
    "&:not(:last-child)": {
      marginRight: "19px",
    },
  },
  colored: {
    "& h3": {
      color: theme.palette.primary.main,
    },
  },
  spinnerButtonContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    alignSelf: "center",
    maxWidth: "15px",
    marginRight: "11px",
    width: "100%",
    height: "35px",
    backgroundColor: theme.palette.background.main,
    "& svg": {
      fill: theme.palette.primary.main,
    },
    "& svg:first-child": {
    },
  },
  spinnerLabel: {
    maxWidth: "64px",
    width: "100%",
    height: "43px",
    backgroundColor: theme.palette.background.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    userSelect: "none",
  },
  spinnerWrapper: {
    // border: "1px solid red",
    width: " 100%",
    cursor: "pointer",
    height: " 37%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },
}));

export default useStyles;
