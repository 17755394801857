import React from 'react'
import { ArrowRightIcon, ArrowLeftIcon } from '../../Icons'
import { Box, Typography, Button } from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import { useDispatch } from 'react-redux'
import { setFilteredData } from '../../../store/actions'

const FooterTable = ({
  classes,
  data,
  pages,
  page,
  pageChangeHandler,
  length,
}) => {
  const dispatch = useDispatch()

  const backButtonHandler = () => {
    dispatch(setFilteredData('page', parseInt(page) - 1))
  }

  const nextButtonHandler = () => {
    dispatch(setFilteredData('page', parseInt(page) + 1))
  }

  return (
    <Box className={classes.tableFooter}>
      <Typography variant="body1" color="textSecondary">
        showing {data && data?.length} out of {length}
      </Typography>
      <Box className={classes.btnContainer}>
        <Button
          startIcon={<ArrowLeftIcon className={classes.paginationIcon} />}
          className={classes.footerBtn}
          color="secondary"
          noicon="true"
          variant="text"
          disableRipple
          onClick={backButtonHandler}
          disabled={page <= 1}
        >
          <span className={classes.buttons}>Back</span>
        </Button>
        <Pagination
          className={classes.pagination}
          hideNextButton={true}
          hidePrevButton={true}
          count={pages ? pages : 0}
          page={parseInt(page) ? parseInt(page) : 0}
          shape={'rounded'}
          onChange={pageChangeHandler}
        />
        <Button
          endIcon={<ArrowRightIcon className={classes.paginationIcon} />}
          className={classes.footerBtn}
          color="secondary"
          variant="text"
          disableRipple
          onClick={nextButtonHandler}
          disabled={page >= pages}
        >
          <span className={classes.buttons}>Next</span>
        </Button>
      </Box>
    </Box>
  )
}

export default FooterTable
