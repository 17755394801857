export const FETCH_EXAM_BY_ID = 'FETCH_EXAM_BY_ID'
export const FETCH_EXAM_BY_ID_SUCCESS = 'FETCH_EXAM_BY_ID_SUCCESS'
export const FETCH_EXAM_BY_ID_FAIL = 'FETCH_EXAM_BY_ID_FAIL'

export const FETCH_EXAM_ANSWERS = 'FETCH_EXAM_ANSWERS'
export const FETCH_EXAM_ANSWERS_SUCCESS = 'FETCH_EXAM_ANSWERS_SUCCESS'
export const FETCH_EXAM_ANSWERS_FAILURE = 'FETCH_EXAM_ANSWERS_FAILURE'

export const FETCH_EXAM_QUESTIONS = 'FETCH_EXAM_QUESTIONS'
export const FETCH_EXAM_QUESTIONS_SUCCESS = 'FETCH_EXAM_QUESTIONS_SUCCESS'
export const FETCH_EXAM_QUESTIONS_FAILURE = 'FETCH_EXAM_QUESTIONS_FAILURE'

export const FETCH_USER_INFORMATION = 'FETCH_USER_INFORMATION'
export const FETCH_USER_INFORMATION_SUCCESS = 'FETCH_USER_INFORMATION_SUCCESS'
export const FETCH_USER_INFORMATION_FAILURE = 'FETCH_USER_INFORMATION_FAILURE'

export const UPDATE_USER_INFORMATION = 'UPDATE_USER_INFORMATION'
export const UPDATE_USER_INFORMATION_SUCCESS = 'UPDATE_USER_INFORMATION_SUCCESS'
export const UPDATE_USER_INFORMATION_FAILURE = 'UPDATE_USER_INFORMATION_FAILURE'

export const FETCH_ALL_EXAM_USERS = 'FETCH_ALL_EXAM_USERS'
export const FETCH_ALL_EXAM_USERS_SUCCESS = 'FETCH_ALL_EXAM_USERS_SUCCESS'
export const FETCH_ALL_EXAM_USERS_FAILURE = 'FETCH_ALL_EXAM_USERS_FAILURE'

export const SET_TIME_COMPLETED = 'SET_TIME_COMPLETED'

export const UPDATE_USER_INPUT_FIELD = 'UPDATE_USER_INPUT_FIELD'

export const CLEAR_USER_INFO = 'CLEAR_USER_INFO'

export const SHOW_SNACK_BAR = 'SHOW_SNACK_BAR'

export const UPDATE_EXAM_INPUT_FIELD = 'UPDATE_EXAM_INPUT_FIELD'

export const UPDATE_EXAM_DURATION_FIELD = 'UPDATE_EXAM_DURATION_FIELD'

export const UPDATE_EXAM_QUESTION_FIELD = 'UPDATE_EXAM_QUESTION_FIELD'

export const CLEAR_EXAM_INFO = 'CLEAR_EXAM_INFO'
