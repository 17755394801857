import { Typography } from "@material-ui/core";
import React from "react";
import useStyles from "./styles";
const TextHighlight = ({ textColor, backgroundColor, color, children }) => {
  const classes = useStyles();
  const customStyles = {};
  textColor && (customStyles.color = textColor);
  backgroundColor && (customStyles.backgroundColor = backgroundColor);
  return (
    <Typography
      style={customStyles}
      className={[classes.box, classes[color]].join(" ")}
      variant="body2"
      component="span"
    >
      {children}
    </Typography>
  );
};

export default TextHighlight;