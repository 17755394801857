import React from "react";

function TriangleArrowUp(props) {
  return (
    <svg
      width="6"
      height="4"
      viewBox="0 0 6 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0.672559 3.1666H5.32756C5.40996 3.16659 5.4905 3.14214 5.559 3.09635C5.62751 3.05057 5.6809 2.9855 5.71243 2.90938C5.74396 2.83325 5.75221 2.74949 5.73614 2.66867C5.72007 2.58786 5.6804 2.51363 5.62214 2.45535L3.29464 0.127854C3.21651 0.0497408 3.11054 0.00585938 3.00006 0.00585938C2.88957 0.00585938 2.78361 0.0497408 2.70548 0.127854L0.377976 2.45535C0.319722 2.51363 0.280053 2.58786 0.263983 2.66867C0.247913 2.74949 0.256164 2.83325 0.287694 2.90938C0.319223 2.9855 0.372614 3.05057 0.441118 3.09635C0.509622 3.14214 0.590163 3.16659 0.672559 3.1666Z" />
    </svg>
  );
}

export default TriangleArrowUp;
