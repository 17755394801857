import React from "react";

const SearchIcon = ({ color }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.707 22.293L17.738 16.324C19.3646 14.3346 20.1644 11.7961 19.9719 9.23359C19.7793 6.67106 18.6092 4.28056 16.7036 2.55655C14.798 0.832537 12.3026 -0.0930816 9.73367 -0.0288498C7.16472 0.035382 4.71873 1.08455 2.90164 2.90164C1.08455 4.71873 0.035382 7.16472 -0.0288498 9.73367C-0.0930816 12.3026 0.832537 14.798 2.55655 16.7036C4.28056 18.6092 6.67106 19.7793 9.23359 19.9719C11.7961 20.1644 14.3346 19.3646 16.324 17.738L22.293 23.707C22.4816 23.8892 22.7342 23.99 22.9964 23.9877C23.2586 23.9854 23.5094 23.8803 23.6948 23.6948C23.8803 23.5094 23.9854 23.2586 23.9877 22.9964C23.99 22.7342 23.8892 22.4816 23.707 22.293ZM10 18C8.41778 18 6.87106 17.5308 5.55546 16.6518C4.23987 15.7727 3.21449 14.5233 2.60899 13.0615C2.00349 11.5997 1.84506 9.99115 2.15374 8.4393C2.46243 6.88746 3.22435 5.46199 4.34317 4.34317C5.46199 3.22435 6.88746 2.46243 8.4393 2.15374C9.99115 1.84506 11.5997 2.00349 13.0615 2.60899C14.5233 3.21449 15.7727 4.23987 16.6518 5.55546C17.5308 6.87106 18 8.41778 18 10C17.9976 12.121 17.154 14.1545 15.6542 15.6542C14.1545 17.154 12.121 17.9976 10 18Z"
        fill="#ACABAB"
      />
    </svg>
  );
};

export default SearchIcon;
