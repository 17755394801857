import API from './APIs'

export const getExams = async (userId) => {
  try {
    const { data } = await API.get(`/users/${userId}/exams`)
    const promises = data?.map(async (exam) => {
      return getRemainingTimeService(userId, exam.Exam?.id)
    })
    const remTime = await Promise.all(promises)
    const transformed = data?.map((exam, index) => {
      return {
        id: exam.Exam.id,
        title: exam.Exam.title,
        status:
          exam.status.toLowerCase() === 'new'
            ? 'active'
            : !remTime[index]
            ? 'finished'
            : 'in progress',
        timeTotal: convertHMS(exam.Exam.duration),
        countedTime: exam.Exam.duration * 1000,
        timeRemain: remTime[index],
        img: exam.Exam.imageUrl,
      }
    })

    return transformed
  } catch (error) {
    throw error
  }
}

function convertHMS(seconds) {
  seconds = Number(seconds)
  let hours = Math.floor(seconds / 3600)
  seconds %= 3600
  let minutes = Math.floor(seconds / 60)
  seconds = seconds % 60
  return { timeInHours: hours, timeInMinutes: minutes, timeInSeconds: seconds }
}

export const startExam = (userId, examId) => {
  return API.post(`/users/${userId}/exams/${examId}/start`)
    .then(() => {
      return
    })
    .catch((err) => {
      return Promise.reject(err)
    })
}

export const getRemainingTimeService = (userId, examId) => {
  return API.get(`/users/${userId}/exams/${examId}/remaining-time`)
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      return Promise.reject(err)
    })
}

export const submitQuestion = (data) => {
  return API.post(
    `/users/${data.userId}/exams/${data.examId}/questions/${data.questionId}/answer`,
    {
      updatedBy: data.userId,
      examId: data.examId,
      questionId: data.q.id,
      description: data.q.description,
    },
  )
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      return Promise.reject(err)
    })
}

export const UpdateQuestionService = (data) => {
  return API.put(
    `/users/${data.userId}/exams/${data.examId}/questions/${data.questionId}/answer`,
    {
      updatedBy: data.userId,
      examId: data.examId,
      questionId: data.q.id,
      description: data.q.description,
    },
  )
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      return Promise.reject(err)
    })
}
export const EndExamService = (userId, examId) => {
  return API.put(`/users/${userId}/exams/${examId}/end`)
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      return Promise.reject(err)
    })
}
