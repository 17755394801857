import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  questions: {
    marginTop: "12px",
    borderRadius: "5px",
    width: "100%",
    minHeight: "136px",
    backgroundColor: theme.palette.background.main,
    display: "flex",
    justifyContent: " center",
    alignItems: "center",
  },
  leftAuto: {
    marginLeft: "auto",
  },
  createBtn: {
    width: "220px",
    textTransform: "unset",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    marginTop: "5px",
    borderRadius: "5px",
  },
  div: {
    margin: "27px 0",
  },
  container: {
    marginBottom: "25px",
    maxWidth: "729px",
    padding: "22px",
    background: theme.palette.white.main,
    borderRadius: "10px",
  },
}));

export default useStyles;
