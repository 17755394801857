import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    skeletonContainer: {
        display: 'flex',
        flexWrap:'wrap'
    },
    skeletonCard: {
        display: 'flex',
        flexDirection: 'column',
        padding: '10px',
        marginLeft: '10px'
    },
    duration: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    headSkeleton: {
        marginLeft: '75px',
        padding: '45px 63px 25px 55px',
    },
})

const SkeletonLoader = () => {
    const classes = useStyles();
    return (
        <>
            <Typography component="div" variant="h2">
                <Skeleton width={980} className={classes.headSkeleton} />
            </Typography>
            <Box className={classes.skeletonContainer}>
                {Array(8).fill(0).map((item, idx) => (
                    <Box className={classes.skeletonCard} key={idx}>
                        <Skeleton variant="rect" height={133} width={219} />
                        <Skeleton width={40} height={15} />
                        <Skeleton width={179} height={15} />
                        <Box className={classes.duration}>
                            <Skeleton width={100} height={30} />
                            <Skeleton width={100} height={30} />
                        </Box>
                        <Skeleton width={219} height={15} />
                    </Box>))}
            </Box>
        </>
    )
}

export default SkeletonLoader