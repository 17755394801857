import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  tableBodyContainer: {
    display: 'flex',
    padding: '24px 0px 25px 11.5px',
    borderBottom: '1px solid #E8E8E8',
    alignItems: 'center',
  },
  highlights: {
    display: 'flex',
    justifyContent: 'center',
    '& span': {
      width: '63px',
    },
  },
  subtext: {
    marginBottom: '31px',
    color: theme.palette.neutral.dark,
  },
  title: {
    marginTop: '0',
    marginBottom: '3px',
    color: theme.palette.neutral.main,
  },
  search: {
    width: '264px',
    '& input': {
      height: '38px',
      backgroundColor: 'transparent',
    },
    '& label': {
      position: 'absolute',
      right: '10px',
      top: '5px',
    },
  },
  container: {
    padding: '30px 22px',
    background: theme.palette.white.main,
    borderRadius: '10px 10px 0 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  createBtn: {
    width: '175px',
    textTransform: 'unset',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '5px',
    borderRadius: '10px',
  },
  activeSessions: {
    color: theme.palette.secondary.main,
  },
  examInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  examImg: {
    width: '80.95px',
    height: '53px',
    borderRadius: '5px',
    overflow: 'hidden',
  },
  examDetails: {
    marginLeft: '16px',
    '& h3': {
      marginTop: '0',
      marginBottom: '3px',
    },
  },
  filter: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '9px',
    '& * + *': {
      marginTop: '3.35px',
    },
  },
  tableHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '14px',
  },
  tableCell: {
    fontSize: '20px',
  },
  headerRow: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '13px',
    width: '100%',
  },
  tableContainer: {
    backgroundColor: theme.palette.white.main,
    borderRadius: '0 0 10px 10px',
  },
  tableBtnContainer: {
    display: 'flex',
    alignItems: 'center',
    '& a': {
      marginTop: '0',
      textDecoration: 'none',
    },
    '& div': {
      width: 'unset',
    },
    '& span': {
      color: theme.palette.info.main,
      fontWeight: 'bold',
    },
    '@media (min-width:600px)': {
      fontSize: '1.5rem',
    },
  },
  tableFooter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '0px',
    padding: '30px 20px 20px 20px',
  },
  footerBtn: {
    '& .MuiButton-outlinedSecondary.Mui-disabled': {
      border: 'none',
      marginRight: '0px',
    },
    '& .MuiButton-outlined.Mui-disabled': {
      border: 'none',
      marginRight: '0px',
    },
    '&,&:hover,&:active': {
      all: 'unset',
      display: 'flex',
      alignItems: 'center',
      marginRight: '0px',
    },
    '& span': {
      color: `${theme.palette.neutral.dark} !important`,
    },
    cursor: 'pointer',
  },
  btnContainer: {
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
  },
  arrowIcon: {
    fill: theme.palette.neutral.light,
    width: '7.68px',
    height: '5.03px',
    cursor: 'pointer',
    '&:focus, &:hover, &:active': {
      filter:
        'invert(43%) sepia(34%) saturate(4439%) hue-rotate(197deg) brightness(96%) contrast(96%)',
    },
  },
  tableHead: {
    display: 'flex',
    paddingLeft: '20px',
    width: '100%',
    borderBottom: '1px solid #E8E8E8',
  },
  linkIcon: {
    width: '7.29px',
    height: '13.41px',
    fill: theme.palette.info.main,
  },
  paginationIcon: {
    fill: theme.palette.neutral.light,
    width: '4.86px',
    height: '8.94px',
  },
  selected: {
    fill: theme.palette.primary.main,
  },
  pagination: {
    '& .MuiPaginationItem-page.Mui-selected': {
      backgroundColor: 'rgb(255 255 255 / 8%)',
      border: '1px solid #3E40B7',
      borderRadius: '6px',
      fontWeight: 'bold',
      fontSize: '14px',
      lineHeight: '150%',
      color: '#3E40B7',
    },
    padding: '0px 40px 0px 40px',
  },
  buttons: {
    lineHeight: '150%',
    fontWeight: 'normal',
    cursor: 'pointer',
  },
  numOfActiveSessions: {
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '150%',
  },
  tableBtn: {
    '& span': {
      textTransform: 'initial',
      fontSize: '1vw',
    },
  },examTitle:{
    width: "316px",
    wordBreak: "break-word",
  }
}))

export default useStyles
