import React, { Fragment } from "react";
import { v4 as uuid } from "uuid";

const List = ({ orderd, title, data }) => {
  const renderdList = orderd ? (
    <ol style={{ paddingLeft: "15px" }}>
      {data.map((item) => (
        <li style={{ fontWeight: "normal" }} key={uuid()}>
          {item}
        </li>
      ))}
    </ol>
  ) : (
    <ul style={{ paddingLeft: "15px" }}>
      {data.map((item) => (
        <li style={{ fontWeight: "normal" }} key={uuid()}>
          {item}
        </li>
      ))}
    </ul>
  );

  return (
    <Fragment>
      {title ? <h3>{title}</h3> : null}
      {renderdList}
    </Fragment>
  );
};

export default List;
