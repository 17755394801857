import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  star: {
    fill: "#E8E8E8",
  },
  fill: {
    fill: "#FD3D39",
  },
  bad: {
    fill: theme.palette.error.main,
  },
  good: {
    fill: theme.palette.warning.main,
  },
  excellent: {
    fill: theme.palette.secondary.main,
  },
}));

export default useStyles;
