import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: "259px",
    minHeight: "427px",
    padding: "1.25rem",
    borderRadius: "20px",
    boxShadow: theme.cardShadow,
    boxSizing: "border-box",
    margin: "1rem 0 0",
    marginRight: "0.875rem",
  },
  media: {
    width: "219px",
    height: "133px",
    borderRadius: "10px",
    margin: "0 auto",
  },
  content: {
    padding: "0",
    marginTop: "12px",
  },
  title: {
    margin: "8px 0px",
    width: "236px",
    minHeight: "91px",
    // width: "478px",
    wordBreak: "break-word",

  },
  time: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "1.1rem",
    width: "219px",
  },
}));

export default useStyles;