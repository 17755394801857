import React from "react";

const ArrowUpIcon = (props) => {
  return (
    <svg
      width="7.68px"
      height="5.03px"
      viewBox="0 0 14 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M12.293 8.207L6.99997 2.914L1.70697 8.207L0.292969 6.793L5.58597 1.5C5.96102 1.12506 6.46964 0.914429 6.99997 0.914429C7.5303 0.914429 8.03891 1.12506 8.41397 1.5L13.707 6.793L12.293 8.207Z" />
    </svg>
  );
};

export default ArrowUpIcon;
