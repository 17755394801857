import API from "./APIs";
import { mapToUserExam } from "../../helpers/mapper";
import moment from "moment";

// integrated but celebrate request validation failed
export const createExam = (examDetails) => {
  return API.post(`/exams`, examDetails)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const updateExam = (examId, examDetails) => {
  return API.put(`/exams/${examId}`, examDetails)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const addQuestion = (examId, questions) => {
  return API.post(`/exams/${examId}/questions`, questions)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const createUser = (userDetails) => {
  return API.post(`/users`, userDetails)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const deleteUser = (userId) => {
  return API.delete(`/users/${userId}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const assignUsers = (examId, users) => {
  return API.put(`/exams/${examId}/users`, { users })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const examUsers = (examId, filterData) => {
  return API.get(`/exams/${examId}/users?${mapToUserExam(filterData)}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const selectedUsers = (examId) => {
  return API.get(`/exams/${examId}/selected-users?`)
    .then(({ data }) => {
      return data?.selectedUsers?.map(({ User }) => ({
        id: User?.id,
        firstName: User?.first_name,
        lastName: User?.last_name,
        checked: true,
      }));
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const setScore = (userId, examId, questionId, score) => {
  return API.post(
    `/users/${userId}/exams/${examId}/questions/${questionId}/score`,
    { score }
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const getUsers = (
  filterData,
  role
) => {
  return API.get(
    `/users?${mapToUserExam(filterData)}role=${role}`
  )
    .then((res) => {
      const transformed = res?.data?.data?.map((item) => {
        return {
          name: item?.firstName,
          last: item?.lastName,
          account: item?.status,
          sessionStartDate: moment(item?.created_date).format(
            "DD MMM YYYY hh:mm A"
          ),
          score: null,
          id: item?.id,
          email: item?.email,
          createdBy: item?.createdBy,
          created_date: item?.created_date,
          phone: item?.phone,
          role: item?.role,
          updatedBy: item?.updatedBy,
          update_date: item?.update_date,
        };
      });
      return {
        transformed,
        items: res?.data?.items,
        pages: res?.data?.pages,
        currentPage: res?.data?.currentPage,
      };
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const getAdmins = (
  filterData,
  role
) => {
  return API.get(
    `/users?${mapToUserExam(filterData)}role=${role}`
  )
    .then((res) => {
      const transformed = res?.data?.data?.map((item) => {
        return {
          name: item?.firstName,
          last: item?.lastName,
          account: item?.status,
          sessionStartDate: moment(item?.created_date).format(
            "DD MMM YYYY hh:mm A"
          ),
          score: null,
          id: item?.id,
          email: item?.email,
          createdBy: item?.createdBy,
          created_date: item?.created_date,
          phone: item?.phone,
          role: item?.role,
          updatedBy: item?.updatedBy,
          update_date: item?.update_date,
        };
      });
      return {
        transformed,
        items: res?.data?.items,
        pages: res?.data?.pages,
        currentPage: res?.data?.currentPage,
      };
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const adminExams = () => {
  return API.get(`/exams`)
    .then((res) => {
      const transformed = res?.data?.map((item) => ({
        id: item?.id,
        name: item?.title, //name
        img: item?.imageUrl, //img
        desc: item?.description, //desc
        timeLimit: {
          days: "00",
          hours: "00",
          minutes: "00",
          seconds: "00",
        },
        createdBy: item?.createdBy,
        created_date: item?.created_date,
        duration: item?.duration,
        status: item?.status,
        updatedBy: item?.updatedBy,
      }));
      return transformed;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const updateUserExamScore = (examId, userId, totalScore) => {
  return API.put(`/exams/${examId}/users/${userId}/score`, { totalScore })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const fetchUserExamScore = (examId, userId) => {
  return API.get(`/exams/${examId}/users/${userId}/score`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};
