import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Box, Typography } from '@material-ui/core'
import ButtonSecondary from '../../Button/ButtonSecondary/ButtonSecondary'
import { UserIcon } from '../../Icons'
import useStyles from './styles'
import {
  fetchExamById,
  resetSelectedUsers,
  clearFilterbyDateRange,
} from '../../../store/actions'
import { useParams } from 'react-router-dom'

const EmptyExamSummary = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { id } = useParams()

  const openModal = props.openModal

  useEffect(() => {
    dispatch(resetSelectedUsers())
    dispatch(clearFilterbyDateRange())
  }, [])

  return (
    <>
      <Box className={classes.content}>
        <Box className={classes.emptyBox}>
          <Typography variant="h4">
            There are no participants for this exam
          </Typography>
          <ButtonSecondary
            startIcon={<UserIcon className={classes.userIcon} />}
            className={classes.createBtn}
            color="secondary"
            variant="contained"
            onClick={openModal}
          >
            Participants
          </ButtonSecondary>
        </Box>
      </Box>
    </>
  )
}

export default EmptyExamSummary
