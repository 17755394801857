import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    left: '50%',
    top: '40%',
    width: '500px',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '10px',
    outline: 'none',
    padding: '25px 39px 25px 39px',
    transform: 'translate(-50%,-50%)',
  },
  modalHeader: {
    textAlign: 'center',
    paddingBottom: '14px',
    marginBottom: '14px',
    borderBottom: '1px solid #E8E8E8',
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalSubHeader: {
    textAlign: 'center',
    textTransform: 'capitalize',
  },
  modalTime: {
    background: '#FFE3E2',
    padding: '6px',
    textAlign: 'center',
    borderRadius: '10px',
    height: '67px',
  },
  duration: {
    margin: '0',
  },
  note: {
    textAlign: 'center',
    padding: '14px 0',
    borderBottom: '1px solid #E8E8E8',
    '& p': {
      width: '219px',
      margin: '0 auto',
      color: '#C72F2C',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '18px',
    '& button:first-child': {
      width: '133px',
      textAlign: 'center',
      marginRight: '14px',
      justifyContent: 'center',
    },
    '& button:last-child': {
      width: '225px',
    },
  },
  icon: {
    margin: ' 0 12px',
  },

  contentTitle: {
    color: '#333333',
    marginBottom: '4px',
  },
  startButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: '10px',
  },
  startText: {
    flex: 8,
    display: 'flex',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#fff',
    textTransform: 'none',
  },
  startIcon: {
    flex: 1,
    height: '13.41px',
  },
  cancelBtn: {
    height: '54px',
    width: '138px',
    backgroundColor: '#E8E8E8 !important',
    border: '2px solid #E8E8E8 !important',
    boxSizing: 'border-box !important',
    borderRadius: '10px !important',
  },
  deleteBtn: {
    height: '54px',
    width: '138px',
    background: '#E03131 !important',
    border: '2px solid #E03131 !important',
    boxSizing: 'border-box !important !important',
    borderRadius: '10px !important !important',
    marginLeft: '8px !important',
  },
  mainText: {
    height: '61px',
    width: '220px',
    fontStyle: 'normal',
    fontWeight: '400 !important',
    fontSize: '15px !important',
    lineHeight: '23px !important',
    textAlign: 'center !important',
    color: ' #000000 !important',
  },
  headerText: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '17px',
    lineHeight: '20px',
    textAlign: 'center',
    color: '#181725',
  },
  mainBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: '#FFFFFF',
    boxShadow: 24,
    height: 237,
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',

    padding: '27px 33px',
  },
  btnContainer: {
    display: 'flex',
  },
  deleteText: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '17px',
    lineHeight: '22px',
    textAlign: 'center',
    letterSpacing: '-0.0043em',
    color: '#FFFFFF',
    textTransform: 'lowercase',
    '&:first-letter': {
      textTransform: 'uppercase',
    },
  },
  cancelText: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '17px',
    lineHeight: '22px',
    textAlign: 'center',
    letterSpacing: '-0.0043em',
    color: '#595959',
    textTransform: 'lowercase',
    '&:first-letter': {
      textTransform: 'uppercase',
    },
  },
  goodLuck: {
    fontWeight: 'bold',
    background: 'red',
  },
  instructions: {
    margin: '0px',
    overflowY: 'scroll',
    borderBottom: '1px solid #E8E8E8',
    '& p': {
      margin: '0 auto',
    },
  },
  listInstructions: {
    margin: '5px 0px',
  },
  as: { fontSize: '13px' },
}));

export default useStyles;
