import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import ParticipantsModal from "../ParticipantsModal/ParticipantsModal";
import { useParams, withRouter } from "react-router-dom";
import Input from "../../Input/Input";
import TextHighlight from "../../TextHighlight/TextHighlight";
import Link from "../../Link/Link";
import { tableHeadExamSummary } from "../../../helpers/objects";
import { ArrowRightIcon, SearchIcon, MenuDotsIcon } from "../../Icons";

import { Box, TextField, Typography } from "@material-ui/core";

import moment from "moment";
import useStyles from "./styles";
import HeadTable from "../../Table/HeadTable/HeadTable";
import FooterTable from "../../Table/FooterTable/FooterTable";
import {
  fetchExamQuestions,
  setFilteredData,
  setFiltered,
  setColNum,
  setDateRange,
} from "../../../store/actions";
import debounce from "lodash.debounce";
import ExamSummaryFiltered from "../../ExamSummaryFiltered/ExamSummaryFiltered";

const ExamSummary = ({ userExamList, orderByDateRangehandle, examUsers }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const onOpen = () => setOpen(!open);
  const { id } = useParams();
  const { columnNum, dateRange, filterData, filter } = useSelector(
    ({ exams }) => ({
      columnNum: exams?.columnNum,
      dateRange: exams?.dateRange,
      filterByDate: exams?.filterByDate,
      filterData: exams?.filterData,
      filter: exams?.filter,
    })
  );
  useEffect(() => {
    dispatch(fetchExamQuestions(id));
  }, [id]);

  useEffect(() => {
    columnNum === 1
      ? dispatch(setFilteredData("columnName", "name"))
      : columnNum === 2
      ? dispatch(setFilteredData("columnName", "schedule_date"))
      : columnNum === 3
      ? dispatch(setFilteredData("columnName", "status"))
      : columnNum === 4
      ? dispatch(setFilteredData("columnName", "score"))
      : dispatch(setFilteredData("columnName", "score"));
  }, [columnNum]);

  const handleChange = (event) => {
    dispatch(setFilteredData(event.target.name, event.target.value));
    dispatch(setFilteredData("page", 1));
    dispatch(setFiltered(true));
  };
  const dateRangehandle = (event) => {
    dispatch(setDateRange(event.target.name, event.target.value));
  };

  const dateFormatHandler = (date) => {
    return moment(date).format("DD MMM YYYY hh:mm A");
  };
  const searchChangeHandler = (e) => {
    dispatch(setFilteredData("searchTerm", e.target.value));
    dispatch(setFilteredData("page", 1));
    dispatch(setFiltered(true));
  };

  const debouncedChangeHandler = useCallback(
    debounce(searchChangeHandler, 500),
    []
  );
  const pageChangeHandler = (event, page) => {
    dispatch(setFilteredData("page", page));
  };

  const sortHandler = (order, id) => {
    dispatch(setFilteredData("order", order));
    dispatch(setColNum(id));
  };

  return (
    <>
      <Box className={classes.filterContainer}>
        <Typography variant="h4">
          Participants ({userExamList?.result?.items})
        </Typography>
        <Box className={classes.inputContainer}>
          <Box className={classes.search}>
            <Input
              id="search"
              labeltxt={<SearchIcon />}
              placeholderTxt="Search"
              onChange={debouncedChangeHandler}
            />
          </Box>
          <Box className={classes.selectFilter}>
            <TextField
              id="date1"
              type="date"
              name="first_schedule_date"
              sx={{ width: 220 }}
              variant="outlined"
              className={classes.date}
              onChange={dateRangehandle}
              defaultValue="Date range"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
          <Box className={classes.selectFilter}>
            <TextField
              id="date2"
              type="date"
              name="last_schedule_date"
              sx={{ width: 220 }}
              variant="outlined"
              className={classes.date}
              onChange={dateRangehandle}
              inputProps={{
                min: dateRange?.first_schedule_date,
              }}
            />
          </Box>
          <Box className={classes.selectFilter}>
            <select
              className={classes.selectInput}
              name="status"
              onChange={handleChange}
              defaultValue="Status"
            >
              <option value="-1">Status</option>
              <option value="Finished">Finished</option>
              <option value="Waiting">Waiting assesment</option>
              <option value="New">DNS</option>
              <option value="Started">Started</option>
            </select>
          </Box>
          <Box className={classes.selectFilter}>
            <select
              placeholder="score"
              className={classes.selectInput}
              name="score"
              onChange={handleChange}
              defaultValue="Score"
              disabled={filterData?.status == "Waiting"}
            >
              <option value="-1">Score</option>
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select>
          </Box>
        </Box>
      </Box>
      {userExamList?.result?.items > 0 ? (
        <Box className={classes.tableContainer}>
          <Box className={classes.table} aria-label="simple table">
            <HeadTable
              classes={classes}
              headCell={tableHeadExamSummary}
              sortHandler={sortHandler}
            />
            <Box>
              {userExamList?.result &&
                userExamList?.result?.data?.map((participant) => (
                  <Box
                    key={participant.id}
                    className={classes.tableBodyContainer}
                  >
                    <Box
                      className={classes.tableCell}
                      style={{ flex: "3", justifyContent: "flex-start" }}
                    >
                      <Box className={classes.examInfo}>
                        <Box className={classes.examDetails}>
                          {`${participant?.User?.first_name} ${participant?.User?.last_name}`}
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      className={classes.tableCell}
                      style={{ flex: "3", justifyContent: "flex-start" }}
                    >
                      {dateFormatHandler(participant.scheduleDate)}
                    </Box>
                    <Box
                      className={classes.tableCell}
                      style={{ flex: "3", justifyContent: "flex-start" }}
                    >
                      <Box className={classes.highlights}>
                        {participant.status === "New" ? (
                          <Box>DNS</Box>
                        ) : participant.status === "Finished" &&
                          participant.score === null ? (
                          "Waiting Assessment"
                        ) : (
                          participant.status
                        )}
                      </Box>
                    </Box>
                    <Box
                      className={classes.highlights}
                      style={{ flex: "1", justifyContent: "center" }}
                    >
                      <Box className={classes.tableBtnContainer}>
                        {participant.score === null ? (
                          participant.score
                        ) : participant.score === 3 ? (
                          <TextHighlight textColor="#BE6D23" color="inProgress">
                            {participant.score}
                          </TextHighlight>
                        ) : participant.score > 3 ? (
                          <TextHighlight textColor="#008748" color="active">
                            {participant.score}
                          </TextHighlight>
                        ) : participant.score <= 2 ? (
                          <TextHighlight textColor="#C72F2C" color="finished">
                            {participant.score}
                          </TextHighlight>
                        ) : null}
                      </Box>
                    </Box>
                    {participant.status === "Finished" ? (
                      <>
                        <Box style={{ flex: "1.7", justifyContent: "center" }}>
                          <Box className={classes.tableBtnContainer}>
                            <Link
                              to={`/admin/exam/${id}/${participant.id}/${participant?.User?.id}`}
                              className={classes.tableBtn}
                              text="View answers"
                            />
                            <ArrowRightIcon className={classes.linkIcon} />
                          </Box>
                        </Box>
                        <Box
                          style={{ flex: "0.5", justifyContent: "flex-end" }}
                        >
                          <MenuDotsIcon />
                        </Box>
                      </>
                    ) : (
                      <>
                        <Box
                          style={{ flex: "1.7", justifyContent: "center" }}
                        />
                        <Box
                          style={{ flex: "0.5", justifyContent: "flex-end" }}
                        />
                      </>
                    )}
                  </Box>
                ))}
            </Box>
          </Box>

          <FooterTable
            data={userExamList?.result?.data}
            pages={userExamList?.result?.pages}
            classes={classes}
            page={userExamList?.result?.currentPage}
            pageChangeHandler={pageChangeHandler}
            length={userExamList?.result?.items}
          />
        </Box>
      ) : (
        <ExamSummaryFiltered />
      )}
      {open ? <ParticipantsModal handleClose={onOpen} open={open} /> : null}
    </>
  );
};

export default withRouter(ExamSummary);
