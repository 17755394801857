import React from 'react';
import { Box } from '@material-ui/core';
import Sidebar from '../Sidebar/Sidebar';
import useStyles from './styles';

function Home({ contained, children }) {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Sidebar contained={contained} />
      <Box className={classes.contentInner}>{children}</Box>
    </Box>
  );
}

export default Home;
