import React from "react";

const Figure = ({ title, img, altTexet, ...props }) => {
  return (
    <div>
      <h4>{title}</h4>
      <img src={img} alt={altTexet} {...props} />
    </div>
  );
};

Figure.defaultProps = {
  alt: " ",
};

export default Figure;
