import {
  LOADING_LOGIN,
  LOGGEDIN_SUCCESS,
  LOGGEDIN_FAILED,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
  SEND_EMAIL,
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_FAILED,
  LOGOUT,
  SET_ROLE,
} from '../types/types'
import {
  LoginService,
  ResetPasswordService,
  sendEmailService,
} from '../services'
import jwt_decode from 'jwt-decode'
import { snackbarAction } from '.'

export const userFromToken = () => {
  const user = jwt_decode(sessionStorage.getItem('accessToken'))
  return user
}

export const checkAuthDispatch = (emailAndPassword, history) => async (
  dispatch,
) => {
  try {
    dispatch({
      type: LOADING_LOGIN,
    })
    const data = await LoginService(emailAndPassword)
    sessionStorage.setItem('accessToken', data?.accessToken)
    const role = userFromToken()?.role?.toLowerCase()
    const firstName = userFromToken()?.firstName?.toLowerCase()
    const lastName = userFromToken()?.lastName?.toLowerCase()
    dispatch({
      type: LOGGEDIN_SUCCESS,
      role: role,
    })
    // sessionStorage.setItem('role', role)
    sessionStorage.setItem('firstName', firstName)
    sessionStorage.setItem('lastName', lastName)
    history.replace('/')
    dispatch(snackbarAction(true, 'success', 'Login success'))
  } catch (err) {
    dispatch(snackbarAction(true, 'error', 'Login failed'))
    dispatch({
      type: LOGGEDIN_FAILED,
      payload: err,
    })
  }
}

export const sendEmailAction = (email) => async (dispatch) => {
  dispatch({
    type: SEND_EMAIL,
  })
  try {
    await sendEmailService(email)
    dispatch({
      type: SEND_EMAIL_SUCCESS,
    })
  } catch (err) {
    dispatch({
      type: SEND_EMAIL_FAILED,
      payload: err,
    })
  }
}

export const resetPasswordAction = (obj) => async (dispatch) => {
  dispatch({
    type: RESET_PASSWORD,
  })
  try {
    await ResetPasswordService(obj)
    dispatch({
      type: RESET_PASSWORD_SUCCESS,
    })
  } catch (err) {
    dispatch({
      type: RESET_PASSWORD_FAILED,
      payload: err,
    })
  }
}

export const logoutDispatch = (history) => (dispatch) => {
  history.push('/login')
  sessionStorage.removeItem('accessToken')
  sessionStorage.removeItem('firstName')
  sessionStorage.removeItem('lastName')
  dispatch(setRole(''))
  dispatch({
    type: LOGOUT,
  })
}

export const setRole = (role) => (dispatch) => {
  dispatch({ type: SET_ROLE, value: role })
}
