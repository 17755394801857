import React from 'react'
import useStyles from './styles'
import { Box, Typography } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import {
  endUserExam,
  userFromToken,
  setTimeState,
} from '../../../store/actions'
import { UPDATE_STATUS } from '../../../store/types/types'
import Timer from 'react-compound-timer'

export default ({ countedTime, id }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const endTimeHandler = () => {
    dispatch(setTimeState('finished'))
    dispatch({
      type: UPDATE_STATUS,
      value: { id: id, status: 'finished' },
    })
    dispatch(endUserExam(userFromToken().id, id))
  }
  return (
    <Timer
      initialTime={countedTime}
      direction="backward"
      lastUnit='h'
      formatValue={(value) => `${value < 10 ? `0${value}` : value} `}
      checkpoints={[
        {
          time: 0,
          callback: endTimeHandler,
        },
      ]}
    >
      {() => {
        return (
          <Box className={classes.counterContainer}>
            <Typography className={classes.TimerCaption} variant="caption">
              Time remaining
            </Typography>
            <Box className={classes.timer} variant="caption">
              <Box className={classes.timeCell}>
                <Typography className={classes.timeDigit} variant="h1">
                  <Timer.Hours />
                </Typography>
                <Typography className={classes.subText} variant="subtitle2">
                  hours
                </Typography>
              </Box>
              <Typography className={classes.seperator} variant="h3">
                :
              </Typography>
              <Box className={classes.timeCell}>
                <Typography className={classes.timeDigit} variant="h1">
                  <Timer.Minutes />
                </Typography>
                <Typography className={classes.subText} variant="subtitle2">
                  minutes
                </Typography>
              </Box>
              <Typography className={classes.seperator} variant="h3">
                :
              </Typography>
              <Box className={classes.timeCell}>
                <Typography className={classes.timeDigit} variant="h1">
                  <Timer.Seconds />
                </Typography>
                <Typography className={classes.subText} variant="subtitle2">
                  seconds
                </Typography>
              </Box>
            </Box>
          </Box>
        )
      }}
    </Timer>
  )
}
