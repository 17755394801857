import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    skeletonContainer: {
        display: 'grid',
        flexWrap: 'wrap'
    },
    skeletonCard: {
        display: 'flex',
        flexDirection: 'row',
        gap: '10px',
        padding: '10px',
        marginLeft: '10px'
    },
    duration: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    headSkeleton: {
        marginLeft: '15px',
        padding: '45px 63px 25px 0px',
    },
    search:{
        marginLeft: '15px',
    },
    status:{
        marginLeft: '75px',
    },
    assessed:{
        marginLeft: '75px',
    },
    button:{
        marginLeft: '75px',
    }
})

const AdminBoardSkeleton = () => {
    const classes = useStyles();
    return (
        <>
            <Typography component="div" variant="h2">
                <Skeleton width={900} className={classes.headSkeleton} />
            </Typography>
                <Skeleton animation="wave" width={300} height={55} className={classes.search}/>
            <Box className={classes.skeletonContainer}>
                {Array(8).fill(0).map((item, idx) => (
                    <Box className={classes.skeletonCard} key={idx}>
                        <Skeleton variant="rect" width={81} height={55} />
                        <Box>
                            <Skeleton animation="wave" width={300} />
                            <Skeleton animation="wave" width={300} />
                        </Box>
                            <Skeleton animation="wave" width={50} className={classes.status}/>
                            <Skeleton animation="wave" width={50} className={classes.assessed}/>

                            <Skeleton animation="wave" width={100} className={classes.button}/>
                    </Box>))}
            </Box>
        </>
    )
}

export default AdminBoardSkeleton