import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { GridViewIcon, ListViewIcon } from '../Icons';
import GridView from '../GridView/GridView';
import EmptyBoard from '../EmptyBoard/EmptyBoard';
import Modal from '../Modal/Modal';
import { Grid, Typography, IconButton, Box } from '@material-ui/core';
import useStyles from './styles';
import useModalStyles from '../Modal/styles';

import {
  fetchUserExams,
  userFromToken,
  setListGridView,
} from '../../store/actions';
import RenderdExam from './RenderdExam.jsx';

import whiteArrowIcon from '../../assets/whiteArrowIcon.svg';
import blueArrowIcon from '../../assets/blueArrowIcon.svg';
import ExamDuration from '../ExamsDuration/ExamDuration';
const ExamBoard = () => {
  const userId = userFromToken().id;
  const classes = useStyles();
  const modalClasses = useModalStyles();

  const dispatch = useDispatch();

  const { examList, loading, gridView } = useSelector(({ examsUser }) => ({
    examList: examsUser.exams,
    loading: examsUser.loading,
    gridView: examsUser.view,
  }));

  useEffect(() => {
    dispatch(fetchUserExams(userId));
  }, []);
  const [open, setOpen] = useState(false);
  const [openModalInstruction, setModalInstruction] = useState(false);

  const [modalConfig, setModalConfig] = useState({
    title: 'Please Read carefully !',
    subTitle: 'title',
    contentTitle: 'Exam duration',
    contentText: '00',
    note: 'Your time will start running once you click on start now',
    onAccept: () => {},
    onClose: () => setOpen(false),
    onAcceptBtnTxt: 'Start now',
    onCancelBtnTxt: 'cancel',
  });
  const ModalInstruction = {
    title: 'Please Read carefully !',

    note: [
      'Assignment is meant to be completed by you (the applying candidate) without assistance from other people, should any plagiarism be identified, you will be disqualified immediately .',
      'Usage of google, documentation, books, tutorials...etc. is allowed .',
      'Concepts and solutions implemented as answers should be well understood, and will be asked about in follow up interviews .',
      'Once completed, submit the answers as:',
    ],
    as: [
      'Binaries and non-code (Build, temp...etc.) folders should be removed from the final submitted zip file to keep size small .',
      'Code should be compilable and have no errors .',
      'File should be named as “FirstName-LastName-PHP-Date.zip” ( e.g. “Ahmad-Mohammad-PHP-Nov-10-2021.zip”) .',
      'Reply back to the recruitment email with solution attached as zip file .',
    ],
    onAccept: () => {
      setModalInstruction(false);
      setOpen(true);
    },
    onClose: () => {
      setModalInstruction(!openModalInstruction);
    },
    onAcceptBtnTxt: 'Next',
    onCancelBtnTxt: 'cancel',
  };
  const handleModal = () => {
    setOpen(!open);
  };
  const changeViewHandler = (view) => {
    dispatch(setListGridView(view));
  };
  const renderdExams = examList?.map((exam) => (
    <RenderdExam
      key={exam?.id}
      examKey={exam?.id}
      exam={exam}
      gridView={gridView}
      modalConfig={modalConfig}
      handleModal={ModalInstruction.onClose}
      setModalConfig={setModalConfig}
      loading={loading}
    />
  ));

  return (
    <>
      {!examList?.length && !loading ? (
        <EmptyBoard />
      ) : (
        <>
          <Grid className={classes.content} container>
            <Grid className={classes.header} xs={10} item>
              <Typography variant="h1">Exams</Typography>
            </Grid>

            <Grid
              className={classes.iconContainer}
              container
              justifyContent="flex-end"
              xs={2}
              item
            >
              <IconButton
                className={gridView === 'list' ? classes.disabled : ''}
                onClick={() => changeViewHandler('grid')}
                color="primary"
              >
                <GridViewIcon />
              </IconButton>
              <IconButton
                className={gridView === 'grid' ? classes.disabled : ''}
                onClick={() => changeViewHandler('list')}
                color="primary"
              >
                <ListViewIcon />
              </IconButton>
            </Grid>
          </Grid>
          <GridView>{renderdExams}</GridView>
        </>
      )}
      <Modal
        open={open}
        handleClose={handleModal}
        handleOpen={handleModal}
        modalConfig={modalConfig}
      >
        <Typography className={modalClasses.modalSubHeader} variant="h4">
          {modalConfig.subTitle}
        </Typography>
        <Box className={modalClasses.modalTime}>
          <Typography variant="body1" className={modalClasses.contentTitle}>
            {modalConfig.contentTitle}
          </Typography>
          <Typography className={modalClasses.duration} variant="h3">
            {modalConfig.contentText}
          </Typography>
        </Box>
        <Box className={modalClasses.note}>
          <Typography variant="body1">{modalConfig.note}</Typography>
          <p className="goodLuck">Good Luck !</p>
        </Box>
      </Modal>
      <Modal
        open={openModalInstruction}
        handleClose={ModalInstruction.onClose}
        handleOpen={ModalInstruction.onAccept}
        modalConfig={ModalInstruction}
      >
        <p className={modalClasses.instructions}>
          General Notes and Rules:
          {ModalInstruction?.note?.map((item) => {
            return <li className={modalClasses.listInstructions}>{item}</li>;
          })}
          {ModalInstruction.as.map((item) => {
            return <p className={modalClasses.as}>- {item}</p>;
          })}
        </p>
      </Modal>
    </>
  );
};

export default withRouter(ExamBoard);
