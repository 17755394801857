import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Snackbar } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import { snackbarAction } from '../../store/actions'
import useStyles from './styles'

const CustomizedSnackBar = () => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const { snackbarOpen, snackbarType, snackbarMessage } = useSelector(
    ({ examDetail }) => ({
      snackbarOpen: examDetail.snackbarOpen,
      snackbarType: examDetail.snackbarType,
      snackbarMessage: examDetail.snackbarMessage,
    }),
  )

  const closeHandle = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    dispatch(snackbarAction(false, snackbarType, snackbarMessage))
  }

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  })

  return (
    <div className={classes.snackBar}>
      <Snackbar
        open={snackbarOpen}
        onClose={closeHandle}
        autoHideDuration={3000}
        color={snackbarType}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={closeHandle}
          severity={snackbarType}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default CustomizedSnackBar
