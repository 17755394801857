import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  counterContainer: {
    backgroundColor: theme.palette.primary.lighter,
    padding: "16px 10px",
    marginBottom: "21px",
    borderRadius: "10px",
    width: "267px",
    minHeight: "95px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  counter: {},
  TimerCaption: {
    color: theme.palette.primary.main,
    display: "block",
  },
  timer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    width: "136px",
    height: "44px",
    margin: "1px 0",
  },
  timeCell: {
    color: theme.palette.primary,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "& h1": {
      margin: 0,
    },
  },
  seperator: {
    alignSelf: "flex-start",
    margin: 0,
    color: theme.palette.primary.main,
  },
  timeDigit: {
    color: theme.palette.primary.main,
  },
  subText: {
    color: theme.palette.primary.main,
    marginTop: "-5px",
  },
  timerCaption: {
    marginBottom: "1px",
  },
}));

export default useStyles;
