import React from 'react'
import { ArrowUpIcon, ArrowDownIcon } from '../../Icons'
import { Box, Typography } from '@material-ui/core'
const HeadTable = ({ headCell, classes, sortHandler }) => {
  return (
    <Box className={classes.tableHead} component="div">
      {headCell.map((cell) => {
        return (
          <Box
            component="div"
            key={cell.id}
            className={classes.headerRow}
            style={{ flex: cell.width, justifyContent: cell.align }}
          >
            <Box className={classes.tableHeader}>
              <Box className={classes.filter}>
                {cell.label !== '' ? (
                  <>
                    <ArrowUpIcon
                      onClick={() => sortHandler('ASC', cell.id)}
                      className={classes.arrowIcon}
                    />{' '}
                    <ArrowDownIcon
                      className={classes.arrowIcon}
                      onClick={() => sortHandler('DESC', cell.id)}
                    />
                  </>
                ) : null}
              </Box>
              <Typography variant="subtitle1" className={classes.headLabel}>
                {cell.label}
              </Typography>
            </Box>
          </Box>
        )
      })}
    </Box>
  )
}
export default HeadTable
