import {
  FETCH_EXAM_BY_ID,
  FETCH_EXAM_BY_ID_SUCCESS,
  FETCH_EXAM_BY_ID_FAIL,
  FETCH_EXAM_ANSWERS,
  FETCH_EXAM_ANSWERS_SUCCESS,
  FETCH_EXAM_ANSWERS_FAILURE,
  FETCH_EXAM_QUESTIONS,
  FETCH_EXAM_QUESTIONS_SUCCESS,
  FETCH_EXAM_QUESTIONS_FAILURE,
  FETCH_USER_INFORMATION,
  FETCH_USER_INFORMATION_SUCCESS,
  FETCH_USER_INFORMATION_FAILURE,
  UPDATE_USER_INFORMATION,
  UPDATE_USER_INFORMATION_SUCCESS,
  UPDATE_USER_INFORMATION_FAILURE,
  FETCH_ALL_EXAM_USERS,
  FETCH_ALL_EXAM_USERS_SUCCESS,
  FETCH_ALL_EXAM_USERS_FAILURE,
  SET_TIME_COMPLETED,
  // SET_LOADING_BOOL,
  UPDATE_USER_INPUT_FIELD,
  CLEAR_USER_INFO,
  SHOW_SNACK_BAR,
  UPDATE_EXAM_INPUT_FIELD,
  UPDATE_EXAM_QUESTION_FIELD,
  UPDATE_EXAM_DURATION_FIELD,
  CLEAR_EXAM_INFO,
} from '../types'

import {
  getUser,
  updateUser,
  getExam,
  getAnswers,
  getQuestions,
  getExamUsers,
} from '../services'
// import { UPDATE_TIME } from '../types/types'
import { setTimeState } from '../actions'
import { fetchUsers } from './AdminActions'
import jwt_decode from 'jwt-decode'

export const getUserInformation = (id) => (dispatch) => {
  dispatch({
    type: FETCH_USER_INFORMATION,
  })
  getUser(id)
    .then((data) => {
      dispatch({
        type: FETCH_USER_INFORMATION_SUCCESS,
        value: data,
      })
    })
    .catch((err) => {
      dispatch({
        type: FETCH_USER_INFORMATION_FAILURE,
        err: err,
      })
    })
}
export const updateUserInfoFieldAction = (name, value) => async (dispatch) => {
  dispatch({ type: UPDATE_USER_INPUT_FIELD, value: { name, value } })
}

export const updateExamInfoFieldAction = (name, value) => async (dispatch) => {
  dispatch({ type: UPDATE_EXAM_INPUT_FIELD, value: { name, value } })
}

export const updateExamDurationAction = (name, value) => async (dispatch) => {
  dispatch({ type: UPDATE_EXAM_DURATION_FIELD, value: { name, value } })
}

export const updateExamQuestionAction = (question) => async (dispatch) => {
  dispatch({ type: UPDATE_EXAM_QUESTION_FIELD, value: question })
}

export const clearExamInfo = () => async (dispatch) => {
  dispatch({ type: CLEAR_EXAM_INFO })
}

export const updateUserInformation = (
  userId,
  userDetails,
  page,
  size,
  role,
  history,
) => (dispatch) => {
  const token = sessionStorage?.getItem('accessToken')
  const tokenInfo = token && jwt_decode(token)
  dispatch({
    type: UPDATE_USER_INFORMATION,
  })
  updateUser(userId, userDetails)
    .then((data) => {
      dispatch({
        type: UPDATE_USER_INFORMATION_SUCCESS,
        value: data,
      })
      dispatch(fetchUsers({page, size}, role))
      if(role==="Admin" && userId===tokenInfo.id){
        sessionStorage.setItem('firstName',userDetails.firstName) 
        sessionStorage.setItem('lastName',userDetails.lastName) 
      }
      history&& history.goBack()
      history&&  dispatch(snackbarAction(true, 'success', 'Updated successfully'))
    })
    .catch((err) => {
      dispatch({
        type: UPDATE_USER_INFORMATION_FAILURE,
        err: err,
      })
      dispatch(snackbarAction(true, 'error', 'Updated failed'))
    })
}

export const clearUserInfo = () => (dispatch) => {
  dispatch({ type: CLEAR_USER_INFO })
}

export const fetchExamById = (id,isPreviewRoute) => (dispatch) => {
  dispatch({
    type: FETCH_EXAM_BY_ID,
  })
  dispatch(setTimeState(''))
  getExam(id,isPreviewRoute)
    .then((data) => {
      dispatch({
        type: FETCH_EXAM_BY_ID_SUCCESS,
        value: data,
      })
    })
    .catch((err) => {
      dispatch({
        type: FETCH_EXAM_BY_ID_FAIL,
        err: err,
      })
    })
}

export const fetchExamAnswers = (userId, examId, userExamId, history) => (
  dispatch,
) => {
  dispatch({
    type: FETCH_EXAM_ANSWERS,
  })
  getAnswers(userId, examId)
    .then((data) => {
      dispatch({
        type: FETCH_EXAM_ANSWERS_SUCCESS,
        value: data,
      })
    })
    .catch((err) => {
      dispatch({
        type: FETCH_EXAM_ANSWERS_FAILURE,
        err: err,
      })
    })
}

export const fetchExamQuestions = (id) => (dispatch) => {
  dispatch({
    type: FETCH_EXAM_QUESTIONS,
  })
  getQuestions(id)
    .then((data) => {
      dispatch({
        type: FETCH_EXAM_QUESTIONS_SUCCESS,
        value: data.Questions,
      })
    })
    .catch((err) => {
      dispatch({
        type: FETCH_EXAM_QUESTIONS_FAILURE,
        err: err,
      })
    })
}

export const fetchAllExamUsers = (filterData) => (dispatch) => {
  dispatch({
    type: FETCH_ALL_EXAM_USERS,
  })
  getExamUsers(filterData)
    .then((data) => {
      dispatch({
        type: FETCH_ALL_EXAM_USERS_SUCCESS,
        value: data,
      })
    })
    .catch((err) => {
      dispatch({
        type: FETCH_ALL_EXAM_USERS_FAILURE,
        err: err,
      })
    })
}

export const setTimeCompletedAction = (value) => (dispatch) => {
  dispatch({ type: SET_TIME_COMPLETED, value })
}

export const snackbarAction = (
  snackbarOpen,
  snackbarType,
  snackbarMessage,
) => async (dispatch) => {
  dispatch({
    type: SHOW_SNACK_BAR,
    snackbarOpen,
    snackbarType,
    snackbarMessage,
  })
}
