import React, { useState, useEffect } from 'react'
import { StarIcon } from '../../Icons'
import useStyles from './styles'
// import { fetchExamAnswers } from '../../../store/actions'
// import { useDispatch } from 'react-redux'

const StarRating = ({ score, saveRating, status, examId, participantId }) => {
  const [hoverRate, setHoverRate] = useState()
  // const dispatch = useDispatch()

  useEffect(() => {
    setHoverRate(score)
    // dispatch(fetchExamAnswers(participantId, examId))
    // let interval = setInterval(() => {}, 1000)
  }, [score])

  const rateStyle = {
    notAssessed: 'notAssessed',
    bad: 'bad',
    good: 'good',
    excellent: 'excellent',
  }
  const style =
    hoverRate <= 2 && hoverRate !== null
      ? rateStyle.bad
      : hoverRate === 3
      ? rateStyle.good
      : hoverRate > 3
      ? rateStyle.excellent
      : rateStyle.notAssessed

  const classes = useStyles()

  const renderdStars = [...Array(5)].map((item, index) => {
    return index + 1 <= hoverRate ? (
      <StarIcon
        key={index}
        className={[classes.star, classes[style]].join(' ')}
        value={index + 1}
        onMouseEnter={() => {
          status && setHoverRate(index + 1)
        }}
        onMouseLeave={() => {
          status && setHoverRate(score)
        }}
        onClick={() => {
          saveRating(hoverRate)
        }}
      />
    ) : (
      <StarIcon
        key={index}
        className={classes.star}
        value={index + 1}
        onMouseEnter={() => {
          status && setHoverRate(index + 1)
        }}
        onMouseLeave={() => {
          status && setHoverRate(score)
        }}
        onClick={() => {
          saveRating(hoverRate)
        }}
      />
    )
  })
  return <>{renderdStars}</>
}

export default StarRating
