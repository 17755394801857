import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  answerBox: {
    '& p': {
      paddingTop: '1rem',
    },
  },
  submitBtn: {
    width: '264px',
    textTansform: 'capitalize',
    textTransform: 'none',
    borderRadius: '10px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#fff',
    '&.MuiTypography-body2': {
      fontWeight: 400,
    },
    '&:hover': {
      backgroundColor: '#008748',
    },
    '&:disabled': {
      color: '#ACABAB',
      background: '#E8E8E8',
    },
  },
  editButton: {
    width: '264px',
    textTansform: 'capitalize',
    textTransform: 'none',
    borderRadius: '10px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#3E40B7',
    '&:hover': {
      backgroundColor: '#E6E6FF',
    },
    '&:disabled': {
      '& span': {
        color: '#ACABAB',
      },
      background: '#E8E8E8',
      border: 'none',
    },
  },
  textarea: {
    fontFamily: 'Roboto, sans serif',
    width: '100%',
    borderRadius: '10px',
    minWidth: '200px',
    minHeight: '198px',
    border: `1px solid ${theme.palette.border}`,
    padding: '1em',
    outlineColor: theme.palette.primary.main,
    resize: 'none',
    marginBottom: '1em',
    '&:disabled': {
      backgroundColor: theme.palette.border,
      color: theme.palette.text.secondary,
    },
  },
  label: {
    marginBottom: '15px',
    marginTop: '35px',
  },
  hide: {
    display: 'none',
  },
  emptyAnswer: {
    fontFamily: 'Roboto, sans serif',
    width: '100%',
    borderRadius: '10px',
    minWidth: '200px',
    minHeight: '198px',
    border: `2px solid red`,
    padding: '1em',
    outlineColor: 'red',
    resize: 'none',
    marginBottom: '1em',
  },
  emptyText: {
    color: 'red',
  },
  answerType: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#3E40B7',
    marginBottom: '0.5em',
  },
  examPhotoLinkButton: {
    boxSizing: 'border-box',
    width: '100%',
    // height: "50px",
    // margin: "20.5px 0px 20.5px 0px",
    display: 'flex',
    flexDirection: 'row',
  },
  examPhotoLinkBox: {
    flex: 3,
    borderBottom: '1px solid #E8E8E8',
    marginBottom: '19px',
    display: 'flex',
    flexDirection: 'row',
  },
  finished: {
    '& p': {
      paddingTop: '0px',
    },
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: '1px solid #E8E8E8',
    borderRadius: '5px',
    height: '37px',
    marginBottom: '16px',
    backgroundColor: theme.palette.border,
    // flex: 3,
    // height: "50px",
    // border: "solid",
    // borderWidth: "1px",
    // borderColor: "#E8E8E8",
    // borderRadius: "5px 0px 0px 5px",
    // display: "flex",
    // flexDirection: "row",
    // backgroundColor: theme.palette.border,
  },
  finishedText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '0em',
    color: '#ACABAB',
    margin: '1px 0px 0px 8.2px',
  },
  wrongFileType: {
    flex: 3,
    height: '50px',
    border: 'solid',
    borderWidth: '1px',
    borderColor: 'red',
    borderRight: 'none',
    borderRadius: '5px 0px 0px 5px',
    display: 'flex',
    flexDirection: 'row',
  },
  examPhotoLink: {
    display: 'flex',
    flexDirection: 'row',
    margin: '0px 0px 20.5px 0px',
    // marginTop: "14.5px",
    // marginLeft: "3.3%",
    flex: 5,
    '&:hover': {
      textDecoration: 'none',
    },
  },
  attach: {
    height: '23.96px',
    // paddingBottom: "6px"
  },
  clickToUploadFile: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '0em',
    color: '#2582F0',
    margin: '1px 0px 0px 8.2px',
  },
  inputFile: {
    display: 'none',
  },
  examPhotoButton: {
    flex: 1,
    height: '50px',
    background: '#3E40B7',
    borderRadius: '0px 5px 5px 0px',
    fontFamily: ' Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '21px',
    border: 'none',
    color: '#ffffff',
    textAlign: 'center',
    textTransform: 'none',
    '&:hover': {
      background: '#3E40B7',
    },
    '&:disabled': {
      backgroundColor: theme.palette.border,
      color: theme.palette.text.secondary,
    },
  },
  buttonBox: {
    width: '100%',
    boxSizing: 'border-box',
    paddingLeft: '77%',
  },
  fileNameOuterBox: {
    width: '100%',
    paddingRight: '70%',
  },
  fileNameBox: {
    '& p': {
      paddingTop: '0px',
    },
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: '1px solid #E8E8E8',
    borderRadius: '5px',
    height: '37px',
    marginBottom: '16px',
  },
  fileName: {
    '& p': {
      paddingTop: '0px',
    },
    marginLeft: '5px',
    fontFamily: ' Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#696969',
    flex: 3,
  },
  removeButton: {
    padding: '0px',
    textTransform: 'none',
    fontFamily: ' Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '21px',
    border: 'none',
    color: '#C72F2C',
    flex: 1,
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: 'transparent',
    },
    '&:disabled': {
      color: theme.palette.text.secondary,
    },
  },
}))

export default useStyles
