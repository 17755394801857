import { Box, Divider, Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Button from "../../Button/Button";
import Input from "../../Input/Input";
import Editor from "../Editor/Editor";
import useStyles from "./styles";
import { useSelector } from "react-redux";

const QuestionDetails = ({ editQuestion, onCancel, onSave }) => {
  const classes = useStyles();
  
  const [question, setQuestion] = useState({
    id: editQuestion?.id ? editQuestion?.id : "",
    title: editQuestion?.title ? editQuestion?.title : "",
    description: editQuestion?.description ? editQuestion?.description : "",
    correctanswer: editQuestion?.correctanswer
      ? editQuestion?.correctanswer
      : "",
    multichoice: editQuestion?.multichoice ? true : false,
  });

  const questionDescription = question?.description
    ? JSON.parse(question?.description || {})?.blocks[0]?.text
    : undefined;

  useEffect(() => {
    setQuestion({ ...editQuestion });
  }, [editQuestion]);

  const onSaveHandler = (value) => {
    onSave({ ...value, status: "Active" });
    onCancel();
  };

  const saveQuestionContent = (value) => {
    setQuestion({
      ...question,
      description: value,
      status: "Active",
    });
  };

  const { editorState } = useSelector(({ exams }) => ({
    editorState: exams?.editorState,
  }));

  const onChangeCorrectAnswer = (value) => {
    if (value.length > 1) return;
    setQuestion({
      ...question,
      correctanswer: value,
    });
  };

  return (
    <div key={question?.id}>
      <Input
        labeltxt="Title"
        onChange={(e) => setQuestion({ ...question, title: e.target.value })}
        value={question?.title}
        placeholderTxt="Question Title"
      />

      <div
        style={{
          display: "flex",
          gap: "5px",
        }}
      >
        <input
          id="checkbox_muliple"
          name="checkbox_muliple"
          type="checkbox"
          onClick={(e) => {
            setQuestion({
              ...question,
              correctanswer: "",
              multichoice: !question?.multichoice,
            });
          }}
          checked={question?.multichoice}
          value={question?.multichoice}
        />
        <label
          htmlFor="checkbox_muliple"
          style={{
            fontSize: "14px",
            userSelect: "none",
          }}
        >
          Is multiple choice
        </label>
      </div>
      {question?.multichoice ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "4px",
            gap: "5px",
            alignItems: "baseline",
          }}
        >
          <input
            name="CorrectAnswer"
            id="CorrectAnswer"
            type="text"
            style={{
              padding: "5px",
              borderRadius: "5px",
              maxWidth: "130px",
              height: "26px",
            }}
            value={question?.correctanswer}
            placeholder="Correct Answer"
            onChange={(v) => {
              onChangeCorrectAnswer(v.target.value);
            }}
          />
          {question?.correctanswer ? (
            <p
              style={{
                fontSize: "14px",
                color: "#3d3d3d",
                fontWeight: "500",
                height: "26px",
              }}
            >
              The Correct Answer is{" "}
              <strong>
                {"("}
                {question?.correctanswer}
                {")"}
              </strong>
            </p>
          ) : null}
        </div>
      ) : null}

      <Typography className={classes.questionLabel} variant="body1">
        Question
      </Typography>
      {/* editor */}
      <Editor
        onChange={(e) =>
          setQuestion({ ...question, description: e.target.value })
        }
        saveQuestionContent={saveQuestionContent}
        data={question?.description}
        index={question?.index}

        // editorState={editorState}
        // setEditorState={setEditorState}
      />
      <Divider className={classes.div} />
      <Box className={classes.btnContainer}>
        <Button
          disableRipple
          className={classes.uploadBtnTextEdit}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          className={classes.createBtn}
          variant="contained"
          color="secondary"
          onClick={() => onSaveHandler(question)}
          disabled={
            !(
              question?.title &&
              questionDescription &&
              (question?.multichoice ? question?.correctanswer : true)
            )
          }
        >
          Save
        </Button>
      </Box>
    </div>
  );
};

export default QuestionDetails;
// || convertToRaw(editorState.getCurrentContent()).blocks[0].text === ''
