import React from "react";

const ArrowLeftIcon = (props) => {
  return (
    <svg
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M4.18333 9.47123L0.654664 5.94256C0.404703 5.69253 0.264282 5.35345 0.264282 4.9999C0.264282 4.64635 0.404703 4.30727 0.654664 4.05723L4.18333 0.528564L5.126 1.47123L1.6 4.9999L5.12866 8.52856L4.18333 9.47123Z" />
    </svg>
  );
};

export default ArrowLeftIcon;
