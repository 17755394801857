import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Button } from '@material-ui/core';
import arrowIcon from '../../assets/arrowIcon.png';
import { Link } from 'react-router-dom';
import useStyles from './styles';
import jwt_decode from 'jwt-decode';

import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import SubmittionModal from '../Modal/SubmittionModal';
import {
  fetchExamById,
  fetchExamQuestions,
  userFromToken,
  getRemainingTimeAction,
  fetchExamAnswers,
} from '../../store/actions';
import ExamTimer from './ExamTimer/ExamTimer';
import Answer from './Answer/Answer';
import SkeletonLoader from '../UserHomeSkeleton/UserHomeSkeleton';
import LZString from 'lz-string';

const Exam = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const userId = userFromToken().id;
  const { role } = jwt_decode(sessionStorage.getItem('accessToken'));
  let userRole = role.toLowerCase() === 'user';
  const { exam, examQuestions, remainingTime, time, loading, examAnswers } =
    useSelector(({ examDetail, examsUser }) => ({
      exam: examDetail.activeExam,
      examQuestions: examDetail.examQuestions,
      remainingTime: examsUser.remainingTime,
      time: examsUser.time,
      loading: examDetail.loading,
      examAnswers: examDetail.examAnswers,
    }));
  const dispatch = useDispatch();
  useEffect(() => {
    id && dispatch(fetchExamById(id, false));
    dispatch(fetchExamQuestions(id));
    userRole && dispatch(getRemainingTimeAction(userId, id));
    userRole && dispatch(fetchExamAnswers(userId, id));
  }, [id]);

  const deCompressedQuestions = (list) => {
    let result = list?.map((item, index) => {
      const parsedData = JSON.parse(list[index]?.description);
      let newEntity = Object.values(parsedData?.entityMap || {}).forEach(
        (subItem, subIndex) => {
          subItem.data.src = LZString.decompress(
            parsedData?.entityMap[subIndex]?.data?.src
          );
        }
      );
      return {
        ...item,
        description: JSON.stringify({
          ...parsedData,
          entityMap: {
            ...parsedData?.entityMap,
            ...newEntity,
          },
        }),
      };
    });
    return result;
  };

  const renderdExam = deCompressedQuestions(examQuestions)?.map(
    (questionItem, i) => {
      return (
        <Answer
          key={questionItem?.id}
          questionItem={questionItem}
          i={i}
          exam={exam}
          time={time}
          countedTime={remainingTime}
        />
      );
    }
  );

  return !loading && exam?.title ? (
    <Box>
      {/* headerSection */}
      {role.toLowerCase() === 'user' ? (
        <Link to="/home" className={classes.backToExamLink}>
          <img src={arrowIcon} alt="arrow" className={classes.arrowIcon} />
          <Typography className={classes.backToExamText}>
            Back to exams list
          </Typography>
        </Link>
      ) : (
        <Link to="/admin/home" className={classes.backToExamLink}>
          <img src={arrowIcon} alt="arrow" className={classes.arrowIcon} />
          <Typography className={classes.backToExamText}>
            Back to exams list
          </Typography>
        </Link>
      )}
      <Typography className={classes.examTitle} variant="h2">
        {exam?.title}
      </Typography>
      {/* end of header */}
      {/* examQuestion */}
      <Grid container>
        <Grid item lg={8} xs={8}>
          {renderdExam}
          <div
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            <Button
              className={classes.submitButton}
              variant="contained"
              color="secondary"
              disabled={!(examAnswers.length === examQuestions.length)}
              onClick={() => setOpen(true)}
            >
              SUBMIT YOUR EXAM
              <Typography
                component={'span'}
                className={classes.startText}
              ></Typography>
            </Button>
            <SubmittionModal
              submitHandler={() => {}}
              handleClose={() => setOpen(false)}
              open={open}
            />
          </div>
        </Grid>
        <Grid item lg={2} xs={2}>
          <ExamTimer
            remainingTime={remainingTime}
            id={id}
            examQuestions={examQuestions}
            exam={exam}
          />
        </Grid>
      </Grid>
    </Box>
  ) : (
    <SkeletonLoader />
  );
};

export default Exam;
