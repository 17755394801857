import React from "react";

const ArrowDownIcon = (props) => {
  return (
    <svg
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M6.99997 7.49997C6.73736 7.50043 6.47725 7.44898 6.23459 7.34859C5.99192 7.24819 5.7715 7.10083 5.58597 6.91497L0.292969 1.62097L1.70697 0.20697L6.99997 5.49997L12.293 0.20697L13.707 1.62097L8.41397 6.91397C8.22852 7.10001 8.00813 7.24756 7.76547 7.34812C7.5228 7.44869 7.26265 7.5003 6.99997 7.49997Z" />
    </svg>
  );
};

export default ArrowDownIcon;
