import React from "react";
import {
  Card as MuiCard,
  CardMedia,
  Typography,
  CardContent,
  Box,
} from "@material-ui/core";
import TextHighlight from "../TextHighlight/TextHighlight";
import useStyles from "./styles";
const Card = ({
  color,
  status,
  timeRemain,
  timeTotal,
  title,
  img,
  children,
}) => {
  const classes = useStyles();
  return (
    <MuiCard className={classes.card}>
      <CardMedia
        className={classes.media}
        component="img"
        image={img}
        title={title}
      />
      <CardContent className={classes.content}>
        <Box>
          <TextHighlight color={color}>{status}</TextHighlight>
          <Typography
            className={classes.title}
            gutterBottom
            variant="h3"
            component="h3"
          >
            {title}
          </Typography>
        </Box>
        <Box className={classes.time}>
          <Box>
            <Typography variant="subtitle1">Duration</Typography>
            <Typography variant="body2">{timeTotal}</Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1">Time Remainig</Typography>
            <Typography
              component={"span"}
              style={{
                fontSize: "0.875rem",
                color: "#000",
                fontWeight: 700,
                lineHeight: "21px",
              }}
            >
              {timeRemain}
            </Typography>
          </Box>
        </Box>
      </CardContent>
      {children}
    </MuiCard>
  );
};

export default Card;
