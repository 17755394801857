import React from "react";

const ArrowRightIcon = (props) => {
  return (
    <svg
      viewBox="0 0 9 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M2.31102 13.707L0.900024 12.293L6.18902 6.99997L0.900024 1.70697L2.31502 0.292969L7.60003 5.58597C7.97497 5.96102 8.1856 6.46964 8.1856 6.99997C8.1856 7.5303 7.97497 8.03891 7.60003 8.41397L2.31102 13.707Z" />
    </svg>
  );
};

export default ArrowRightIcon;
