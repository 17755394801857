import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    minHeight: "100vh",
    backgroundColor: theme.palette.background.main,
  },
  contentInner: {
    margin: "45px 40px 60px 277px",
    flex: "1",
    flexWrap: "wrap",
  },
}));

export default useStyles;
