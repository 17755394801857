import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  toolbarClass: {
    background: theme.palette.border,
    borderRadius: '5px 5px  0 0',
  },
  wrapper: {
    border: '1px solid #E8E8E8',
    borderRadius: '5px',
    minHeight: '389px',
    '& figure': {
      margin: '0',
    },
  },
  editor: {
    minHeight: '389px',
    overflow: 'auto',
    cursor: 'text',
    fontFamily: 'Roboto, sans serif',
    fontWeight: '400',
    padding: '10px',
  },
}))

export default useStyles
