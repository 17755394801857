import React from "react";

const EyeIcon = (props) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0)">
        <path d="M16.014 6.27935C14.98 4.59535 12.628 1.77002 8.5 1.77002C4.372 1.77002 2.02 4.59535 0.986004 6.27935C0.666356 6.79636 0.49704 7.39218 0.49704 8.00002C0.49704 8.60786 0.666356 9.20368 0.986004 9.72069C2.02 11.4047 4.372 14.23 8.5 14.23C12.628 14.23 14.98 11.4047 16.014 9.72069C16.3337 9.20368 16.503 8.60786 16.503 8.00002C16.503 7.39218 16.3337 6.79636 16.014 6.27935ZM14.8773 9.02269C13.9893 10.4667 11.9793 12.8967 8.5 12.8967C5.02067 12.8967 3.01067 10.4667 2.12267 9.02269C1.93276 8.71538 1.83217 8.36127 1.83217 8.00002C1.83217 7.63877 1.93276 7.28466 2.12267 6.97735C3.01067 5.53335 5.02067 3.10335 8.5 3.10335C11.9793 3.10335 13.9893 5.53069 14.8773 6.97735C15.0672 7.28466 15.1678 7.63877 15.1678 8.00002C15.1678 8.36127 15.0672 8.71538 14.8773 9.02269Z" />
        <path d="M8.5 4.66663C7.84073 4.66663 7.19626 4.86212 6.6481 5.22839C6.09993 5.59467 5.67269 6.11526 5.4204 6.72435C5.16811 7.33344 5.1021 8.00366 5.23071 8.65026C5.35933 9.29686 5.6768 9.89081 6.14298 10.357C6.60915 10.8232 7.20309 11.1406 7.8497 11.2692C8.4963 11.3979 9.16652 11.3319 9.77561 11.0796C10.3847 10.8273 10.9053 10.4 11.2716 9.85186C11.6378 9.3037 11.8333 8.65923 11.8333 7.99996C11.8323 7.11623 11.4807 6.269 10.8559 5.64411C10.231 5.01921 9.38373 4.66768 8.5 4.66663ZM8.5 9.99996C8.10444 9.99996 7.71776 9.88266 7.38886 9.6629C7.05996 9.44314 6.80361 9.13078 6.65224 8.76533C6.50086 8.39987 6.46126 7.99774 6.53843 7.60978C6.6156 7.22182 6.80608 6.86545 7.08578 6.58575C7.36549 6.30604 7.72186 6.11556 8.10982 6.03839C8.49778 5.96122 8.89991 6.00082 9.26537 6.1522C9.63082 6.30358 9.94317 6.55992 10.1629 6.88882C10.3827 7.21772 10.5 7.6044 10.5 7.99996C10.5 8.53039 10.2893 9.0391 9.91421 9.41417C9.53914 9.78925 9.03043 9.99996 8.5 9.99996Z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EyeIcon;
