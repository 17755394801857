import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import useStyles from './styles';

export default function ConfirmationModal(props) {
const classes=useStyles()
    return (
        <Box>
            <Modal
                open={props.open}
                onClose={props.handleClose}
            >
                <Box className={classes.mainBox}>
                    <Typography variant="h6" component="h2" className={classes.headerText}>
                        {` ${props.msg}`}
                    </Typography>
                    <Typography  className={classes.mainText}>
                        {`${props?.mainText}`}
                    </Typography>
                    <Box className={classes.btnContainer}>
                        <Button className={classes.cancelBtn} onClick={props.handleClose}> <Typography className={classes.cancelText} >Cancel</Typography></Button>
                        <Button className={classes.deleteBtn} onClick={props.buttonHandler}> <Typography className={classes.deleteText} >delete</Typography></Button>

                    </Box>
                </Box>
            </Modal>
        </Box>
    );
}