import React, { useState } from "react";
import { Box, Divider, Typography } from "@material-ui/core";
import useStyles from "./styles";
import { Addicon } from "../../Icons";
import ButtonSecondary from "../../Button/ButtonSecondary/ButtonSecondary";
import ExamQuestion from "../ExamQuestion/ExamQuestion";
import QuestionDetails from "../QuestionDetails/QuestionDetails";

const AddQuestion = ({
  questionList,
  otherQuestionList,
  setQuestionList,
  setOtherQuestionList,
}) => {
  const classes = useStyles();
  const [showQuestionForm, setshowQuestionForm] = useState(false);
  const [editQuestion, setEditedQuestion] = useState({
    title: null,
    id: null,
    description: null,
    correctanswer: null,
    multichoice: null,
  });

  const editHandler = (value) => {
    setEditedQuestion({ ...value });
    setshowQuestionForm(true);
  };
  const showFormHandler = (bool) => {
    setEditedQuestion({});
    setshowQuestionForm(bool);
  };

  const onSaveHanlder = (value) => {
    const question = questionList.find(
      (question, i) => i === editQuestion?.index
    );
    if (question) {
      // const updatedList = questionList?.map((question, i) =>
      //   i === editQuestion.index
      //     ? { ...question, status: 'Inactive' }
      //     : { ...question },
      // )
      const updatedList = questionList.filter(
        (question, i) => i !== editQuestion.index
      );
      setQuestionList([...updatedList, value]);
      setOtherQuestionList([...updatedList, value]);
      setEditedQuestion(value);
    } else {
      setQuestionList([...questionList, value]);
      setOtherQuestionList([...questionList, value]);
    }
  };

  const renderdQuestions = !questionList?.length ? (
    !showQuestionForm && (
      <Box className={classes.questions}>
        <ButtonSecondary
          startIcon={<Addicon />}
          className={classes.createBtn}
          color="secondary"
          variant="contained"
          onClick={() => showFormHandler(!showQuestionForm)}
        >
          Add Question
        </ButtonSecondary>
      </Box>
    )
  ) : (
    <>
      <ExamQuestion
        editHandler={editHandler}
        setshowQuestionForm={setshowQuestionForm}
        questionList={questionList}
        otherQuestionList={otherQuestionList}
        setQuestionList={setQuestionList}
        setOtherQuestionList={setOtherQuestionList}
        showQuestionForm={showFormHandler}
      />
      <Divider className={classes.div} />
      <ButtonSecondary
        startIcon={<Addicon />}
        className={[classes.createBtn, classes.leftAuto].join(" ")}
        color="secondary"
        variant="contained"
        onClick={() => showFormHandler(!showQuestionForm)}
      >
        Add Question
      </ButtonSecondary>
    </>
  );
  return (
    <Box className={classes.container}>
      <Typography variant="h3">Questions</Typography>
      <Divider className={classes.div} />
      {renderdQuestions}
      {showQuestionForm ? (
        <QuestionDetails
          onCancel={() => setshowQuestionForm(!showQuestionForm)}
          editQuestion={editQuestion}
          onSave={(value) => onSaveHanlder(value)}
        />
      ) : null}
    </Box>
  );
};

export default AddQuestion;
