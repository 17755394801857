import React from "react";
import { Box, Typography } from "@material-ui/core";
import { TriangleArrowDown, TriangleArrowUp } from "../../Icons";
import useStyles from "./styles";

const DurationSpinner = ({ duration, updateDurationHandler }) => {
  const classes = useStyles();

  const addClickHandler = (type, value) => {
    if (parseInt(value) <= 8) {
      switch (type) {
        case "days":
          //   days: '0' + (parseInt(value) + 1),
          updateDurationHandler("days", "0" + `${parseInt(value) + 1}`);
          break;
        case "hours":
          updateDurationHandler("hours", "0" + `${parseInt(value) + 1}`);
          break;
        case "minutes":
          updateDurationHandler("minutes", "0" + `${parseInt(value) + 1}`);
          break;
        default:
          break;
      }
    } else {
      switch (type) {
        case "days":
          updateDurationHandler("days", `${parseInt(value) + 1}`);
          break;
        case "hours":
          updateDurationHandler("hours", `${parseInt(value) + 1}`);
          break;
        case "minutes":
          updateDurationHandler("minutes", `${parseInt(value) + 1}`);
          break;
        default:
          break;
      }
    }
  };

  const subtractClickHandler = (type, value) => {
    if (parseInt(value) !== 0) {
      if (parseInt(value) <= 10) {
        switch (type) {
          case "days":
            //   days: '0' + (parseInt(value) - 1),
            updateDurationHandler("days", "0" + `${parseInt(value) - 1}`);
            break;
          case "hours":
            updateDurationHandler("hours", "0" + `${parseInt(value) - 1}`);
            break;
          case "minutes":
            updateDurationHandler("minutes", "0" + `${parseInt(value) - 1}`);
            break;
          default:
            break;
        }
      } else {
        switch (type) {
          case "days":
            updateDurationHandler("days", `${parseInt(value) - 1}`);
            break;
          case "hours":
            updateDurationHandler("hours", `${parseInt(value) - 1}`);
            break;
          case "minutes":
            updateDurationHandler("minutes", `${parseInt(value) - 1}`);
            break;
          default:
            break;
        }
      }
    }
  };

  return (
    <Box className={classes.container}>
      <Box
        className={[
          classes.spinner,
          parseInt(duration?.days) > 0 && classes.colored,
        ].join(" ")}
      >
        <Typography variant="h3">{duration?.days}</Typography>
        <Box className={classes.spinnerButtonContainer}>
          <Box
            className={classes.spinnerWrapper}
            onClick={() => {
              addClickHandler("days", duration?.days);
            }}
          >
            <TriangleArrowUp />{" "}
          </Box>
          <Box
            className={classes.spinnerWrapper}
            onClick={() => {
              subtractClickHandler("days", duration?.days);
            }}
          >
            <TriangleArrowDown />
          </Box>
        </Box>
        <Typography className={classes.spinnerLabel} variant="body1">
          Days
        </Typography>
      </Box>
      <Box
        className={[
          classes.spinner,
          parseInt(duration?.hours) > 0 && classes.colored,
        ].join(" ")}
      >
        <Typography variant="h3">{duration?.hours}</Typography>
        <Box className={classes.spinnerButtonContainer}>
          <Box
            className={classes.spinnerWrapper}
            onClick={() => {
              if (parseInt(duration?.hours) < 23) {
                addClickHandler("hours", duration?.hours);
              } else {
                addClickHandler("days", duration?.days);
                updateDurationHandler("hours", "00");
              }
            }}
          >
            <TriangleArrowUp />
          </Box>

          <Box
            className={classes.spinnerWrapper}
            onClick={() => {
              subtractClickHandler("hours", duration?.hours);
            }}
          >
            <TriangleArrowDown />
          </Box>
        </Box>
        <Typography className={classes.spinnerLabel} variant="body1">
          Hours
        </Typography>
      </Box>
      <Box
        className={[
          classes.spinner,
          parseInt(duration?.minutes) > 0 && classes.colored,
        ].join(" ")}
      >
        <Typography variant="h3">{duration?.minutes}</Typography>
        <Box className={classes.spinnerButtonContainer}>
          <Box
            className={classes.spinnerWrapper}
            onClick={() => {
              addClickHandler("minutes", duration?.minutes);
              if (parseInt(duration?.minutes) < 59) {
                addClickHandler("minutes", duration?.minutes);
              } else {
                addClickHandler("hours", duration?.hours);
                updateDurationHandler("minutes", "00");
              }
            }}
          >
            <TriangleArrowUp />
          </Box>
          <Box
            className={classes.spinnerWrapper}
            onClick={() => {
              subtractClickHandler("minutes", duration?.minutes);
            }}
          >
            <TriangleArrowDown />
          </Box>
        </Box>

        <Typography className={classes.spinnerLabel} variant="body1">
          Minutes
        </Typography>
      </Box>
    </Box>
  );
};

export default DurationSpinner;
