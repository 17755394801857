import { Box, Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import useStyles from "./styles";
import Button from "../../Button/Button";
import ExamInfoBar from "../ExamInfoBar/ExamInfoBar";
import { useParams, useHistory, withRouter } from "react-router-dom";
import {
  fetchExamUsers,
  fetchExamAnswers,
  fetchExamById,
  updateUserExamTotalScore,
  setFilteredData,
  setExamScore,
  fetchUserExamTotalScore,
  fetchWaitingAssessmentExamUsersAction,
  fetchExamQuestions,
} from "../../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import { getAssessmentParticipantInfo } from "../../../store/actions/AssessmentActions";
import QuestionPreviewWithPagination from "./QuestionPreviewWithPagination";

const AdminExam = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { id, userExamId, participantId } = useParams();
  const [markComplete, setMarkComplete] = useState();

  const {
    userExamList,
    filterData,
    examAnswers,
    activeExam,
    loading,
    listExamUsers,
    waitingAssessmentUser,
    assessmentParticipantInfo,
    examScore,
    examQuestions,
  } = useSelector(({ exams, examDetail, AssessmentReducer }) => ({
    userExamList: exams?.userExamList,
    waitingAssessmentUser: exams?.waitingAssessmentUser,
    loading: exams?.loading,
    filterData: exams?.filterData,
    examAnswers: examDetail?.examAnswers,
    activeExam: examDetail?.activeExam,
    examScore: exams?.examScore,
    listExamUsers: examDetail.listExamUsers,
    examQuestions: examDetail?.examQuestions,
    order: exams?.order,
    columnNum: exams?.columnNum,
    columnName: exams?.columnName,
    assessmentParticipantInfo:
      AssessmentReducer?.currentAssessment?.participantInfo,
  }));
  let reduceExamAnswers = Object.values(
    examAnswers?.reduce(
      (acc, item) => ({ ...acc, [item?.Question?.id]: { ...item } }),
      {}
    )
  );

  const fetchUserExamAnswerChangeHandler = (participantId) => {
    dispatch(fetchExamAnswers(participantId, id, userExamId, history));
    history.push(`/admin/exam/${id}/${userExamId}/${participantId}`);
  };
  const pageChangeHandler = (event, page) => {
    dispatch(setFilteredData("page", page));
  };
  const completeHandler = (bool) => {
    setMarkComplete(bool);
    let totalScore = 0;
    examAnswers.map((answer) => {
      totalScore += answer.Answer.score;
    });
    totalScore = Math.ceil(totalScore / examAnswers.length);
    dispatch(setExamScore(totalScore));
    dispatch(
      updateUserExamTotalScore(id, participantId, totalScore, filterData)
    );
    dispatch(fetchExamUsers(id, filterData));
    history.push(`/admin/exam/${id}`);
  };

  useEffect(() => {
    dispatch(
      fetchWaitingAssessmentExamUsersAction(id, {
        ...filterData,
        size: 10,
        status: "Waiting",
      })
    );
  }, [id, filterData.page]);
  useEffect(() => {
    dispatch(setFilteredData("page", 1));
    dispatch(
      fetchWaitingAssessmentExamUsersAction(id, {
        page: 1,
        size: 10,
        status: "Waiting",
      })
    );
  }, []);

  useEffect(() => {
    dispatch(fetchExamQuestions(id));
  }, [id]);

  useEffect(() => {
    dispatch(fetchExamById(id));
    dispatch(fetchExamAnswers(participantId, id));
    dispatch(fetchUserExamTotalScore(id, participantId, filterData));
    dispatch(getAssessmentParticipantInfo(participantId));
  }, [participantId]);

  const downloadAnswers = () => {
    const userName = `${
      assessmentParticipantInfo?.firstName +
      " " +
      assessmentParticipantInfo?.lastName
    }`;
    const userExamScore = `${examScore?.score}`;
    let markdown = "";
    markdown += `# Candidate's Info\n\n`;
    markdown += `>> Name: ${userName}\n`;
    markdown += `>> Total Score: ${userExamScore}\n\n`;
    markdown += `# Questions And Answers\n\n`;
    reduceExamAnswers.map((question, id) => {

      let quesDisc = "";
      JSON.parse(question.Question.description)?.blocks?.forEach((blk) => {
        quesDisc += `${blk.text}\n`;
      });

      markdown += `__________________________\n\n`;
      markdown += `## Question ${id + 1}\n\n`;
      markdown += `>> title:\n${question?.Question?.title}\n\n`;
      markdown += `>> description:\n${quesDisc}\n>> Answer:\n${question?.Answer?.description}\n\n`;
      // markdown += `>> Score:\n${question?.Answer?.score}\n\n`;
    });

    const blob = new Blob([markdown], { type: "text/markdown" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${userName}-answers.md`;
    link.click();
    URL.revokeObjectURL(url);
  };

  return (
    <Box className={classes.wrapper}>
      <ExamInfoBar
        userList={userExamList && userExamList?.result?.data}
        waitingAssessmentUser={
          waitingAssessmentUser && waitingAssessmentUser?.result?.data
        }
        userExamId={userExamId}
        examId={id}
        fetchUserExamAnswerChangeHandler={fetchUserExamAnswerChangeHandler}
        pages={waitingAssessmentUser?.result?.pages}
        count={waitingAssessmentUser?.result?.pages}
        loading={loading}
        page={filterData.page}
        pageChangeHandler={pageChangeHandler}
        participantId={participantId}
        assessmentParticipantInfo={assessmentParticipantInfo}
      />
      {reduceExamAnswers ? (
        <Box className={classes.container}>
          <Box display={"flex"} justifyContent="space-between">
            <Typography className={classes.examTitle} variant="h1">
              {activeExam?.title}
            </Typography>
            <Button
              color="primary"
              variant="contained"
              onClick={downloadAnswers}
              style={{
                width: "80px",
              }}
            >
              Download
            </Button>
          </Box>

          <QuestionPreviewWithPagination
            reduceExamAnswers={reduceExamAnswers}
            examQuestions={examQuestions}
            id={id}
            userExamId={userExamId}
            participantId={participantId}
          />

          <Box className={classes.examBtn}>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => completeHandler(!markComplete)}
            >
              Mark as completed
            </Button>
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};
export default withRouter(AdminExam);
