import React, { useEffect, useCallback } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Input from "../../Input/Input";
import ButtonSecondary from "../../Button/ButtonSecondary/ButtonSecondary";
import Link from "../../Link/Link";
import HeadTable from "../../Table/HeadTable/HeadTable";
import FooterTable from "../../Table/FooterTable/FooterTable";
import { Addicon, SearchIcon, ArrowRightIcon } from "../../Icons";
import { tableHeadAdminBoard } from "../../../helpers/objects";
import {
  fetchAdminExams,
  fetchAllExamUsers,
  resetSelectedUsers,
  setFilteredData,
  setFiltered,
  setColNum,
  clearFilteredData,
  clearExamInfo,
} from "../../../store/actions";
import debounce from "lodash.debounce";
import TextHighlight from "../../TextHighlight/TextHighlight";
import useStyles from "./styles";
import { Box, Typography } from "@material-ui/core";

const AdminBoard = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const resetHandler = () => {
    dispatch(resetSelectedUsers());
    dispatch(clearFilteredData());
  };

  const { examList, listExamUsers, filterData, columnNum } = useSelector(
    ({ exams, examDetail }) => ({
      examList: exams.examList,
      listExamUsers: examDetail.listExamUsers,
      filterData: exams?.filterData,
      columnNum: exams?.columnNum,
    })
    );

  useEffect(() => {
    columnNum === 1
      ? dispatch(setFilteredData("columnName", "title"))
      : columnNum === 2
      ? dispatch(setFilteredData("columnName", "activeSessions"))
      : columnNum === 3
      ? dispatch(setFilteredData("columnName", "toBeAssest"))
      : dispatch(setFilteredData("columnName", "title"));
  }, [columnNum]);
  useEffect(() => {
    dispatch(fetchAdminExams());
    dispatch(fetchAllExamUsers(filterData));
    dispatch(setFiltered(false));
  }, [
    filterData.searchTerm,
    filterData.columnName,
    filterData.order,
    filterData.page,
  ]);
  useEffect(() => {
    dispatch(clearExamInfo());
    dispatch(fetchAdminExams());
    dispatch(fetchAllExamUsers({page:1,size:10,order:"ASC",columnName:"title"}));
  }, []);

  const handleCreateExamRoute = () => {
    history.push("/admin/create");
  };

  const searchChangeHandler = (e) => {
    dispatch(setFilteredData("searchTerm", e.target.value));
    dispatch(setFilteredData("page", 1));
  };
  const debouncedChangeHandler = useCallback(
    debounce(searchChangeHandler, 500),
    []
  );

  const pageChangeHandler = (event, page) => {
    dispatch(setFilteredData("page", page));
  };

  const sortHandler = (order, id) => {
    dispatch(setFilteredData("order", order));
    dispatch(setColNum(id));
  };

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.header}>
          <Typography className={classes.title} variant="h1">
            Exams
          </Typography>
          <Typography className={classes.subtext} variant="body1">
            {`${examList ? examList?.length:0} Exam · `}   
            <Box className={classes.activeSessions} component="span">
              {`${listExamUsers?.activeExams?listExamUsers?.activeExams:0}  Active sessions`} 
            </Box>
          </Typography>
          <Box className={classes.search}>
            <Input
              id="search"
              labeltxt={<SearchIcon />}
              placeholderTxt="Search"
              onChange={debouncedChangeHandler}
            />
          </Box>
        </Box>
        <Box>
          <ButtonSecondary
            startIcon={<Addicon />}
            className={classes.createBtn}
            color="secondary"
            noicon="true"
            variant="contained"
            onClick={handleCreateExamRoute}
          >
            Create new exam
          </ButtonSecondary>
        </Box>
      </Box>
      <Box className={classes.tableContainer}>
        <Box className={classes.table}>
          <HeadTable
            classes={classes}
            headCell={tableHeadAdminBoard}
            sortHandler={sortHandler}
          />
          {listExamUsers?.result?.data &&
            listExamUsers?.result?.data?.map((exam) => {
              return (
                <Box key={exam?.id} className={classes.tableBodyContainer}>
                  <Box
                    className={classes.tableCell}
                    style={{ flex: "5", justifyContent: "flex-start" }}
                  >
                    <Box className={classes.examInfo}>
                      <Box className={classes.examImg}>
                        <img src={exam?.imageUrl} />
                      </Box>
                      <Box className={classes.examDetails}>
                        <Typography variant="h3" className={classes.examTitle}>
                          {exam.title}
                        </Typography>
                        <Typography variant="body1">
                          {exam?.participant?.count
                            ? exam?.participant?.count
                            : 0}{" "}
                          Participants
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    className={classes.tableCell}
                    style={{ flex: "4", justifyContent: "center" }}
                  >
                    <Box className={classes.highlights}>
                      {exam?.activeSession?.count
                        ? exam?.activeSession?.count
                        : 0}
                    </Box>
                  </Box>
                  <Box
                    className={classes.tableCell}
                    style={{ flex: "4", justifyContent: "center" }}
                  >
                    <Box className={classes.highlights}>
                      {exam?.numToBeAssest?.count > 0 ? (
                        <TextHighlight color="finished">
                          {exam?.numToBeAssest?.count}{" "}
                        </TextHighlight>
                      ) : (
                        <TextHighlight textColor="#00B761" color="active">
                          {exam?.numToBeAssest?.count
                            ? exam?.numToBeAssest?.count
                            : 0}
                        </TextHighlight>
                      )}
                    </Box>
                  </Box>
                  <Box style={{ flex: "1.7", justifyContent: "center" }}>
                    <Box className={classes.tableBtnContainer}>
                      <Link
                        to={`/admin/exam/${exam?.id}`}
                        className={classes.tableBtn}
                        text="View Exam"
                        onClick={() => resetHandler(exam?.id)}
                      />
                      <ArrowRightIcon className={classes.linkIcon} />
                    </Box>
                  </Box>
                </Box>
              );
            })}
        </Box>
        <FooterTable
          data={listExamUsers?.result?.data}
          pages={
            filterData.searchTerm === ""
              ? Math.ceil(examList?.length / filterData.size)
              : Math.ceil(listExamUsers?.result?.items / filterData.size)
          }
          page={listExamUsers?.result?.currentPage}
          classes={classes}
          pageChangeHandler={pageChangeHandler}
          length={
            filterData.searchTerm === ""
              ? examList?.length
              : listExamUsers?.result?.items
          }
        />
      </Box>
    </>
  );
};

export default withRouter(AdminBoard);

