import React, { useState, useEffect } from "react";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import { Editor as QuestionEditor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import useStyles from "./styles";
import { useDispatch } from "react-redux";
import { setEditorStateAction } from "../../../store/actions";

const Editor = ({ saveQuestionContent, data, index }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const ind = typeof index === "number";
  const content = {
    entityMap: {},
    blocks: [
      {
        key: "637gr",
        text: "",
        type: "unstyled",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
    ],
  };

  const [editorState, setEditorState] = useState(
    data
      ? EditorState.createWithContent(convertFromRaw(JSON.parse(data)))
      : EditorState.createWithContent(convertFromRaw(content))
  );
  useEffect(() => {
    ind &&
      setEditorState(
        ind && EditorState.createWithContent(convertFromRaw(JSON.parse(data)))
      );
  }, [ind, data]);
  const handleEditorChange = (state) => {
    setEditorState(state);
    dispatch(
      setEditorStateAction(
        JSON.stringify(convertToRaw(editorState.getCurrentContent()))
      )
    );
    saveQuestionContent(
      JSON.stringify(convertToRaw(editorState.getCurrentContent()))
    );
  };
  const getFileBase64 = (file, callback) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => callback(reader.result);
    reader.onerror = (error) => {};
  };

  const imageUploadCallback = (file) =>
    Math.round(file.size / 1024) > 2000
      ? alert("File too Big, please select a file less than 2mb!")
      : new Promise((resolve, reject) =>
          getFileBase64(file, (data) => resolve({ data: { link: data } }))
        );

  const toolBarConfig = {
    options: [
      "inline",
      "blockType",
      "fontSize",
      "fontFamily",
      "list",
      "textAlign",
      "colorPicker",
      "link",
      "image",
    ],
    image: {
      previewImage: true,
      alt: { present: true, mandatory: false },
      inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
      urlEnabled: true,
      uploadEnabled: true,
      alignmentEnabled: false,
      uploadCallback: imageUploadCallback,
      defaultSize: {
        width: "600px",
      },
    },
    inline: {
      inDropdown: false,
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
      options: ["bold", "italic", "underline"],
    },
    list: {
      inDropdown: true,
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
      options: ["unordered", "ordered"],
    },
    link: {
      options: ["link"],
    },
    textAlign: {
      inDropdown: true,
    },
  };

  return (
    <>
      <QuestionEditor
        editorState={editorState}
        onEditorStateChange={handleEditorChange}
        wrapperClassName={classes.wrapper}
        editorClassName={classes.editor}
        toolbarClassName={classes.toolbarClass}
        toolbar={toolBarConfig}
      />
    </>
  );
};

export default Editor;
