import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  questionLabel: {
    marginTop: "27px",
    marginBottom: "7px",
  },
  div: {
    marginTop: "19px",
    marginBottom: "27px",
  },
  createBtn: {
    width: "140px",
    textTransform: "unset",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    marginTop: "5px",
    borderRadius: "5px",
  },
  uploadBtnTextEdit: {
    marginRight: "34px",
    "&:hover": {
      background: "none",
    },
    "& span": {
      color: theme.palette.primary.main,
      textTransform: "initial",
    },
  },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
}));

export default useStyles;
