import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { endUserExam, userFromToken, setTimeState } from '../../store/actions'
import { UPDATE_STATUS } from '../../store/types/types'
import Timer from 'react-compound-timer'

const ExamDuration = ({ countedTime, id }) => {
  const dispatch = useDispatch()
  const endTimeHandler = () => {
    dispatch(setTimeState('finished'))
    dispatch({
      type: UPDATE_STATUS,
      value: { id: id, status: 'finished' },
    })
    dispatch(endUserExam(userFromToken().id, id))
  }
  return (
    <Timer
      initialTime={countedTime}
      direction="backward"
      lastUnit="h"
      formatValue={(value) => `${value < 10 ? `0${value}` : value}`}
      checkpoints={[
        {
          time: 0,
          callback: endTimeHandler,
        },
      ]}
    >
      {() => {
        return (
          <div>
            <Timer.Hours
              formatValue={(value) => `${value < 10 ? `0${value}` : value}h`}
            />
            :
            <Timer.Minutes
              formatValue={(value) => `${value < 10 ? `0${value}` : value}m`}
            />
            :
            <Timer.Seconds
              formatValue={(value) => `${value < 10 ? `0${value}` : value}s`}
            />
          </div>
        )
      }}
    </Timer>
  )
}

export default ExamDuration
