import React, { useState } from 'react'
import { withRouter, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Box, Typography } from '@material-ui/core'
import Button from '../Button/Button'
// import Input from '../Input/Input'
import Link from '../Link/Link'
import useStyles from './styles'
import { checkAuthDispatch } from '../../store/actions'
import { validateEmail } from '../../helpers/regex'
import { TextField, InputLabel } from '@material-ui/core'

const LoginForm = (props) => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const [emailInputValue, setEmailInputValue] = useState('')
  const [passwordInputValue, setPasswordInputValue] = useState('')
  const [state, setState] = useState({
    emailError: false,
  })
  
  const handleEmailChange = (e) => {
    setEmailInputValue(e.target.value)
    setState((prevState) => ({
      ...prevState,
      emailError: false,
    }))
  }
  const handlePasswordChange = (e) => {
    setPasswordInputValue(e.target.value)
    setState((prevState) => ({
      ...prevState,
      passwordError: 'false',
    }))
  }
  const handelLogin = (e) => {
    e.preventDefault()
    setState((prevState) => ({
      ...prevState,
      emailError: false,
    }))
    if (!validateEmail(emailInputValue)) {
      return setState((prevState) => ({
        ...prevState,
        emailError: true,
      }))
    }
      dispatch(
        checkAuthDispatch(
          {
            email: emailInputValue,
            password: passwordInputValue,
          },
          history,
        ),
      )
  }

  return (
    <>
      <Box className={classes.formHeading}>
        <Typography variant="h6">Welcome!</Typography>
        <Typography variant="h1">Login into your account</Typography>
      </Box>
      <Box>
        <form onSubmit={handelLogin}>
          <InputLabel className={classes.Labels}>Email</InputLabel>
          <TextField
            variant="outlined"
            value={emailInputValue}
            onChange={handleEmailChange}
            type="Email"
            placeholder="Email"
            className={classes.Inputs}
            error={state.emailError}
            helperText={
              state.emailError ? 'incorrect email format' : ''
            }
          />
          <InputLabel className={classes.Labels}>Password</InputLabel>
          <TextField
            variant="outlined"
            value={passwordInputValue}
            onChange={handlePasswordChange}
            labeltxt="password"
            type="password"
            placeholder="Password"
            className={classes.Inputs}
          // error={state.passwordError === 'true'}
          // helperText={
          //   state.passwordError === 'true' ? 'incorrect password format' : ' '
          // }
          />
          {<Link align="flex-end" to="/login/forget" text="Forgot password?" />}
          <Box className={classes.formBtn}>
            <Button
              fullWidth
              type="submit"
              variant="contained"
              color="secondary"
              className={classes.LoginButton}
              disabled={!emailInputValue || !passwordInputValue}
            >
              Login
            </Button>
          </Box>
        </form>
      </Box>
    </>
  )
}

export default withRouter(LoginForm)
