export const FETCH_ADMIN_EXAM = 'FETCH_ADMIN_EXAM'
export const FETCH_ADMIN_EXAM_SUCCESS = 'FETCH_ADMIN_EXAM_SUCCESS'
export const FETCH_ADMIN_EXAM_FAIL = 'FETCH_ADMIN_EXAM_FAIL'

export const CREATE_EXAM = 'CREATE_EXAM'
export const CREATE_EXAM_SUCCESS = 'CREATE_EXAM_SUCCESS'
export const CREATE_EXAM_FAILURE = 'CREATE_EXAM_FAILURE'

export const UPDATE_EXAM = 'UPDATE_EXAM'
export const UPDATE_EXAM_SUCCESS = 'UPDATE_EXAM_SUCCESS'
export const UPDATE_EXAM_FAILURE = 'UPDATE_EXAM_FAILURE'

export const UPDATE_EXAM_INFO_FIELD = 'UPDATE_EXAM_INFO_FIELD'

export const ADD_QUESTIONS = 'ADD_QUESTIONS'
export const ADD_QUESTIONS_SUCCESS = 'ADD_QUESTIONS_SUCCESS'
export const ADD_QUESTIONS_FAILURE = 'ADD_QUESTIONS_FAILURE'

export const CREATE_USER = 'CREATE_USER'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE'

export const DELETE_USER = 'DELETE_USER'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE'

export const ASSIGN_USERS = 'ASSIGN_USERS'
export const ASSIGN_USERS_SUCCESS = 'ASSIGN_USERS_SUCCESS'
export const ASSIGN_USERS_FAILURE = 'ASSIGN_USERS_FAILURE'

export const FETCH_EXAM_USERS = 'FETCH_EXAM_USERS'
export const FETCH_EXAM_USERS_SUCCESS = 'FETCH_EXAM_USERS_SUCCESS'
export const FETCH_EXAM_USERS_FAILURE = 'FETCH_EXAM_USERS_FAILURE'

export const SET_SCORE = 'SET_SCORE'
export const SET_SCORE_SUCCESS = 'SET_SCORE_SUCCESS'
export const SET_SCORE_FAILURE = 'SET_SCORE_FAILURE'

export const FETCH_USERS = 'FETCH_USERS'
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS'
export const FETCH_USERS_ADD_SUCCESS = 'FETCH_USERS_ADD_SUCCESS'
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE'

export const SET_OPEN_MODAL = 'SET_OPEN_MODAL'

export const GET_FILTERED_EXAM_USERS = 'GET_FILTERED_EXAM_USERS'

export const CLEAR_FILTERED_DATA = 'CLEAR_FILTERED_DATA'

export const UPDATE_USER_EXAM_TOTAL_SCORE = 'UPDATE_USER_EXAM_TOTAL_SCORE'
export const UPDATE_USER_EXAM_TOTAL_SCORE_SUCCESS =
  'UPDATE_USER_EXAM_TOTAL_SCORE_SUCCESS'
export const UPDATE_USER_EXAM_TOTAL_SCORE_FAILURE =
  'UPDATE_USER_EXAM_TOTAL_SCORE_FAILURE'

export const SET_SELECTED_USERS = 'SET_SELECTED_USERS'

export const RESET_SELECTED_USERS = 'RESET_SELECTED_USERS'

export const SET_EXAM_USER_CHECK = 'SET_EXAM_USER_CHECK'

export const CLEAR_ORDER_BY_DATA = 'CLEAR_ORDER_BY_DATA'

export const SET_LOADING_BOOL = 'SET_LOADING_BOOL'

export const SET_EXAM_SCORE = 'SET_EXAM_SCORE'

export const SET_EXAM_QUESTION_RATE = 'SET_EXAM_QUESTION_RATE'

export const FETCH_USER_EXAM_SCORE = 'FETCH_USER_EXAM_SCORE'
export const FETCH_USER_EXAM_SCORE_SUCCESS = 'FETCH_USER_EXAM_SCORE_SUCCESS'
export const FETCH_USER_EXAM_SCORE_FAILURE = 'FETCH_USER_EXAM_SCORE_FAILURE'

export const RESEL_LINK = 'RESEL_LINK'

export const SET_FILTERED = 'SET_FILTERED'

export const SET_COLUMN_NUMBER = 'SET_COLUMN_NUMBER'

export const SET_DATE_RANGE = 'SET_DATE_RANGE'

export const CLEAR_FILTER_BY_DATE_RANGE = 'CLEAR_FILTER_BY_DATE_RANGE'

export const SET_FILTERED_BY_DATE_RANGE = 'SET_FILTERED_BY_DATE_RANGE'

export const SET_ENABLE_EXAM_EDIT = 'SET_ENABLE_EXAM_EDIT'

export const SET_EDITOR_STATE = 'SET_EDITOR_STATE'

export const SET_USER_DATA = 'SET_USER_DATA'

export const CLEAR_USER_DATA = 'CLEAR_USER_DATA'

export const FETCH_WAITING_ASSESSMENT_USERS = 'FETCH_WAITING_ASSESSMENT_USERS'
export const FETCH_WAITING_ASSESSMENT_USERS_SUCCESS = 'FETCH_WAITING_ASSESSMENT_USERS_SUCCESS'
export const FETCH_WAITING_ASSESSMENT_USERS_FAILURE = 'FETCH_WAITING_ASSESSMENT_USERS_FAILURE'
