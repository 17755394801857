export const colors = {
  'in progress': 'inProgress',
  active: 'active',
  finished: 'finished',
}
export const btn = {
  'in progress': { txt: 'Continue', style: 'outlined', color: 'primary' },
  active: { txt: 'Take the exam', style: 'contained', color: 'secondary' },
  finished: { txt: 'Finished', style: 'outlined', color: 'primary' },
}

export const tableHeadAdminBoard = [
  {
    label: 'Name',
    id: 1,
    width: 5,
    align: 'flex-start',
  },
  {
    label: 'Active Sessions',
    id: 2,
    width: 4,
    align: 'center',
  },
  {
    label: 'To be assessed',
    id: 3,
    width: 4,
    align: 'center',
  },
  {
    label: '',
    id: 4,
    width: 1.7,
    align: 'center',
  },
]

export const tableHeadExamSummary = [
  {
    label: 'Name',
    id: 1,
    width: 3,
    align: 'flex-start',
  },
  {
    label: 'Session Start Date',
    id: 2,
    width: 3,
    align: 'flex-start',
  },
  {
    label: 'Status',
    id: 3,
    width: 3,
    align: 'flex-start',
  },
  {
    label: 'Score',
    id: 4,
    width: 1,
    align: 'center',
  },
  {
    label: '',
    id: 5,
    width: 1.7,
    align: 'center',
  },
  {
    label: '',
    id: 6,
    width: 0.5,
    align: 'center',
  },
]

export const tableHeadUserHead = [
  {
    label: 'Name',
    id: 1,
    width: 5,
    align: 'flex-start',
  },
  {
    label: 'Created',
    id: 2,
    width: 4,
    align: 'flex-start',
  },
  {
    label: 'Status',
    id: 3,
    width: 4,
    align: 'flex-start',
  },
  {
    label: '',
    id: 4,
    width: 2,
    align: 'center',
  },
  {
    label: '',
    id: 5,
    width: 1,
    align: 'center',
  },
]
