import React from 'react'
const MenuDotsIcon = (classes) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_815_875)">
        <path
          d="M2.5 14.5C3.60457 14.5 4.5 13.6046 4.5 12.5C4.5 11.3954 3.60457 10.5 2.5 10.5C1.39543 10.5 0.5 11.3954 0.5 12.5C0.5 13.6046 1.39543 14.5 2.5 14.5Z"
          fill="#333333"
        />
        <path
          d="M12.5 14.5C13.6046 14.5 14.5 13.6046 14.5 12.5C14.5 11.3954 13.6046 10.5 12.5 10.5C11.3954 10.5 10.5 11.3954 10.5 12.5C10.5 13.6046 11.3954 14.5 12.5 14.5Z"
          fill="#333333"
        />
        <path
          d="M22.5 14.5C23.6046 14.5 24.5 13.6046 24.5 12.5C24.5 11.3954 23.6046 10.5 22.5 10.5C21.3954 10.5 20.5 11.3954 20.5 12.5C20.5 13.6046 21.3954 14.5 22.5 14.5Z"
          fill="#333333"
        />
      </g>
      <defs>
        <clipPath id="clip0_815_875">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.5 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default MenuDotsIcon
