import  {React, useState} from 'react';
import {Button,Menu,MenuItem} from '@mui/material';
import { useDispatch } from 'react-redux';
import { clearUserDataAction } from '../../store/actions';

export default function DropMenu(props) {    
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const dispatch=useDispatch()
  const handleClose = () => {
    setAnchorEl(null);
    dispatch(clearUserDataAction({}));

  };
  // const openModalHandler = (data)=>{
  //     setAnchorEl(null);
  // }
  return (
    <div>
      <Button
      style={{display:'flex',justifyContent:'flex-start'}}
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {props?.children}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
      <MenuItem onClick={()=>{
        props?.deleteUser()
        handleClose()
      }}> Delete User</MenuItem>
      <MenuItem onClick={()=>{props?.updateUserStatus() 
        handleClose()
      }}>
        {props?.status === "Active"
          ? "Deactivate User"
          : "Activate User"}
      </MenuItem>
      <MenuItem onClick={handleClose}>Cancel</MenuItem>
      </Menu>
    </div>
  );
}
