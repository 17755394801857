import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Home from "../components/Home/Home";
import Login from "../components/Login/Login";
import LoginForm from "../components/Forms/LoginForm";
import ResetForm from "../components/Forms/NewPasswordForm";
import ExamBoard from "../components/ExamBoard/ExamBoard";
import Exam from "../components/Exam/Exam";
import AdminExam from "../components/Admin/AdminExam/AdminExam";
import AdminBoard from "../components/Admin/AdminBoard/AdminBoard";
import ExamSummaryMainCard from "../components/Admin/ExamSummaryMainCard/ExamSummaryMainCard";
import CreateExam from "../components/Admin/CreateExam/CreateExam";
import CreateNewUser from "../components/Admin/CreateNewUser/CreateNewUser";
import ListUsers from "../components/Admin/ListUsers/ListUsers";
import { useDispatch, useSelector } from "react-redux";
import { setRole } from "../store/actions";
import AdminUsers from "../components/Admin/AdminUsers/AdminUsers";
import CustomizedSnackBar from "../components/CustomizedSnackBar/CustomizedSnackBar";
// import NewPasswordForm from "../components/Forms/NewPasswordForm";
import ResetPasswordForm from "../components/Forms/ResetPasswordForm";
// import NewPasswordForm from '../components/Forms/NewPasswordForm'
import jwt_decode from "jwt-decode";
const Router = () => {
  const { loginRole, role } = useSelector((state) => ({
    loginRole: state.login.role,
    role: state.login.routerRole,
  }));
  const token = sessionStorage?.getItem("accessToken");
  const user = token && jwt_decode(token);

  const dispatch = useDispatch();
  let notLoggedIn = !role;
  useEffect(() => {
    dispatch(setRole(user?.role));
  }, [loginRole]);

  return (
    <div>
      {" "}
      <CustomizedSnackBar />
      <Switch>
        {/* / main route */}
        <Route path="/" exact>
          {    role?.toLowerCase() === "admin" ? (
            <Redirect to="/admin/home" />
          ) :     role?.toLowerCase() === "user" ? (
            <Redirect to="/home" />
          ) : (
            <Redirect to="/login" />
          )}
        </Route>

        {/* for user and admin */}
        {notLoggedIn ? (
          <>
            <Route path="/login" exact>
              <Login>
                <LoginForm />
              </Login>
            </Route>
            <Route path="/login/reset/:token">
              <Login>
                <ResetForm />
              </Login>
            </Route>
            <Route path="/login/forget">
              <Login>
                <ResetPasswordForm />
              </Login>
            </Route>
          </>
        ) : null}

        {/* user routes */}
        {role?.toLowerCase() === "user" ? (
          <>
            <Route path="/home" exact>
              <Home>
                <ExamBoard />
              </Home>
            </Route>
            <Route path="/home/exam/:id">
              <Home contained>
                <Exam />
              </Home>
            </Route>
          </>
        ) : null}

        {/* admin routes */}
        {role?.toLowerCase() === "admin" ? (
          <>
            <Route path="/admin/home">
              <Home>
                <AdminBoard />
              </Home>
            </Route>
            <Route path="/admin/exam/:id" exact>
              <Home>
                <ExamSummaryMainCard />
              </Home>
            </Route>
            <Route path="/admin/create">
              <Home>
                <CreateExam />
              </Home>
            </Route>
            <Route path="/admin/edit/:id">
              <Home>
                <CreateExam />
              </Home>
            </Route>
            <Route path="/admin/exam/:id/preview">
              <Home contained>
                <Exam />
              </Home>
            </Route>
            <Route path="/admin/users/create" exact>
              <Home>
                <CreateNewUser role="User" />
              </Home>
            </Route>
            <Route path="/admin/admins/create" exact>
              <Home>
                <CreateNewUser role="Admin" />
              </Home>
            </Route>
            <Route path="/admin/users/:id/edit" exact>
              <Home>
                <CreateNewUser role="User"/>
              </Home>
            </Route>
            <Route path="/admin/admins/:id/edit" exact>
              <Home>
                <CreateNewUser role="Admin" />
              </Home>
            </Route>
            <Route path="/admin/list-user" exact>
              <Home>
                <ListUsers />
              </Home>
            </Route>
            <Route path="/admin/list-admin" exact>
              <Home>
                <AdminUsers />
              </Home>
            </Route>
            <Route path="/admin/exam/:id/:userExamId/:participantId">
              <Home>
                <AdminExam />
              </Home>
            </Route>
            <Route path="/admin/profile-edit/:id">
              <Home>
              <CreateNewUser  role="Admin" />
              </Home>
            </Route>
          </>
        ) : null}
      </Switch>
    </div>
  );
};

export default Router;
