import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formLabel: {
    margin: "0.4375rem 0", //7px
    textTransform: "capitalize",
    display: "inline-block",
  },
  formInput: {
    padding: "17px 10px",
    width: "100%",
    borderRadius: "5px",
    border: `1px solid ${theme.palette.border}`,
    "&:focus, &:hover": {
      border: `1px solid ${theme.palette.primary.main}`,
      boxShadow: `0px 0px 3px ${theme.palette.shadow.main}`,
      outline: "none",
    },
    "&:hover": {
      boxShadow: "none",
    },
    "&:invalid": {
      border: `1px solid ${theme.palette.error.main}`,
    },
  },
  inputContainer: {
    margin: ".5rem 0px",
    position: "relative",
  },
}));

export default useStyles;
