import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  previewBox: {
    backgroundColor: "#FFF",
    maxWidth: "350px",
    width: "100%",
    minHeight: "270px",
    padding: "16px",
    borderRadius: "10px",
    marginLeft: "32px",
    top: "10px",
    scrollBehavior: "smooth",
    position: "sticky",
  },
  previewImgContainer: {
    display: "flex",
    borderBottom: "1px solid #E8E8E8",
    paddingBottom: "21px",
    alignItems: "center",
    margin: "21px 0",
  },
  previewImg: {
    marginRight: "21px",
    marginLeft: "16px",
    width: "95.49px",
    height: "61px",
    background: "#EDEDED",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  examTitle: {
    marginLeft: "21px",
    width: "478px",
    wordBreak: "break-word",

  },

  btnContainer: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #E8E8E8",
    paddingBottom: "21px",
  },
  previewBtn: {
    flex: 1,
    marginRight: "15px",
    "& span": {
      color: "#ACABAB",
    },
    "& span svg": {
      fill: "#ACABAB",
    },
  },
  publishBtn: {
    flex: 2,
    "& span svg": {
      fill: "#ACABAB",
    },
    "& span": {
      color: "#ACABAB",
      textTransform: "lowercase",
      "&::first-line": {
        textTransform: "capitalize",
      },
    },
  },
  activePreviewBtn: {
    borderColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
    "& span svg": {
      fill: theme.palette.primary.main,
    },
    "& span": {
      color: theme.palette.primary.main,
      textTransform: "lowercase",
      "&::first-line": {
        textTransform: "capitalize",
      },
    },
  },
  activePublishBtn: {
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
    },
    "& span svg": {
      fill: theme.palette.white.main,
    },
    "& span": {
      color: theme.palette.white.main,
    },
  },
  previewInfo: {
    display: "flex",
    maxWidth: "210px",
    width: "100%",
    justifyContent: "space-between",
  },
}));

export default useStyles;
