import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    justifyContent: "center",
    marginTop: "59px",
    alignItems: "center",
    minHeight: "272px",
    borderRadius: "20px",
    backgroundColor: theme.palette.border,
  },
  content: {
    marginBottom: "24px",
    padding: "0",
    width: "100%",
  },
  header: {},
}));

export default useStyles;
