import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  subtext: {
    marginBottom: "31px",
    color: theme.palette.neutral.dark,
  },
  userIcon: {
    fill: theme.palette.white.main,
  },
  title: {
    marginTop: "0",
    marginBottom: "3px",
    color: theme.palette.neutral.main,
    textTransform: "lowercase",
    "&::first-line": {
      textTransform: "capitalize",
    },
  },
  // link: { display: 'flex', padding: '28px 0px 17px 0px' },
  examLink: {
    background: "red",
  },
  container: {
    paddingBottom: "21px",
    marginTop: "17px",
    background: theme.palette.white.main,
    borderRadius: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    flexWrap: "wrap",
    position: "relative",
    maxWidth: "1110px",
    "&::after": {
      display: "block",
      content: '" "',
      position: "absolute",
      height: "2px",
      background: theme.palette.border,
      left: "22px",
      right: "22px",
      bottom: "0",
    },
  },
  createBtn: {
    width: "175px",
    textTransform: "unset",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    marginTop: "5px",
    borderRadius: "5px",
    "& span:first-child": {
      marginRight: "10px",
    },
  },
  disableEditBtn: {
    width: "175px",
    textTransform: "unset",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    marginTop: "5px",
    borderRadius: "5px",
    "& span:first-child": {
      marginRight: "10px",
    },
    "& span": {
      color: "#ACABAB",
    },
  },
  disableEditIcon: {
    filter:
      "invert(82%) sepia(3%) saturate(13%) hue-rotate(9deg) brightness(85%) contrast(85%)",
  },
  activeSessions: {
    color: theme.palette.secondary.main,
  },
  headerBtnContainer: {
    display: "flex",
    "& > * + *": {
      marginLeft: "20px",
    },
    padding: "30px 22px 0px 22px",
  },
  header: {
    padding: "30px 0px 0px 22px",
  },
  content: {
    maxWidth: "1110px",
    width: "100%",
    background: theme.palette.white.main,
    padding: "22px",
    // borderTop: '1px solid #E8E8E8',
  },
  emptyBox: {
    // background: theme.palette.primary.lighter,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px",
    height: "100px",
    "& button": {
      width: "225px",
    },
  },
}));

export default useStyles;