import API from './APIs'
import { mapToUserExam } from '../../helpers/mapper'
export const getUser = (userId) => {
  return API.get(`/users/${userId}`)
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      return Promise.reject(err)
    })
}

export const updateUser = (userId, userDetails) => {
  return API.put(`/users/${userId}`, userDetails)
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      return Promise.reject(err)
    })
}

export const getExam = (examId,isPreviewRoute) => {
  return API.get(`/exams/${examId}`)
    .then((res) => {
      return {
        id: res.data.id,
        title: res.data.title,
        status: res.data.status,
        timeTotal:isPreviewRoute? convertHMS(res.data.duration):convertHMSPreview(res.data.duration),
        //countedTime:res.data.duration*1000,
        description: res.data.description,
        timeRemain: convertHMS(res.data.duration),
        img: res.data.imageUrl,
        examUsers:res.data.user_exams.length
      }
    })
    .catch((err) => {
      return Promise.reject(err)
    })
}
function convertHMS(seconds) {
  let days = Math.floor(seconds / 86400)
  days = ('0' + days).slice(-2)
  let hours = Math.floor(seconds / 3600)
  hours = ('0' + hours).slice(-2)
  seconds %= 3600
  let minutes = Math.floor(seconds / 60)
  minutes = ('0' + minutes).slice(-2)
  seconds = seconds % 60
  seconds = ('0' + seconds).slice(-2)
  return {
    days: days,
    hours: hours - (days * 24),
    minutes: minutes,
    seconds: seconds,
  }
}
function convertHMSPreview(seconds) {
  let days = Math.floor(seconds / 86400)
  days = ('0' + days).slice(-2)
  let hours = Math.floor(seconds / 3600)
  hours = ('0' + hours).slice(-2)
  seconds %= 3600
  let minutes = Math.floor(seconds / 60)
  minutes = ('0' + minutes).slice(-2)
  seconds = seconds % 60
  seconds = ('0' + seconds).slice(-2)
  return {
    days: days,
    hours: hours ,
    minutes: minutes,
    seconds: seconds,
  }
}
export const getQuestions = (examId) => {
  return API.get(`/exams/${examId}/questions`)
    .then((res) => {
      // const transformed = res.data.Questions.map((q) =>
      //   q.description.includes('text') ? JSON.parse(q.description) : q,
      // )
      return res.data
    })
    .catch((err) => {
      return Promise.reject(err)
    })
}

export const getAnswers = (userId, examId) => {
  return API.get(`/users/${userId}/exams/${examId}/answers`)
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      return Promise.reject(err)
    })
}

export const getExamUsers = (filterData) => {
  return API.get(`/exams/exam/users?${mapToUserExam(filterData)}`)
    .then((res) => {
      return res?.data
    })
    .catch((err) => {
      return Promise.reject(err)
    })
}
