import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: "20px",
    width: "100%",
    margin: "48px 0px",
    boxShadow: theme.cardShadow,
    marginTop: "0",
    "& img + h3": {
      marginTop: "26px",
    },
    "& h3": {
      marginTop: "36px",
    },
  },
  examFigure: {
    maxWidth: "100%",
  },

  examHeader: {
    padding: "10px",
  },
  examHeaderTxt: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white.main,
    padding: "20px",
    borderRadius: "10px",
    "&.MuiTypography-h4":{
      margin:"0px"
    },
    textTransform: "lowercase",
    "&::first-line": {
      textTransform: "capitalize",
    },
  },
  question: {
    margin: 0,
  },
  content: {
    padding: "35px 30px",
  },
  examTitle: {
    margin: "20px 0 22px",
  },
  divider: {
    margin: "22px 0px 0px",
  },
  timerBox: {
    borderRadius: "20px",
    padding: "16px",
    width: "299px",
    minHeight: "450px",
    marginLeft: "20px",
    backgroundColor: theme.palette.white.main,
    boxShadow: theme.cardShadow,
    alignSelf: "flex-start",
  },
}));

export default useStyles;