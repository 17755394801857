import { makeStyles } from '@material-ui/core/styles';
import patternImg from '../../assets/pattern.jpg';

const useStyles = makeStyles((theme) => ({
  aside: {
    '&::before': {
      content: '" "',
      position: 'absolute',
      display: 'block',
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.info.main,
      mixBlendMode: 'multiply',
      opacity: 0.6,
    },
    backgroundImage: `url(${patternImg})`,
    backgroundSize: 'cover',
    height: '100vh',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      height: '45vh',
    },
  },
  formContainer: {
    maxWidth: '350px',
    width: '100%',
    '& label': {
      marginTop: '1.5rem',
    },
  },
  content: {
    position: 'relative',
    paddingBottom: '1rem',
  },
  logoBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    bottom: '30px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  logoText: {
    display: 'inline-block',
    width: '50%',
    maxWidth: '165px',
    color: theme.palette.text.secondary,
    marginLeft: '1rem',
    textAlign: 'left',
    wordBreak: 'break-word',
  },
  aspireLogo: {
    width: '169px',
    height: '194px',
    filter: 'brightness(0) invert(1)',
  },
  aspireFooter: { width: '33px', height: '42px' },
}));

export default useStyles;
