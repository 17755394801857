import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "100%",
    padding: "1.25rem",
    borderRadius: "20px",
    boxShadow: theme.cardShadow,
    display: "flex",
    margin: "1rem 0 .5rem",
    marginRight: "0.875rem",
    justifyContent: "space-between",
  },
  media: {
    height: 103,
    width: 157,
    borderRadius: "10px",
  },
  content: {
    display: "flex",

    alignItems: "center",
    "& h2": {
      marginRight: "1rem",
    },
  },
  header: {
    display: "flex",
    alignItems: "center",
  },
  time: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: ".5rem",
  },
  action: {
    display: "flex",
    flexDirection: "column",
    width: "237px",
  },
  cardItem: {
    marginButtom: "2rem",
  },
}));

export default useStyles;
