import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '22px',
    backgroundColor: theme.palette.white.main,
    maxWidth: '920px',
    width: '100%',
    maxHeight: '93%',
    margin: '74px auto',
    overflowY: 'auto',
    borderRadius: '10px',
    outline: 'none',
  },
  content: {
    paddingTop: '27px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  subtext: {
    color: theme.palette.neutral.dark,
  },
  title: {
    marginTop: '0',
    marginBottom: '3px',
    color: theme.palette.neutral.main,
  },
  header: {
    position: 'relative',
    paddingBottom: '27px',
    '&::after': {
      display: 'block',
      content: '" "',
      position: 'absolute',
      height: '2px',
      background: theme.palette.border,
      left: '0',
      right: '0',
      bottom: '0',
    },
  },
  list: {
    flex: '1',
    paddingTop: '0',
    maxHeight: '300px',
    height: '100%',
    overflow: 'auto',
    '& li': {
      padding: '0',
    },
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      backgroundColor: '#F4F5FF',
    },
  },
  box: {
    border: '1px solid #E8E8E8',
    maxHeight: '389px',
    height: '385px',
    borderRadius: '5px',
  },
  search: {
    width: '100%',
    '& input': {
      height: '38px',
      backgroundColor: 'transparent',
      borderRadius: '5px 5px 0 0',
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
    },
    '& label': {
      position: 'absolute',
      right: '10px',
      top: '5px',
    },
    '& div': {
      margin: '0',
    },
  },
  contentInner: {
    flex: '1',
    maxWidth: '403.99px',
  },
  createBtn: {
    width: '140px',
    textTransform: 'unset',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '5px',
    borderRadius: '5px',
  },
  uploadBtnTextEdit: {
    marginRight: '34px',
    '&:hover': {
      background: 'none',
    },
    '& span': {
      color: theme.palette.primary.main,
      textTransform: 'initial',
    },
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  div: {
    height: '2px',
    margin: '27px 0',
  },
  selectedItem: {
    fontWeight: '700',
    background: theme.palette.primary.lighter,
  },
  userBox: {
    height: '41px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  userBoxChecked: {
    backgroundColor: '#F4F5FF',
    height: '41px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: 'bold',
    borderBottom: '1px solid #E8E8E8',
    '& .makeStyles-checkboxStyle-65.MuiCheckbox-root': {
      backgroundColor: '#F4F5FF',
    },
  },
  userBoxUnchecked: {
    backgroundColor: 'transparent',
    height: '41px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
  },
  userName: {
    textTransform: 'capitalize',
    fontFamily: ' Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#333333',
  },
  userNameChecked: {
    textTransform: 'capitalize',
    fontFamily: ' Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#333333',
  },
  checkboxStyle: {
    '&.MuiCheckbox-root': {
      color: '#ACABAB',
    },
  },
  checkboxIcon: {
    height: '16px',
  },
}))

export default useStyles
