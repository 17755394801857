import { EditorState, convertFromRaw } from 'draft-js'
import { Box, Paper, Typography, Divider } from '@material-ui/core'
import useStyles from './styles'
import Figure from '../../Figure/Figure'
import { useSelector } from 'react-redux'
import List from '../../List/List'
import AnswerBox from '../../AnswerBox/AnswerBox'
import { useParams } from 'react-router-dom'
import { Editor as QuestionEditor } from 'react-draft-wysiwyg'
import { useState } from 'react'

const Answer = ({ questionItem, i, exam, time, countedTime }) => {
  const classes = useStyles()
  const { id } = useParams()
  const [answerCheck, setAnswerTxt] = useState({
    answerText: null,
    answerTouched: false,
  })
  const description = {
    blocks: [
      {
        key: '6rf7g',
        text: '',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
    ],
    entityMap: {},
  }
  const { examAnswers } = useSelector(({ examDetail }) => ({
    examAnswers: examDetail.examAnswers,
  }))
  const findedAnswer = examAnswers.find(
    (e) => e.Question.id === questionItem?.id,
  )
  return (
    <Paper
      key={questionItem?.id}
      className={classes.container}
      id={questionItem?.id}
    >
      <Box className={classes.examHeader}>
        <Typography className={classes.examHeaderTxt} variant="h4">
          {`Question ${i+1} -  ${questionItem?.title}`}
        </Typography>
      </Box>
      <Box className={classes.content}>
        <QuestionEditor
          toolbarHidden
          editorState={
            EditorState.createWithContent(
              convertFromRaw(JSON.parse(questionItem?.description)),
            ) || description
          }
        />
        {questionItem?.content ? (
          <Typography variant="h5">{questionItem?.content}</Typography>
        ) : null}
        {questionItem?.figure ? (
          <Figure
            className={classes.examFigure}
            img={questionItem.figure?.img}
          />
        ) : null}
        {questionItem?.list ? (
          <List
            data={questionItem?.list?.items}
            orderd={questionItem?.list?.orderd}
            title={questionItem?.list?.title}
          />
        ) : null}
        <Divider className={classes.divider} />
        <AnswerBox
          questionItem={questionItem}
          answerkey={questionItem?.id}
          questionNumber={i}
          examId={id}
          questionId={questionItem?.id}
          submitedAnswer={questionItem?.submitedAnswer}
          answer={findedAnswer?.Answer?.description}
          answerdTcheck={findedAnswer?.Question?.id}
          status={exam?.status}
          placeholderTxt="Type your answer here"
          time={time}
          countedTime={countedTime}
          setAnswerTxt={setAnswerTxt}
          answerCheck={answerCheck}
        />

        {/* ) : null} */}
      </Box>
    </Paper>
  )
}

export default Answer
