import React from 'react';
import { Button, Modal, Box, Typography } from '@material-ui/core';
import useStyles from './submtionStyle';
import ButtonSecondary from '../Button/ButtonSecondary/ButtonSecondary';
import whiteArrowIcon from '../../assets/whiteArrowIcon.svg';
import { Link } from 'react-router-dom';
import beCareFul from '../../assets/becareful.svg';
const SubmittionModal = ({ open, handleClose, submitHandler }) => {
  const classes = useStyles();

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={classes.container}>
          <h2
            classes={classes.thank}
            style={{ textAlign: 'center', margin: '10px 0' }}
          >
            Thank you for performing your exam !
          </h2>
          <h3
            style={{ margin: 0, textAlign: 'center' }}
            classes={classes.thank}
          >
            We will contact you soon{' '}
          </h3>

          <p className={classes.headerMessage}>
            Are you sure that you want to submit your exam?
          </p>
          <img className={classes.beCareFul} src={beCareFul} alt="beCareful" />
          <Box className={classes.buttonContainer}>
            <ButtonSecondary
              onClick={handleClose}
              className={classes.cancel}
              noicon="true"
              variant="outlined"
              color="primary"
            >
              Cancel
            </ButtonSecondary>
            <Link className={classes.link} to={'/Home'}>
              <Button
                className={classes.startButton}
                variant="contained"
                color="secondary"
                onClick={submitHandler}
              >
                <Typography component={'span'} className={classes.startText}>
                  Submit
                </Typography>
                <img
                  src={whiteArrowIcon}
                  alt=""
                  className={classes.startIcon}
                />
              </Button>
            </Link>
          </Box>
        </div>
      </Modal>
    </>
  );
};

export default SubmittionModal;
