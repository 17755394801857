export const FETCH_EXAM_START = 'FETCH_EXAM_START'
export const FETCH_EXAM_SUCCESS = 'FETCH_EXAM_SUCCESS'
export const FETCH_EXAM_FAIL = 'FETCH_EXAM_FAIL'

export const START_USER_EXAM = 'START_USER_EXAM'
export const START_USER_EXAM_SUCCESS = 'START_USER_EXAM_SUCCESS'
export const START_USER_EXAM_FAILURE = 'START_USER_EXAM_FAILURE'
export const END_USER_EXAM_SUCCESS = 'END_USER_EXAM_SUCCESS'
export const END_USER_EXAM_FAILURE = 'END_USER_EXAM_FAILURE'

export const SUBMIT_USER_QUESTION = 'SUBMIT_USER_QUESTION'
export const SUBMIT_USER_QUESTION_SUCCESS = 'SUBMIT_USER_QUESTION_SUCCESS'
export const SUBMIT_USER_QUESTION_FAILURE = 'SUBMIT_USER_QUESTION_FAILURE'

export const UPDATE_USER_QUESTION = 'UPDATE_USER_QUESTION'
export const UPDATE_USER_QUESTION_SUCCESS = 'UPDATE_USER_QUESTION_SUCCESS'
export const UPDATE_USER_QUESTION_FAILURE = 'UPDATE_USER_QUESTION_FAILURE'

export const UPDATE_EXAM_DURATION = 'UPDATE_EXAM_DURATION'

export const STORE_QUESTION = 'STORE_QUESTION'
export const STORE_ANSWER = 'STORE_ANSWER'
export const REMAINING_TIME = 'REMAINING_TIME'

export const FETCH_REMAINING_TIME = 'FETCH_REMAINING_TIME'
export const FETCH_REMAINING_TIME_SUCCESS = 'FETCH_REMAINING_TIME_SUCCESS'
export const FETCH_REMAINING_TIME_FAIL = 'FETCH_REMAINING_TIME_FAIL'

export const GET_PAGE_SUCCESS = 'GET_PAGE_SUCCESS'

export const SET_TIME_STATE = 'SET_TIME_STATE'

export const SAVE_ANSWER = 'SAVE_ANSWER'

export const SAVE_CONDITION_VALUES = 'SAVE_CONDITION_VALUES'

export const LIST_GRID_VIEW = 'LIST_GRID_VIEW'
