import React from "react";

const PaperClipIcon = () => {
  return (
    <svg
      width="23.14px"
      height="23.96px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.95 9.60019C22.7625 9.41271 22.5082 9.3074 22.243 9.3074C21.9779 9.3074 21.7236 9.41271 21.536 9.60019L10.644 20.5392C10.1797 21.0035 9.62853 21.3719 9.02188 21.6232C8.41524 21.8746 7.76503 22.0039 7.10838 22.004C5.78221 22.0041 4.51033 21.4774 3.57252 20.5397C2.63472 19.602 2.10781 18.3302 2.10772 17.004C2.10762 15.6779 2.63435 14.406 3.57202 13.4682L14.121 2.87619C14.6851 2.32108 15.4457 2.01136 16.2371 2.01448C17.0285 2.01761 17.7866 2.33334 18.3463 2.89289C18.906 3.45244 19.2219 4.21048 19.2252 5.00189C19.2285 5.7933 18.919 6.55397 18.364 7.11819L7.81502 17.7102C7.62477 17.8924 7.37149 17.9942 7.10802 17.9942C6.84456 17.9942 6.59127 17.8924 6.40102 17.7102C6.21355 17.5227 6.10824 17.2683 6.10824 17.0032C6.10824 16.738 6.21355 16.4837 6.40102 16.2962L15.793 6.86119C15.9752 6.67258 16.076 6.41998 16.0737 6.15778C16.0714 5.89559 15.9662 5.64477 15.7808 5.45937C15.5954 5.27396 15.3446 5.16879 15.0824 5.16651C14.8202 5.16423 14.5676 5.26503 14.379 5.44719L4.98702 14.8822C4.70839 15.1608 4.48737 15.4915 4.33657 15.8555C4.18578 16.2195 4.10816 16.6097 4.10816 17.0037C4.10816 17.3977 4.18578 17.7878 4.33657 18.1519C4.48737 18.5159 4.70839 18.8466 4.98702 19.1252C5.55864 19.6707 6.3184 19.975 7.10852 19.975C7.89864 19.975 8.6584 19.6707 9.23002 19.1252L19.778 8.53218C20.696 7.59049 21.206 6.32504 21.1976 5.00996C21.1892 3.69488 20.663 2.43606 19.733 1.50621C18.803 0.576353 17.5441 0.0503304 16.229 0.0420905C14.914 0.0338505 13.6486 0.544056 12.707 1.46219L2.15802 12.0542C0.845201 13.367 0.107666 15.1476 0.107666 17.0042C0.107666 18.8608 0.845201 20.6414 2.15802 21.9542C3.47084 23.267 5.25141 24.0045 7.10802 24.0045C8.96463 24.0045 10.7452 23.267 12.058 21.9542L22.95 11.0182C23.0435 10.9253 23.1176 10.8148 23.1682 10.6931C23.2188 10.5714 23.2448 10.441 23.2448 10.3092C23.2448 10.1774 23.2188 10.0469 23.1682 9.92526C23.1176 9.80359 23.0435 9.69311 22.95 9.60019Z"
        fill="#333333"
      />
    </svg>
  );
};

export default PaperClipIcon;
