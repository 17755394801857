import Card from "../Card/Card";
import CardListItem from "../CardListItem/CardListItem";
import { colors, btn } from "../../helpers/objects";
import { Typography, Button } from "@material-ui/core";
import useStyles from "./styles";
import { userFromToken, startUserExam } from "../../store/actions";
import whiteArrowIcon from "../../assets/whiteArrowIcon.svg";
import blueArrowIcon from "../../assets/blueArrowIcon.svg";
import ExamDuration from "../ExamsDuration/ExamDuration";
import { useDispatch } from "react-redux";
import { UPDATE_STATUS } from "../../store/types/types";
import { useHistory } from "react-router";

const RenderdExam = ({examKey,exam,gridView, modalConfig,handleModal, setModalConfig, loading}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userId = userFromToken().id;
  const classes = useStyles();

  const buttonHandler = ({ exam, time }) => {
    setModalConfig({
      ...modalConfig,
      contentText: time,
      onAccept: () => {
        if (exam?.status === "active") {
          dispatch({ type: UPDATE_STATUS,
            value: { id: exam?.id, status: "in progress" },
          });
          startNowHandler({ exam });
        }
      },
      subTitle: exam?.title,
      onAcceptBtnTxt: "Start now",
    });
    btn[exam?.status]?.txt === "Take the exam"
      ? handleModal()
      : btn[exam?.status]?.txt === "Continue"
      ? history.push({
          pathname: `/home/exam/${exam?.id}`})
      : history.push(`/home/exam/${exam?.id}`);
  };
  const startNowHandler = ({ exam }) => {
    dispatch(startUserExam(userId, exam.id, history));
  };
  const timeInHour = exam?.timeTotal?.timeInHours > 9 ? `${exam?.timeTotal?.timeInHours }` : `0${exam?.timeTotal?.timeInHours }`
  const timeInMinutes = exam?.timeTotal?.timeInMinutes > 9 ? `${exam?.timeTotal?.timeInMinutes}` : `0${exam?.timeTotal?.timeInMinutes}`
  const time = `${timeInHour}h:${timeInMinutes}m:00s` 
  const textStyle = btn[exam?.status]?.txt === "Take the exam" ? classes.tTE : classes.CF;
  return gridView === "grid" ? (
    <Card
      key={examKey}
      status={exam?.status}
      title={exam?.title}
      timeTotal={time}
      timeRemain={ exam?.status === "in progress" && exam?.timeRemain
          ? !loading && <ExamDuration countedTime={exam?.timeRemain} id={exam?.id} />: !exam?.timeRemain ? "N/A": time}
      color={colors[exam?.status]}
      img={exam?.img}>
      <Button
        disabled={btn[exam?.status]?.txt === "Finished" || !exam?.timeRemain}
        className={classes.examsButton} variant={btn[exam?.status]?.style}
        color={btn[exam?.status]?.color} onClick={() => {buttonHandler({ exam, time });}} >
        <Typography component={"span"} className={textStyle}>
          {!exam?.timeRemain ? "Finished" : btn[exam?.status]?.txt}
        </Typography>
        {btn[exam?.status]?.txt === "Take the exam" && (
          <img src={whiteArrowIcon} className={classes.icon} alt="" />
        )}
        {btn[exam?.status]?.txt !== "Take the exam" && (
          <img src={blueArrowIcon} className={classes.icon} alt="" />
        )}
      </Button>
    </Card>
  ) : (
    <CardListItem
      key={examKey}
      status={exam?.status}
      title={exam?.title}
      timeTotal={time}
      timeRemain={exam?.status === "in progress" && exam?.timeRemain ? (
        !loading && <ExamDuration countedTime={exam?.timeRemain} id={exam?.id} />) : !exam?.timeRemain ? ( "N/A") : ( time)}
      color={colors[exam?.status]} img={exam?.img}>
      <Button
        disabled={btn[exam?.status]?.txt === "Finished" || !exam?.timeRemain}
        className={classes.examsButton}
        variant={btn[exam?.status]?.style}
        color={btn[exam?.status]?.color}
        onClick={() => { buttonHandler({ exam, time });}}>
        <Typography component={"span"} className={textStyle}>
          {!exam?.timeRemain ? "Finished" : btn[exam?.status]?.txt}
        </Typography>
        {btn[exam?.status]?.txt === "Take the exam" && (
          <img src={whiteArrowIcon} className={classes.icon} alt="" />
        )}
        {btn[exam?.status]?.txt !== "Take the exam" && (
          <img src={blueArrowIcon} className={classes.icon} alt="" />
        )}
      </Button>
    </CardListItem>
  );
};

export default RenderdExam;
