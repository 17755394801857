import React from "react";

const WarningIcon = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M12.5 24.5C10.1266 24.5 7.80655 23.7962 5.83316 22.4776C3.85977 21.1591 2.3217 19.2849 1.41345 17.0922C0.505199 14.8995 0.267559 12.4867 0.730582 10.1589C1.1936 7.83115 2.33649 5.69295 4.01472 4.01472C5.69295 2.33649 7.83115 1.1936 10.1589 0.730582C12.4867 0.267559 14.8995 0.505199 17.0922 1.41345C19.2849 2.3217 21.1591 3.85977 22.4776 5.83316C23.7962 7.80655 24.5 10.1266 24.5 12.5C24.4966 15.6815 23.2312 18.7318 20.9815 20.9815C18.7318 23.2312 15.6815 24.4966 12.5 24.5ZM12.5 2.50001C10.5222 2.50001 8.58879 3.0865 6.9443 4.18531C5.29981 5.28412 4.01809 6.84591 3.26121 8.67317C2.50433 10.5004 2.3063 12.5111 2.69215 14.4509C3.078 16.3907 4.03041 18.1725 5.42894 19.5711C6.82746 20.9696 8.60929 21.922 10.5491 22.3079C12.4889 22.6937 14.4996 22.4957 16.3268 21.7388C18.1541 20.9819 19.7159 19.7002 20.8147 18.0557C21.9135 16.4112 22.5 14.4778 22.5 12.5C22.4971 9.84873 21.4426 7.30689 19.5679 5.43215C17.6931 3.55742 15.1513 2.50292 12.5 2.50001Z"
          fill="#FD3D39"
        />
        <path d="M13.5 5.5H11.5V15.5H13.5V5.5Z" fill="#FD3D39" />
        <path d="M13.5 17.5H11.5V19.5H13.5V17.5Z" fill="#FD3D39" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.5 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WarningIcon;
