import React from "react";
import { withRouter } from "react-router-dom";
import Link from "../../Link/Link";
import {
  Box,
  Typography,
  ListItem,
  ListItemText,
  ListItemIcon,
  List,
} from "@material-ui/core";
import { ArrowLeftIcon, ArrowRightIcon, Person } from "../../Icons";
import Pagination from "@material-ui/lab/Pagination";
import useStyles from "./styles";
import { useSelector } from "react-redux";
const ExamInfoBar = ({
  userList,
  userExamId,
  examId,
  fetchUserExamAnswerChangeHandler,
  pages,
  page,
  count,
  waitingAssessmentUser,
  pageChangeHandler,
  participantId,
  assessmentParticipantInfo,
}) => {
  const classes = useStyles();
  const { examScore } = useSelector(({ exams }) => ({
    examScore: exams?.examScore,
  }));
  return (
    <Box className={classes.infoBar}>
      <Box className={classes.infoBarHeader}>
        <Link
          text="Back to Exam page"
          to={`/admin/exam/${examId}`}
          startIcon={<ArrowLeftIcon className={classes.backIcon} />}
        />
        <Person className={classes.profileIcon} />
        <Box>
          <Typography className={classes.participantName} variant="body2">
            {`${assessmentParticipantInfo?.firstName} ${assessmentParticipantInfo?.lastName}`}
          </Typography>
          <Link
            align={"center"}
            text="View Profile"
            to={`/admin/users/${participantId}/edit`}
          />
          <Typography className={classes.scoreInfo} variant="body2">
            Total score: {examScore?.score || "N/A"}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.infoBarBody}>
        <Typography variant="h5">
          To be assessd (
          {waitingAssessmentUser ? waitingAssessmentUser.length : 0})
        </Typography>
        {/* list */}
        <List component="nav" aria-label="main mailbox folders">
          {waitingAssessmentUser?.map((item, index) => {
            return (
              <ListItem
                key={item?.id}
                disableGutters
                button
                selected={item?.User?.id === participantId}
                onClick={() => {
                  fetchUserExamAnswerChangeHandler(item?.User?.id);
                }}
              >
                <ListItemText
                  primary={`${item?.User?.first_name} ${item?.User?.last_name}`}
                />
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
              </ListItem>
            );
          })}
        </List>

        <Pagination
          className={classes.pagination}
          hideNextButton={true}
          hidePrevButton={true}
          count={pages}
          page={page}
          onChange={pageChangeHandler}
        />
      </Box>
    </Box>
  );
};

export default withRouter(ExamInfoBar);
