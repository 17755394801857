import {
  FETCH_ADMIN_EXAM,
  FETCH_ADMIN_EXAM_SUCCESS,
  FETCH_ADMIN_EXAM_FAIL,
  CREATE_EXAM,
  CREATE_EXAM_SUCCESS,
  CREATE_EXAM_FAILURE,
  UPDATE_EXAM,
  UPDATE_EXAM_SUCCESS,
  UPDATE_EXAM_FAILURE,
  ADD_QUESTIONS,
  ADD_QUESTIONS_SUCCESS,
  ADD_QUESTIONS_FAILURE,
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  ASSIGN_USERS,
  ASSIGN_USERS_SUCCESS,
  ASSIGN_USERS_FAILURE,
  FETCH_EXAM_USERS,
  FETCH_EXAM_USERS_SUCCESS,
  FETCH_EXAM_USERS_FAILURE,
  SET_SCORE,
  SET_SCORE_SUCCESS,
  SET_SCORE_FAILURE,
  FETCH_USERS,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  SET_OPEN_MODAL,
  GET_FILTERED_EXAM_USERS,
  UPDATE_USER_EXAM_TOTAL_SCORE,
  UPDATE_USER_EXAM_TOTAL_SCORE_SUCCESS,
  UPDATE_USER_EXAM_TOTAL_SCORE_FAILURE,
  UPDATE_EXAM_INFO_FIELD,
  SET_SELECTED_USERS,
  SET_EXAM_USER_CHECK,
  RESET_SELECTED_USERS,
  SET_LOADING_BOOL,
  SET_EXAM_SCORE,
  SET_EXAM_QUESTION_RATE,
  FETCH_USER_EXAM_SCORE,
  FETCH_USER_EXAM_SCORE_SUCCESS,
  FETCH_USER_EXAM_SCORE_FAILURE,
  CLEAR_FILTERED_DATA,
  CLEAR_ORDER_BY_DATA,
  RESEL_LINK,
  SET_FILTERED,
  SET_COLUMN_NUMBER,
  SET_DATE_RANGE,
  CLEAR_FILTER_BY_DATE_RANGE,
  SET_FILTERED_BY_DATE_RANGE,
  SET_ENABLE_EXAM_EDIT,
  SET_EDITOR_STATE,
  SET_USER_DATA,
  CLEAR_USER_DATA,
  FETCH_WAITING_ASSESSMENT_USERS,
  FETCH_WAITING_ASSESSMENT_USERS_SUCCESS,
  FETCH_WAITING_ASSESSMENT_USERS_FAILURE,
  FETCH_USERS_ADD_SUCCESS
} from "../types";

import {
  adminExams,
  createExam,
  updateExam,
  addQuestion,
  createUser,
  deleteUser,
  assignUsers,
  examUsers,
  setScore,
  getUsers,
  updateUserExamScore,
  fetchUserExamScore,
  getAdmins,
  selectedUsers,
} from "../services";
import {
  fetchAllExamUsers,
  fetchExamAnswers,
  snackbarAction,
  fetchExamById
} from "./CommonActions";

// We need fetch all users service / action
export const updateExamInfoFeild = (name, value) => async (dispatch) => {
  dispatch({ type: UPDATE_EXAM_INFO_FIELD, payload: { name, value } });
};

export const updateExamInformation =
  (id, exam, page, size, history) => (dispatch) => {
    dispatch({
      type: UPDATE_EXAM,
    });
    updateExam(id, exam)
      .then((data) => {
        dispatch({
          type: UPDATE_EXAM_SUCCESS,
          value: data,
        });
        dispatch(fetchAllExamUsers(page, size));
        history && history.push(`/admin/exam/${id}`);
      history &&
        dispatch(snackbarAction(true, "success", "Updated successfully"));
      })
      .catch((err) => {
        dispatch(snackbarAction(true, "error", "Updated failed"));
        dispatch({
          type: UPDATE_EXAM_FAILURE,
          err: err,
        });
      });
  };

export const resetSelectedUsers = () => (dispatch) => {
  dispatch({ type: RESET_SELECTED_USERS });
};

export const fetchAdminExams = () => (dispatch) => {
  dispatch({
    type: FETCH_ADMIN_EXAM,
  });
  adminExams()
    .then((data) => {
      dispatch({
        type: FETCH_ADMIN_EXAM_SUCCESS,
        value: data,
      });
    })
    .catch((err) => {
      dispatch({
        type: FETCH_ADMIN_EXAM_FAIL,
        err: err,
      });
    });
};

export const createExamAction =
  (examDetails, page, size, history) => async (dispatch) => {
    try {
      dispatch({
        type: CREATE_EXAM,
      });
      const data = await createExam(examDetails);
      dispatch({
        type: CREATE_EXAM_SUCCESS,
        value: data,
      });
      dispatch(fetchAllExamUsers(page, size));
      history.push("/admin/home");
      dispatch(snackbarAction(true, "success", "Create exam successfully"));
    } catch (err) {
      dispatch({
        type: CREATE_EXAM_FAILURE,
        err: err,
      });
      dispatch(snackbarAction(true, "error", "Create exam failed"));
    }
  };

export const setOpenModal = (open) => async (dispatch) => {
  dispatch({
    type: SET_OPEN_MODAL,
    value: open,
  });
};

export const setDateRange = (key, val) => (dispatch) => {
   dispatch({ type: SET_DATE_RANGE, value: { key, val } });
};

export const clearFilterbyDateRange = () => (dispatch) => {
  dispatch({ type: CLEAR_FILTER_BY_DATE_RANGE });
};

export const addExamQuestions = () => (dispatch) => {
  dispatch({
    type: ADD_QUESTIONS,
  });
  addQuestion()
    .then((data) => {
      dispatch({
        type: ADD_QUESTIONS_SUCCESS,
        value: data,
      });
    })
    .catch((err) => {
      dispatch({
        type: ADD_QUESTIONS_FAILURE,
        err: err,
      });
    });
};

export const createUserAction = (userDetails) => async (dispatch) => {
  dispatch({
    type: CREATE_USER,
  });
  createUser(userDetails)
    .then((data) => {
      dispatch({
        type: CREATE_USER_SUCCESS,
        value: data,
      });
      // dispatch(fetchUsers(page, size, ''))
      dispatch(snackbarAction(true, "success", "User is created successfully"));
    })
    .catch((err) => {
      dispatch(snackbarAction(true, "error", "User created failed"));
      dispatch({
        type: CREATE_USER_FAILURE,
        err: err,
      });
    });
};

export const deleteUserAction = (userId,filterData,role) => (dispatch) => {
  dispatch({
    type: DELETE_USER,
  });
  deleteUser(userId)
    .then((data) => {
      dispatch({
        type: DELETE_USER_SUCCESS,
        value: data,
      });
      dispatch(fetchUsers(filterData,role))
    })
    .catch((err) => {
      dispatch({
        type: DELETE_USER_FAILURE,
        err: err,
      });
    });
};



export const assignUsersToExam =
  (examId, assignedUsers, filterData) => (dispatch) => {
    dispatch({
      type: ASSIGN_USERS,
    });
    assignUsers(examId, assignedUsers)
      .then((data) => {
        dispatch({
          type: ASSIGN_USERS_SUCCESS,
          value: data,
        });
        dispatch(setOpenModal(false));
        dispatch(fetchExamUsers(examId, filterData));
        dispatch(snackbarAction(true, "success", "Assigned successfully"));
        dispatch(fetchExamById(examId));

      })
      .catch((err) => {
        dispatch(snackbarAction(true, "error", "Assigned failed"));
        dispatch({
          type: ASSIGN_USERS_FAILURE,
          err: err,
        });
      });
  };

export const setSelectedExamUsers = (data, check) => async (dispatch) => {
  dispatch({ type: SET_SELECTED_USERS, value: data, check });
};

export const setChecked = (checked) => async (dispatch) => {
  dispatch({ type: SET_EXAM_USER_CHECK, value: checked });
};

export const setExamScore = (score) => async (dispatch) => {
  dispatch({ type: SET_EXAM_SCORE, value: score });
};

export const setExamQuestionRating = (rating) => async (dispatch) => {
  dispatch({ type: SET_EXAM_QUESTION_RATE, value: rating });
};

export const fetchExamUsers = (examId, filterData) => (dispatch) => {
  dispatch({
    type: FETCH_EXAM_USERS,
  });
  examUsers(examId, filterData)
    .then((data) => {
      dispatch({
        type: FETCH_EXAM_USERS_SUCCESS,
        value: data,
      });
    })
    .catch((err) => {
      dispatch({
        type: FETCH_EXAM_USERS_FAILURE,
        err: err,
      });
    });
};

export const fetchSelectedUser = (examId) => (dispatch) => {
  dispatch({
    type: FETCH_EXAM_USERS,
  });
  selectedUsers(examId)
    .then((data) => {
      dispatch({
        type: SET_SELECTED_USERS,
        value: data,
      });
    })
    .catch((err) => {
      dispatch({
        type: FETCH_EXAM_USERS_FAILURE,
        err: err,
      });
    });
};

export const setQuestionScore =
  (userId, examId, questionId, score) => (dispatch) => {
    dispatch({
      type: SET_SCORE,
    });
    setScore(userId, examId, questionId, score)
      .then((data) => {
        dispatch({
          type: SET_SCORE_SUCCESS,
          value: data,
        });
        dispatch(fetchExamAnswers(userId, examId));
      })
      .catch((err) => {
        dispatch({
          type: SET_SCORE_FAILURE,
          err: err,
        });
      });
  };

export const fetchUsers = (filterData, role, type = 'new') => (dispatch) => {
  dispatch({
    type: FETCH_USERS,
  });
    getUsers(filterData, role)
    .then((data) => {
      if (type === 'add') {
        dispatch({
          type: FETCH_USERS_ADD_SUCCESS,
          value: data,
        });
      } else {
        dispatch({
          type: FETCH_USERS_SUCCESS,
          value: data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: FETCH_USERS_FAILURE,
        err: err,
      });
    });
};

export const fetchAdmins =
  (page, size, searchTerm, order = "ASC", columnName = "firstName", role) =>
    (dispatch) => {
      dispatch({
        type: FETCH_USERS,
      });
      getAdmins(page, size, searchTerm, order, columnName, role)
        .then((data) => {
          dispatch({
            type: FETCH_USERS_SUCCESS,
            value: data,
          });
        })
        .catch((err) => {
          dispatch({
            type: FETCH_USERS_FAILURE,
            err: err,
          });
        });
    };
export const setFilteredData = (key, val) => async (dispatch) => {
  dispatch({ type: GET_FILTERED_EXAM_USERS, value: { key, val } });
};

export const clearFilteredData = () => async (dispatch) => {
  dispatch({ type: CLEAR_FILTERED_DATA });
};

export const setColNum = (col) => async (dispatch) => {
  dispatch({ type: SET_COLUMN_NUMBER, value: col });
};

export const updateUserExamTotalScore =
  (examId, userId, totalScore,filterData) => (dispatch) => {
    dispatch({
      type: UPDATE_USER_EXAM_TOTAL_SCORE,
    });
    updateUserExamScore(examId, userId, totalScore)
      .then((data) => {
        dispatch({
          type: UPDATE_USER_EXAM_TOTAL_SCORE_SUCCESS,
          value: data,
        });
        dispatch(fetchExamUsers(examId,filterData));
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_USER_EXAM_TOTAL_SCORE_FAILURE,
          err: err,
        });
      });
  };

export const fetchUserExamTotalScore =
  (examId, userId, filterData) => (dispatch) => {
    dispatch({
      type: FETCH_USER_EXAM_SCORE,
    });
    fetchUserExamScore(examId, userId)
      .then((data) => {
        dispatch({
          type: FETCH_USER_EXAM_SCORE_SUCCESS,
          value: data,
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_USER_EXAM_SCORE_FAILURE,
          err: err,
        });
      });
  };

export const clearOrderedByDataAction = () => (dispatch) => {
  dispatch({ type: CLEAR_ORDER_BY_DATA });
};
export const resetLink = () => async (dispatch) => {
  dispatch({ type: RESEL_LINK });
};

export const setFiltered = (bool) => async (dispatch) => {
  dispatch({ type: SET_FILTERED, value: bool });
};

export const setFilteredByDateRange = (bool) => async (dispatch) => {
  dispatch({ type: SET_FILTERED_BY_DATE_RANGE, value: bool });
};

export const setEnableExamEdit = (arr) => async (dispatch) => {
  dispatch({ type: SET_ENABLE_EXAM_EDIT, value: arr });
};

export const setEditorStateAction = (editorState) => async (dispatch) => {
  dispatch({ type: SET_EDITOR_STATE, value: editorState });
};


export const setUserDataAction = (data) => async (dispatch) => {
  dispatch({ type: SET_USER_DATA, payload: {...data} });
};

export const clearUserDataAction = () => async (dispatch) => {
  dispatch({ type: CLEAR_USER_DATA });
};


export const fetchWaitingAssessmentExamUsersAction = (examId, filterData) => (dispatch) => {
  dispatch({
    type: FETCH_WAITING_ASSESSMENT_USERS,
  });
  examUsers(examId, filterData)
    .then((data) => {
      dispatch({
        type: FETCH_WAITING_ASSESSMENT_USERS_SUCCESS,
        value: data,
      });
    })
    .catch((err) => {
      dispatch({
        type: FETCH_WAITING_ASSESSMENT_USERS_FAILURE,
        err: err,
      });
    });
};