import React from 'react'
import useStyles from './styles'
import jwt_decode from "jwt-decode";
import { Box, Typography } from '@material-ui/core'
const TimerPlaceHolder = ({ duration }) => {
  const classes = useStyles()
  const token = sessionStorage?.getItem("accessToken");
  const { role } = token && jwt_decode(token);
  return (
    <Box className={classes.counterContainer}>
      <Typography className={classes.TimerCaption} variant="caption">
        Time remaining
      </Typography>
      <Box className={classes.timer} variant="caption">
        <Box className={classes.timeCell}>
          <Typography className={classes.timeDigit} variant="h1">
            {role.toLowerCase() === 'user' ? '00' : duration?.hours}
          </Typography>
          <Typography className={classes.subText} variant="subtitle2">
            hours
          </Typography>
        </Box>
        <Typography className={classes.seperator} variant="h3">
          :
        </Typography>
        <Box className={classes.timeCell}>
          <Typography className={classes.timeDigit} variant="h1">
            {role.toLowerCase() === 'user' ? '00' : duration?.minutes}
          </Typography>
          <Typography className={classes.subText} variant="subtitle2">
            minutes
          </Typography>
        </Box>
        <Typography className={classes.seperator} variant="h3">
          :
        </Typography>
        <Box className={classes.timeCell}>
          <Typography className={classes.timeDigit} variant="h1">
            {role.toLowerCase() === 'user' ? '00' : duration?.seconds}
          </Typography>
          <Typography className={classes.subText} variant="subtitle2">
            seconds
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default TimerPlaceHolder
