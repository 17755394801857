import React, { useEffect, useCallback, Fragment, useState } from "react";
import { Modal as MuiModal } from "@material-ui/core";
import Input from "../../Input/Input";
import Button from "../../Button/Button";
import { SearchIcon } from "../../Icons";
import {
  Box,
  Typography,
  List,
  ListItem,
  Divider,
  Checkbox,
} from "@material-ui/core";
import useStyles from "./styles";
import { useSelector, useDispatch } from "react-redux";
import {
  assignUsersToExam,
  fetchUsers,
  fetchSelectedUser,
  resetSelectedUsers,
  clearFilteredData,
} from "../../../store/actions";
import { useParams } from "react-router-dom";
import checkbox from "../../../assets/checkbox.svg";
import checkboxIcon from "../../../assets/checkboxIcon.svg";
import debounce from "lodash.debounce";

const ParticipantsModal = ({ handleClose, open, userExamList }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const classes = useStyles();

  const { users, filterData, selectedUsers, examInfo, page } = useSelector(
    ({ exams, examDetail }) => ({
      users: exams?.users,
      filterData: exams.filterData,
      selectedUsers: exams?.selectedUsers,
      examInfo: examDetail?.examInfo,
      page: exams.page,
      size: exams.size,
    })
  );
  const [usersToAdd, setUsersToAdd] = useState([]);
  const [usersToRemove, setUsersToRemove] = useState([]);
  const [selectedUserHype, setSelectedUserHype] = useState(selectedUsers);
  const [searchText, setSearchText] = useState("");
  const [usersPage, setUsersPage] = useState(page);
  const unSelectedUsers = users?.transformed?.filter(
    (user) => !selectedUsers.some((checkedUser) => checkedUser.id === user.id)
  );
  useEffect(() => {
    setSelectedUserHype(selectedUsers);
  }, [selectedUsers]);

  useEffect(() => {
    dispatch(clearFilteredData());
    dispatch(fetchSelectedUser(id, true));
    dispatch(fetchUsers({ page, size: 50, searchTerm: "" }, "User"));
    return () => {
      dispatch(resetSelectedUsers());
    };
  }, [dispatch, id, page]);

  const onSelectAdd = (event, user) => {
    const result = usersToAdd?.filter((obj) => obj.id !== user?.id);
    setUsersToAdd((prev) => {
      return result.length < usersToAdd.length
        ? result
        : [...prev, { ...user, checked: true }];
    });
  };
  const onSelectRemove = (event, user) => {
    const result = usersToRemove?.filter((obj) => obj.id !== user?.id);
    setUsersToRemove((prev) => {
      return result.length < usersToRemove.length
        ? result
        : [...prev, { ...user, checked: false }];
    });
  };

  const onAddHandler = () => {
    dispatch(
      assignUsersToExam(id, usersToAdd, {
        ...filterData,
        columnName: "schedule_date",
      })
    );
  };
  const onDeleteHandler = () => {
    dispatch(
      assignUsersToExam(id, usersToRemove, {
        ...filterData,
        columnName: "schedule_date",
      })
    );
  };

  const debouncedUnSelectedHandler = useCallback(
    debounce((e) => {
      setSearchText(e.target.value);
      setUsersPage(1);
      dispatch(
        fetchUsers(
          { page: usersPage, size: 50, searchTerm: e.target.value },
          "User"
        )
      );
    }, 300),
    [selectedUsers]
  );

  const debouncedSelectedHandler = useCallback(
    debounce((e) => {
      const searchTerm = e.target.value.toLowerCase();
      const data = selectedUsers.filter((user) => {
        const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
        return (
          user.firstName.toLowerCase().includes(searchTerm) ||
          user.lastName.toLowerCase().includes(searchTerm) ||
          fullName.includes(searchTerm)
        );
      });

      setSelectedUserHype(data);
    }, 300),
    [selectedUsers]
  );

  const loadMore = () => {
    let x = usersPage + 1;
    setUsersPage(x);
    dispatch(
      fetchUsers({ page: x, size: 50, searchTerm: searchText }, "User", "add")
    );
  };

  const getModalStyle = () => {
    return {
      transform: `translate(0%, -5%)`,
    };
  };

  const isLoadMore = users?.items > users?.transformed?.length;

  const body = (
    <Box
      className={classes.container}
      onClose={handleClose}
      style={getModalStyle()}
    >
      <Box className={classes.header}>
        <Typography className={classes.title} variant="h1">
          Manage Participants
        </Typography>
        <Typography className={classes.subtext} variant="body1">
          {examInfo && examInfo?.title}
        </Typography>
      </Box>
      <Box className={classes.content}>
        <Box className={classes.contentInner}>
          <Typography variant="h5">Available Participants</Typography>
          <Box className={classes.box}>
            <Box className={classes.search}>
              <Input
                id="search"
                labeltxt={<SearchIcon />}
                placeholderTxt="Search"
                onChange={debouncedUnSelectedHandler}
              />
            </Box>
            <List className={classes.list}>
              {unSelectedUsers?.map((user) => {
                let x = usersToAdd.some((obj) => obj.id === user.id);
                return user?.role === "User" ? (
                  <Fragment key={user.id}>
                    <ListItem disableGutters>
                      <Box className={classes.userBoxUnchecked}>
                        <Checkbox
                          onChange={(event) =>
                            onSelectAdd(event, {
                              id: user?.id,
                              firstName: user?.name,
                              lastName: user?.last,
                            })
                          }
                          icon={
                            <img
                              alt=""
                              src={checkbox}
                              className={classes.checkboxIcon}
                            />
                          }
                          checkedIcon={
                            <img
                              alt=""
                              src={checkboxIcon}
                              className={classes.checkboxIcon}
                            />
                          }
                          checked={x}
                        />
                        <Typography
                          className={classes.userNameChecked}
                        >{`${user?.name} ${user?.last}`}</Typography>
                      </Box>
                    </ListItem>
                    <Divider />
                  </Fragment>
                ) : null;
              })}
              {isLoadMore ? (
                <ListItem>
                  <Button variant="outlined" onClick={loadMore}>
                    Load More
                  </Button>
                </ListItem>
              ) : null}
            </List>
            <Button
              variant="contained"
              onClick={onAddHandler}
              color="secondary"
            >
              Add
            </Button>
          </Box>
        </Box>
        <Box className={classes.contentInner}>
          <Typography variant="h5">
            Selected Participants
            {"("}
            {selectedUserHype?.length}
            {")"}
          </Typography>
          <Box className={classes.box}>
            <Box className={classes.search}>
              <Input
                id="search"
                labeltxt={<SearchIcon />}
                placeholderTxt="Search"
                onChange={debouncedSelectedHandler}
              />
            </Box>
            <List className={classes.list}>
              {selectedUserHype.map((user) => {
                let x = usersToRemove.some((obj) => obj.id === user.id);
                return (
                  <Box className={classes.userBoxChecked} key={user?.id}>
                    <Checkbox
                      className={classes.checkboxStyle}
                      onChange={(event) =>
                        onSelectRemove(event, {
                          id: user?.id,
                          firstName: user?.firstName,
                          lastName: user?.lastName,
                        })
                      }
                      icon={
                        <img
                          alt=""
                          src={checkbox}
                          className={classes.checkboxIcon}
                        />
                      }
                      checkedIcon={
                        <img
                          alt=""
                          src={checkboxIcon}
                          className={classes.checkboxIcon}
                        />
                      }
                      checked={x}
                    />
                    <Typography className={classes.userNameChecked}>
                      {`${user?.firstName} ${user?.lastName}`}
                    </Typography>
                  </Box>
                );
              })}
            </List>
            <Button
              variant="contained"
              onClick={onDeleteHandler}
              style={{
                background: "#d24b4b",
              }}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Box>
      <Divider className={classes.div} />
      <Box className={classes.btnContainer}>
        <Button onClick={handleClose} className={classes.uploadBtnTextEdit}>
          Close
        </Button>
      </Box>
    </Box>
  );

  return (
    <Box>
      <MuiModal open={open} onClose={handleClose}>
        {body}
      </MuiModal>
    </Box>
  );
};

export default ParticipantsModal;
