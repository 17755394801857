import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  backIcon: {
    fill: theme.palette.info.main,
  },
  infoBar: {
    maxWidth: "270px",
    backgroundColor: theme.palette.primary.light,
  },
  infoBarHeader: {
    padding: "35px",
  },
  profileIcon: {
    margin: "50px auto",
    display: "flex",
  },
  profileText: {
    textAlign: "center",
    cursor: "pointer",
  },
  scoreInfo: {
    textAlign: "center",
    padding: "10px",
    backgroundColor: theme.palette.white.main,
    borderRadius: "10px",
    marginTop: "15px",
  },
  infoBarBody: {
    marginTop: "175px",
    "& svg": {
      fill: theme.palette.info.main,
      width: "7.29px",
      height: "13.41px",
    },
    "& nav > div ": {
      margin: "19px auto",
      width: "250px",
      padding: "10px",
    },
    "& h5": {
      width: "250px",
      margin: "0 20px",
    },
    "& .Mui-selected": {
      background: "none",
      "& .MuiListItemText-root": {
        "& .MuiTypography-body1": {
          fontWeight: 700,
        },
      },
    },
  },
  pagination: {
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "rgb(255 255 255 / 8%)",
      border: "1px solid #3E40B7",
      borderRadius: "6px",
      fontWeight: "bold",
      fontSize: "14px",
      lineHeight: "150%",
      color: "#3E40B7",
    },
    paddingLeft: "18px",
  },
  participantName: {
    textAlign: "center",
    padding: "10px",
    borderRadius: "10px",
    marginTop: "15px",
    fontSize: "18px",
  },
}));

export default useStyles;
