import { CssBaseline } from '@material-ui/core'
import Routes from './Routes/Routes'
import './App.css'
function App() {
  return (
    <>
      <CssBaseline />
      <Routes />
    </>
  )
}

export default App
