import thunk from "redux-thunk";
import loginReducer from "./reducers/login/login";

import AdminReducer from "./reducers/AdminReducer";
import UserReducer from "./reducers/UserReducer";
import activeExamReducer from "./reducers/CommonReducer";
import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import AssessmentReducer from "./reducers/AssessmentReducer";

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const reducers = combineReducers({
  exams: AdminReducer,
  examsUser: UserReducer, //examReducer
  examDetail: activeExamReducer,
  login: loginReducer,
  AssessmentReducer: AssessmentReducer,
});
const appReducer = (state, action) => {
  if (action.type === "LOGOUT") state = undefined;
  return reducers(state, action);
};
const store = createStore(appReducer, composeEnhancers(applyMiddleware(thunk)));

export default store;
