import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    margin: "-45px -55px",
    width: "100%",
  },
  container: {
    backgroundColor: theme.palette.white.main,
    padding: "40px 54px",
    width: "100%",
  },
  examTitle: {
    marginBottom: "25px",
  },
  examBtn: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "11px",
    "& button": {
      width: "232px",
    },
  },
  disabled: {
    backgroundColor: theme.palette.border,
    "& span": {
      color: theme.palette.neutral.light,
    },
    "&:hover": {
      backgroundColor: theme.palette.border,
    },
  },
  currentButton: {
    border: "1px solid #3d40b8",
  },
  btnContainer: {
    margin: "0 auto",
    display: "flex",
    alignItems: "center",
    justifyContent:'center'
  },
  paginationIcon: {
    fill: theme.palette.neutral.light,
    width: "4.86px",
    height: "8.94px",
  },
  footerBtn: {
    "& .MuiButton-outlinedSecondary.Mui-disabled": {
      border: "none",
      marginRight: "0px",
    },
    "& .MuiButton-outlined.Mui-disabled": {
      border: "none",
      marginRight: "0px",
    },
    "&,&:hover,&:active": {
      all: "unset",
      display: "flex",
      alignItems: "center",
      marginRight: "0px",
    },
    "& span": {
      color: `${theme.palette.neutral.dark} !important`,
    },
    cursor: "pointer",
  },
  buttons: {
    lineHeight: "150%",
    fontWeight: "normal",
    cursor: "pointer",
  },
}));

export default useStyles;
